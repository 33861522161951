<template>
    <div class="druk-c-seg-btns">
        <div class="druk-c-seg-btns__wrapper">
            <div v-if="label" class="druk-c-seg-btns__label">{{ label }}:</div>

            <div class="druk-c-seg-btns__list" :class="{ 'druk-is-not-empty': isNotEmpty, 'druk-is-disabled': isDisabled }">
                <div
                    v-for="(item, index) in list"
                    :key="index"
                    class="druk-c-seg-btns__item"
                    :class="{ 'druk-is-selected': value && value.find((el) => el.key === item.key) }"
                    @click="onChange(item)">
                    <div class="druk-c-seg-btns__item-content">
                        <div
                            class="druk-c-seg-btns__item-lead"
                            :class="{ 'druk-is-shown': value && value.find((el) => el.key === item.key) }">
                            <druk-icon :size="'xs'" :name="'check'" :color="isNotEmpty ? 'on-surface' : 'on-secondary-container'" />
                        </div>

                        <div class="druk-c-seg-btns__item-name">{{ item.title }}</div>
                    </div>

                    <div class="druk-c-seg-btns__item-state"></div>
                </div>
            </div>

            <div v-if="hint" class="druk-c-seg-btns__hint">
                <druk-hint :tooltip="hintTT" :icon="hintIcon" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'druk-segmented-buttons',

        model: {
            prop: 'value',
            event: 'change',
        },

        props: {
            value: {
                type: Array,
                default: [],
            },

            label: String,
            list: Array,
            hint: [String, Object],

            isMultiple: Boolean,
            isNotEmpty: Boolean,
            isDisabled: Boolean,
        },

        computed: {
            hintTT() {
                if (!this.hint || typeof this.hint === 'string') return this.hint;

                const { icon, ...resp } = this.hint;
                return resp;
            },

            hintIcon() {
                if (!this.hint || typeof this.hint === 'string') return null;

                const { icon, ...resp } = this.hint;
                return icon;
            },
        },

        methods: {
            onChange(item) {
                const index = this.value.findIndex((el) => el.key === item.key);
                this.isMultiple ? this.onMultipleChange(item, index) : this.onSingleChange(item, index);
            },

            onMultipleChange(item, index) {
                index !== -1 ? this.value.splice(index, 1) : this.value.push(item);
                this.$emit('change', this.value);
            },

            onSingleChange(item, index) {
                index === -1 || this.isNotEmpty ? this.value.splice(0, 1, item) : this.value.splice(0, 1);
                this.$emit('change', this.value);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-seg-btns {
        flex-grow: 1;
        width: 100%;
        &__wrapper {
            width: 100%;
            display: flex;
            align-items: center;
        }
        &__label {
            margin-right: var(--druk-gap-m);
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            font-weight: 500;
        }
        &__list {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            &.druk-is-disabled {
                opacity: 1;
            }
        }
        &__list.druk-is-not-empty &__item.druk-is-selected,
        &__list.druk-is-disabled &__item {
            pointer-events: none;
            border-color: var(--druk-state-layers-on-surface-0-12);
            color: var(--on-surface);
            background-color: transparent;
            opacity: 0.38;
        }
        &__item {
            cursor: pointer;
            position: relative;
            padding: var(--druk-gap-xs-a) var(--druk-gap-l);
            min-height: 40px;
            text-align: center;
            border: 1px solid var(--druk-outline-variant);
            color: var(--druk-on-surface);
            background-color: transparent;
            &:first-child,
            &:first-child &-state {
                border-radius: 100px 0 0 100px;
            }
            &:last-child,
            &:last-child &-state {
                border-radius: 0 100px 100px 0;
            }
            &:not(:last-child) {
                border-right: 1px solid transparent;
            }
            &:hover &-state,
            &:active &-state,
            &:focus &-state {
                transition: background-color var(--druk-duration-medium-2) var(--druk-easing-emphasized-decelerate);
            }
            &:hover &-state {
                background-color: var(--druk-state-layers-on-surface-0-08);
            }
            &:active &-state,
            &:focus &-state {
                background-color: var(--druk-state-layers-on-surface-0-12);
            }
            &.druk-is-selected {
                color: var(--druk-on-secondary-container);
                background-color: var(--druk-secondary-container);
            }
            &.druk-is-selected:hover &-state {
                background-color: var(--druk-state-layers-on-secondary-container-0-08);
            }
            &.druk-is-selected:active &-state,
            &.druk-is-selected:focus &-state {
                background-color: var(--druk-state-layers-on-secondary-container-0-12);
            }
            &.druk-is-selected &-content {
                transform: translate(0, 0);
                transition: transform var(--druk-duration-medium-2) var(--druk-easing-emphasized-decelerate);
            }
            &-content {
                transform: translate(-14px, 0);
                transition: transform var(--druk-duration-medium-2) var(--druk-easing-emphasized-decelerate);
            }
            &-lead {
                display: inline-block;
                vertical-align: middle;
                margin-right: var(--druk-gap-s);
                opacity: 0;
                &.druk-is-shown {
                    opacity: 1;
                    transition: opacity var(--druk-duration-medium-2) var(--druk-easing-emphasized-decelerate);
                }
            }
            &-name {
                display: inline-block;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                font-weight: 500;
            }
            &-state {
                pointer-events: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                transition: background-color var(--druk-duration-short-2) var(--druk-easing-emphasized-accelerate);
            }
        }
        &__hint {
            margin-left: var(--druk-gap-step);
        }
    }
</style>
