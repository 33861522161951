<template>
    <div class="druk-l-section" :class="{ 'druk-is-inner': isInnerModule }">
        <div class="druk-l-container">
            <div class="druk-l-section__wrapper">
                <div v-if="!isInnerModule" class="druk-l-section__header">
                    <div class="druk-l-title">
                        <div class="druk-l-title__text">{{ $t('typog.index.title') }}</div>
                    </div>
                </div>

                <div class="druk-l-section__body">
                    <div class="druk-l-section__block druk-l-card-m druk-is-filter-board">
                        <div class="druk-l-options">
                            <div class="druk-l-options__item">
                                <druk-button
                                    :type="'text'"
                                    :icon="hasOpenedFilter ? 'xmark' : 'bars-filter'"
                                    :label="$t('common.filter')"
                                    @click="hasOpenedFilter = !hasOpenedFilter" />
                            </div>
                        </div>

                        <div class="druk-l-options">
                            <div class="druk-l-options__item" v-if="$fn.hasAccess(PERM_MODULE_TYPOGRAPHIES, PERM_ACTION_ADD)">
                                <druk-button :icon="'plus'" :label="$t('btn.create')" @click="onSave()" />
                            </div>
                        </div>
                    </div>

                    <div class="druk-l-section__block">
                        <advanced-filter
                            :items="filterList"
                            :activeSort="activeSort"
                            :hasOpenedFilter="hasOpenedFilter"
                            :hasFilterLoader="hasFilterLoader"
                            @onChange="applyFilters" />
                    </div>

                    <div class="druk-l-section__block druk-is-last">
                        <druk-table
                            :headers="headers"
                            :items="list"
                            :hasLoadingList="loadingGetList"
                            :pagination="listPagination"
                            @changePage="onPaginationChangePage"
                            @changeLimit="onPaginationChangeLimit">
                            <template :slot="[`rows-default`]">
                                <tbody v-for="(td, index) in list" :key="index">
                                    <tr>
                                        <td>
                                            <div class="druk-l-info">
                                                <div class="druk-l-info__prop">{{ td.alias }}</div>

                                                <div v-if="td.is_locked" class="druk-l-info__support">
                                                    <druk-support
                                                        >{{ $t('typographies.locked_prompt') }}
                                                        {{ td.copy_progress }}/100%</druk-support
                                                    >
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <druk-link
                                                :path="{ name: 'typographies_item', params: { id: td.id } }"
                                                :label="td.title"
                                                :isDisabled="!!td.is_locked" />
                                        </td>

                                        <td>
                                            <druk-link
                                                :path="`https://${td.domain}`"
                                                :label="`https://${td.domain}`"
                                                :isExternal="true" />
                                        </td>

                                        <td>
                                            <span
                                                class="label"
                                                :class="{
                                                    [`label--${td.pricing_plan.name}`]: !!td.pricing_plan,
                                                    'label--canceled': !td.pricing_plan,
                                                }"
                                                >{{
                                                    td.pricing_plan ? td.pricing_plan.name : $fn.capitalize($t('common.is_missing'))
                                                }}</span
                                            >
                                        </td>

                                        <td>
                                            <div class="label__wrap">
                                                <div
                                                    v-for="(el, index) in td.modules"
                                                    :key="index"
                                                    class="label"
                                                    :class="{
                                                        'label--new': index % 2 === 0,
                                                        'label--alternative': index % 2 !== 0,
                                                    }">
                                                    {{ el.title }}
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="druk-l-options druk-is-inside-table">
                                                <div class="druk-l-options__item">
                                                    <druk-dialog-option
                                                        :question="$t('doppler.auth_method')"
                                                        :options="authOptions"
                                                        :size="'tiny'"
                                                        :isUnavailable="!checkDopplerEffectAvail(td) || td.is_locked"
                                                        @asOwner="onDopplerEffect(td.owner_employee_id)"
                                                        @asAdmin="onDopplerEffect(td.admin_employee_id)">
                                                        <druk-icon-button
                                                            :type="'tonal'"
                                                            :icon="'key-skeleton'"
                                                            :isDisabled="!checkDopplerEffectAvail(td) || td.is_locked" />
                                                    </druk-dialog-option>
                                                </div>

                                                <div class="druk-l-options__item">
                                                    <druk-dropdown
                                                        :class="{ 'druk-is-inaccessible': loadingCopyItem || td.is_locked }"
                                                        :position="{ horizontal: 'strt', vertical: 'mid' }">
                                                        <template v-slot:druk-c-dd-base="{ isShown }">
                                                            <druk-icon-toggle-button
                                                                :type="'outlined'"
                                                                :icon="'ellipsis-stroke-vertical'"
                                                                :isActive="isShown"
                                                                :isDisabled="loadingCopyItem || td.is_locked" />
                                                        </template>

                                                        <template v-slot:druk-c-dd-list>
                                                            <ul class="list">
                                                                <li
                                                                    class="list__item"
                                                                    :class="{
                                                                        'druk-is-disabled': !$fn.hasAccess(
                                                                            PERM_MODULE_TYPOGRAPHIES,
                                                                            PERM_ACTION_EDIT,
                                                                        ),
                                                                    }"
                                                                    @click="onCopy(td.id, td.title)">
                                                                    {{ $t('btn.copy') }}
                                                                </li>

                                                                <druk-dialog-option
                                                                    class="list__item"
                                                                    :class="{
                                                                        'druk-is-disabled': !$fn.hasAccess(
                                                                            PERM_MODULE_TYPOGRAPHIES,
                                                                            PERM_ACTION_DELETE,
                                                                        ),
                                                                    }"
                                                                    @success="onDelete(td.id)">
                                                                    <span>{{ $t('common.delete') }}</span>
                                                                </druk-dialog-option>
                                                            </ul>
                                                        </template>
                                                    </druk-dropdown>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </druk-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import tableFiltering from '@/mixins/table-filtering.mixin.js';
    import moduleBuild from '@/mixins/module-build.mixin.js';

    import ModalSave from './components/ModalSave';
    import ModalCopy from './components/ModalCopy';

    export default {
        name: 'typographies-index',

        mixins: [tableFiltering, moduleBuild],

        props: {
            moduleActiveFilter: Object,
            isInnerModule: Boolean,
            fnModuleFilterUpdate: Function,
        },

        created() {
            this.onCheckDopplerReload();
        },

        computed: {
            ...mapState({
                PERM_MODULE_TYPOGRAPHIES: (state) => state.permissions.PERM_MODULE_TYPOGRAPHIES,
                PERM_ACTION_ADD: (state) => state.permissions.PERM_ACTION_ADD,
                PERM_ACTION_EDIT: (state) => state.permissions.PERM_ACTION_EDIT,
                PERM_ACTION_DELETE: (state) => state.permissions.PERM_ACTION_DELETE,

                list: (state) => state.typographies.list,
                listPagination: (state) => state.typographies.listPagination,
                listFilter: (state) => state.typographies.listFilter,

                loadingGetList: (state) => state.typographies.loadingGetList,
                loadingCopyItem: (state) => state.typographies.loadingCopyItem,
                loadingDeleteItem: (state) => state.typographies.loadingDeleteItem,
                loadingDopplerEffect: (state) => state.auth.loadingDopplerEffect,
            }),

            ...mapGetters({
                listActiveFilter: 'typographies/listActiveFilter',
                isFilterNotEmpty: 'typographies/isFilterNotEmpty',
            }),

            headers() {
                return [
                    { key: 'alias', text: this.$t('table.alias') },
                    { key: 'title', text: this.$t('table.title') },
                    { key: 'domain', text: this.$t('table.domain') },
                    { key: 'pricing_plan', text: this.$t('pricing_plan.title') },
                    { key: 'modules', text: this.$t('table.modules') },
                    { key: 'actions', text: this.$t('common.actions'), hasActions: true },
                ];
            },

            filterList() {
                return [
                    {
                        type: 'form-input',
                        key: 'title',
                        value: this.listFilter['title'],
                        label: this.$t('table.title'),
                    },
                    {
                        type: 'form-input',
                        key: 'domain',
                        value: this.listFilter['domain'],
                        label: this.$t('table.domain'),
                    },
                ];
            },

            authOptions() {
                return [
                    {
                        handler: 'asOwner',
                        icon: 'crown',
                        label: this.$t('owner.tilte'),
                    },
                    {
                        handler: 'asAdmin',
                        icon: 'user',
                        label: this.$t('roles.admin_title'),
                        type: 'tonal',
                    },
                ];
            },
        },

        methods: {
            ...mapActions({
                AUTH_DOPPLER_LOGIN: 'AUTH_DOPPLER_LOGIN',

                GET_LIST: 'typographies/GET_LIST',
                GET_PRICING_PLANS: 'pricing/plans/GET_LIST',
                GET_MODULES: 'typographies/modules/GET_LIST',
                DELETE_ITEM: 'typographies/DELETE_ITEM',
            }),

            ...mapMutations({
                UPDATE_LIST_FILTER: 'typographies/UPDATE_LIST_FILTER',
                RESET_ITEM: 'typographies/RESET_ITEM',
                RESET_STORE: 'typographies/RESET_STORE',
                RESET_MODULES: 'typographies/modules/RESET_TYPOGRAPHY_LIST',
            }),

            async getList() {
                await this.GET_LIST();
                this.setFilterDataToUrl();
            },

            onCheckDopplerReload() {
                if (this.$route.query.with_doppler_reload) {
                    this.$router.replace({ name: this.$route.name, query: {} });
                    window.location.reload();
                }
            },

            async onSave(item) {
                if (!item) {
                    this.RESET_ITEM();
                    this.RESET_MODULES();
                }

                await Promise.all([this.GET_PRICING_PLANS(), this.GET_MODULES({ page: null, limit: null })]);

                this.$modal.show(ModalSave, { item }, $MODAL_OPTIONS.full);
            },

            onCopy(id, title) {
                this.$modal.show(ModalCopy, { id, title }, $MODAL_OPTIONS.normal);
            },

            async onDelete(id) {
                await this.DELETE_ITEM(id);
                if (this.list.length === 1) this.setFilterDataToUrl();
            },

            checkDopplerEffectAvail(item) {
                return (
                    !this.loadingCopyItem &&
                    !this.loadingDeleteItem &&
                    !this.loadingDopplerEffect &&
                    !!(item.admin_employee_id || item.owner_employee_id)
                );
            },

            async onDopplerEffect(id) {
                if (!id) return this.$noty.error(this.$t('doppler.auth_as_user_error'));

                await this.AUTH_DOPPLER_LOGIN(id);

                this.$router.push({ name: 'dashboard', query: { with_doppler_reload: true } });
            },
        },
    };
</script>

<style lang="scss" scoped></style>
