<template>
    <div
        class="druk-l-grid druk-u-gap-l"
        :class="{
            'druk-is-disabled': !$fn.hasAccess(PERM_MODULE_TYPOGRAPHY_CURRENCY, PERM_ACTION_EDIT),
        }">
        <div class="druk-l-grid__col-6 druk-u-md-12 druk-u-md-gap-xl">
            <div class="druk-l-form__item druk-is-last">
                <form-select
                    v-model="formData.currency"
                    :label="$t('typography.save.default_currency')"
                    :options="mutatedList"
                    rules="required"
                    :notEmpty="true"
                    :surface="'tint-brighter'"
                    name="currency"
                    @input="onUpdateRate" />
            </div>
        </div>

        <div class="druk-l-grid__col-6 druk-u-md-12">
            <div class="druk-l-form__item druk-is-last">
                <form-input
                    v-model="formData.rate"
                    :class="{ 'druk-is-disabled': hasPickedDefaultCurrency }"
                    :label="$t('typography.save.rate_to_the_euro')"
                    :placeholder="ratePlaceholder"
                    rules="required"
                    :surface="'tint-brighter'"
                    name="rate" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'typographies-edit-currency',

        created() {
            this.$bus.$on('on-save-typography-currencies', () => this.onSave());
        },

        destroyed() {
            this.$bus.$off('on-save-typography-currencies');
        },

        computed: {
            ...mapState({
                PERM_MODULE_TYPOGRAPHY_CURRENCY: (state) => state.permissions.PERM_MODULE_TYPOGRAPHY_CURRENCY,
                PERM_ACTION_EDIT: (state) => state.permissions.PERM_ACTION_EDIT,

                DEFAULT_CURRENCY: (state) => state.currency.DEFAULT_CURRENCY,

                typographyList: (state) => state.currency.typographyList,
                formData: (state) => state.currency.typographyFormData,
                typography: (state) => state.typographies.item,
            }),
            ...mapGetters({
                mutatedList: 'currency/mutatedTypographyList',
            }),

            ratePlaceholder() {
                return {
                    before: '1€ =',
                    after: this.formData.currency?.code || '',
                };
            },

            hasPickedDefaultCurrency() {
                return !!(this.formData.currency?.code === this.DEFAULT_CURRENCY);
            },
        },

        methods: {
            ...mapActions({
                SAVE_ACTIVE_CURRENCIES: 'currency/SAVE_ACTIVE_CURRENCIES',
            }),
            ...mapMutations({
                SET_SELECTED_CURRENCY: 'SET_SELECTED_CURRENCY',
            }),

            async onSave(e) {
                let result = await this.$validator.validate();

                if (result) {
                    try {
                        this.SET_SELECTED_CURRENCY(this.formData.currency.code);

                        this.SAVE_ACTIVE_CURRENCIES({
                            typography_id: this.typography.id,
                            formData: {
                                currencies: this.typographyList.map((currency) => ({
                                    ...currency,
                                    rate: currency.code === this.formData.currency.code ? this.formData.rate : currency.rate,
                                    is_default: currency.code === this.formData.currency.code,
                                })),
                            },
                            without_noty: true,
                        });
                    } catch (e) {
                        $fn.setValidateErrors(e, this.errors);
                    }
                } else {
                    $fn.showFormError(e.target.closest('.druk-c-btn'), this.$validator.errors.items);
                }
            },

            onUpdateRate(value) {
                this.formData.rate = value.rate;
            },
        },
    };
</script>

<style lang="scss" scoped></style>
