<template>
    <div class="formula">
        <div class="formula__body" :class="{ 'druk-is-reduced': !isDefaultType }">
            <div class="druk-l-grid">
                <div class="druk-l-grid__col-8 druk-u-def-12">
                    <div class="formula__card">
                        <div
                            class="druk-l-card-m druk-is-board druk-is-flexed"
                            :class="{ [`druk-l-surface-${surfaceVariant}`]: surfaceVariant }">
                            <div class="formula__groups">
                                <div class="formula__variables">
                                    <div
                                        class="formula__variable has-icon"
                                        :class="{ 'druk-is-disabled': version <= 0 }"
                                        @click="onBackward">
                                        <font-awesome-icon icon="fa-solid fa-arrow-rotate-left" />
                                    </div>

                                    <div
                                        class="formula__variable has-icon"
                                        :class="{ 'druk-is-disabled': version === history.length - 1 }"
                                        @click="onForward">
                                        <font-awesome-icon icon="fa-solid fa-arrow-rotate-right" />
                                    </div>
                                </div>

                                <div class="formula__variables">
                                    <div
                                        v-for="variable in ADDITIONAL_VARIABLES"
                                        :key="variable.key"
                                        class="formula__variable formula__variable--additional"
                                        :class="{ 'has-icon': variable.icon }"
                                        @click="onSetAreaItem(variable)">
                                        <font-awesome-icon v-if="variable.icon" :icon="variable.icon" />
                                        <span v-else>{{ $fn.tItemSystem(variable).title }}</span>
                                    </div>
                                </div>

                                <div v-if="isDefaultType" class="formula__variables">
                                    <div
                                        v-for="variable in formattedVariables.functions"
                                        :key="variable.key"
                                        class="formula__variable"
                                        :class="{
                                            [`formula__variable--${variable.groupKey}`]: variable.groupKey,
                                        }"
                                        @click="onSetAreaFunction(variable)">
                                        {{ $fn.tItemSystem(variable).title }}
                                    </div>
                                </div>

                                <div class="formula__variables">
                                    <div
                                        class="formula__variable has-icon"
                                        :class="{ 'druk-is-disabled': !isCloneAvailable }"
                                        @click="onClone">
                                        <font-awesome-icon icon="fa-solid fa-copy" />
                                    </div>

                                    <div
                                        class="formula__variable has-icon"
                                        :class="{ 'druk-is-disabled': !isPasteAvailable }"
                                        @click="onPaste">
                                        <font-awesome-icon icon="fa-solid fa-paste" />
                                    </div>
                                </div>
                            </div>

                            <div class="druk-l-options druk-is-reduced">
                                <calc-formula-standard />

                                <tool-tip :text="$t('common.formula_info')">
                                    <druk-icon-toggle-button
                                        :type="'standard'"
                                        :icon="'circle-question'"
                                        :isActive="hasShownPrompt"
                                        @click="hasShownPrompt = !hasShownPrompt" />
                                </tool-tip>
                            </div>
                        </div>
                    </div>

                    <calc-formula-prompt :surface="surfaceVariant" :hasShownPrompt="hasShownPrompt" />

                    <calc-formula-area v-if="!isDEFMaxWidth" :surface="surfaceVariant" />
                </div>

                <div class="druk-l-grid__col-4 druk-u-def-12">
                    <div class="formula__cards">
                        <template v-if="isDefaultType">
                            <div
                                v-for="group in variablesGroups"
                                :key="group.key"
                                class="druk-l-card-m formula__card"
                                :class="{ [`druk-l-surface-${surfaceVariant}`]: surfaceVariant }">
                                <div class="druk-l-card__header">
                                    <div class="druk-l-card__title">
                                        <div class="druk-l-card__title-text">{{ group.title }}</div>

                                        <div class="druk-l-card__title-support">
                                            <druk-support>{{ group.prompt }}</druk-support>
                                        </div>
                                    </div>
                                </div>

                                <div class="formula__variables">
                                    <div
                                        v-for="variable in formattedVariables[group.key]"
                                        :key="variable.key"
                                        class="formula__variable"
                                        :class="{
                                            [`formula__variable--${variable.groupKey}`]: variable.groupKey,
                                        }"
                                        @click="onSetAreaItem(variable)">
                                        {{ $fn.tItemSystem(variable).title }}
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div v-if="filteredVariables.length" class="formula__groups druk-is-column">
                            <div class="druk-l-card-m" :class="{ [`druk-l-surface-${surfaceVariant}`]: surfaceVariant }">
                                <div class="formula__variables">
                                    <div
                                        v-for="variable in filteredVariables"
                                        :key="variable.key"
                                        class="formula__variable"
                                        :class="{ [`formula__variable--${variable.groupKey}`]: variable.groupKey }"
                                        @click="onSetAreaItem(variable)">
                                        {{ $fn.tItemSystem(variable).title }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <calc-formula-area v-if="isDEFMaxWidth" :surface="surfaceVariant" />
                </div>
            </div>
        </div>

        <div v-if="isDefaultType" class="formula__footer">
            <div class="druk-l-options">
                <div class="druk-l-options__item">
                    <druk-button :icon="'floppy-disk'" :label="$t('admin.btn.continue')" @click="onSave" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import CalcFormulaArea from './formula/CalcFormulaArea';
    import CalcFormulaStandard from './formula/CalcFormulaStandard';
    import CalcFormulaPrompt from './formula/CalcFormulaPrompt';

    export default {
        name: 'calc-formula',

        inject: ['$validator'],

        components: {
            CalcFormulaArea,
            CalcFormulaStandard,
            CalcFormulaPrompt,
        },

        props: {
            type: String,

            surface: {
                type: String,
                default: 'tint-pale',
            },

            surfaceVariant: {
                type: String,
                default: 'high',
            },

            hasAltStyles: Boolean,

            fnSave: Function,
        },

        data() {
            return {
                hasShownPrompt: true,
            };
        },

        created() {
            this.$bus.$on('on-show-brackets-error', (e) => this.onShowBracketsError(e));
            this.$emit('formula-mode');
        },

        destroyed() {
            this.$bus.$off('on-show-brackets-error');
        },

        computed: {
            ...mapState({
                DEFAULT_EDITOR_TYPE: (state) => state.calcModes.formula.DEFAULT_EDITOR_TYPE,
                MATERIAL_EDITOR_TYPE: (state) => state.calcModes.formula.MATERIAL_EDITOR_TYPE,
                EQUIPMENT_EDITOR_TYPE: (state) => state.calcModes.formula.EQUIPMENT_EDITOR_TYPE,

                ADDITIONAL_VARIABLES: (state) => state.calcModes.formula.ADDITIONAL_VARIABLES,

                area: (state) => state.calcModes.formula.area,
                cursor: (state) => state.calcModes.formula.cursor,
                history: (state) => state.calcModes.formula.history,
                version: (state) => state.calcModes.formula.version,
                buffer: (state) => state.calcModes.formula.buffer,
            }),

            ...mapGetters({
                formattedVariables: 'calcModes/formula/formattedVariables',
                materialVariables: 'calcModes/formula/materialVariables',
                equipmentVariables: 'calcModes/formula/equipmentVariables',
                emptyIndex: 'calcModes/formula/emptyIndex',

                isDEFMaxWidth: 'isDEFMaxWidth',
                hasActiveCursor: 'calcModes/formula/hasActiveCursor',
                hasValidBracketPairs: 'calcModes/formula/hasValidBracketPairs',
                isCloneAvailable: 'calcModes/formula/isCloneAvailable',
            }),

            variablesGroups() {
                return [
                    {
                        key: 'global',
                        title: this.$t('common.variableGlobal'),
                        prompt: this.$t('librery.works.components.modalSaveFormula.li1'),
                    },
                    {
                        key: 'detail',
                        title: this.$t('librery.works.components.modalSaveFormula.detailParam'),
                        prompt: this.$t('librery.works.components.modalSaveFormula.li2'),
                    },
                    {
                        key: 'equipment',
                        title: this.$t('librery.works.components.modalSaveFormula.deviceParam'),
                        prompt: this.$t('librery.works.components.modalSaveFormula.li3'),
                    },
                    {
                        key: 'material',
                        title: this.$t('librery.works.components.modalSaveFormula.materialParam'),
                        prompt: this.$t('librery.works.components.modalSaveFormula.li4'),
                    },
                    {
                        key: 'other',
                        title: this.$t('calcModes.formula.variables_other.title'),
                        prompt: this.$t('calcModes.formula.variables_other.prompt'),
                    },
                ];
            },

            filteredVariables() {
                return this.isMaterialType ? this.materialVariables : this.isEquipmentType ? this.equipmentVariables : [];
            },

            isDefaultType() {
                return this.type === this.DEFAULT_EDITOR_TYPE;
            },

            isMaterialType() {
                return this.type === this.MATERIAL_EDITOR_TYPE;
            },

            isEquipmentType() {
                return this.type === this.EQUIPMENT_EDITOR_TYPE;
            },

            isPasteAvailable() {
                return !!this.buffer[this.type].length;
            },
        },

        methods: {
            ...mapActions({
                ADD_AREA_ITEM: 'calcModes/formula/ADD_AREA_ITEM',
                ADD_AREA_ITEMS: 'calcModes/formula/ADD_AREA_ITEMS',
                VALIDATE_AREA: 'calcModes/formula/VALIDATE_AREA',
                PASTE_AREA: 'calcModes/formula/PASTE_AREA',
                CHANGE_EMPTY_CURSOR_POSITION: 'calcModes/formula/CHANGE_EMPTY_CURSOR_POSITION',
            }),

            ...mapMutations({
                SET_VERSION: 'calcModes/formula/SET_VERSION',
                CLONE_AREA: 'calcModes/formula/CLONE_AREA',
            }),

            onBackward() {
                this.SET_VERSION({ value: this.version - 1 });
                this.$bus.$emit('on-area-update');
            },

            onForward() {
                this.SET_VERSION({ value: this.version + 1 });
                this.$bus.$emit('on-area-update');
            },

            onSetAreaItem(value) {
                let index = this.hasActiveCursor ? this.cursor.index + +!!this.cursor.is_right : this.emptyIndex;

                this.CHANGE_EMPTY_CURSOR_POSITION({ index, without_update: true });
                this.ADD_AREA_ITEM({ index, value: { ...value } });

                this.$bus.$emit('on-area-update');
            },

            onSetAreaFunction(value) {
                let index = this.hasActiveCursor ? this.cursor.index + +!!this.cursor.is_right : this.emptyIndex;

                this.CHANGE_EMPTY_CURSOR_POSITION({ index, without_update: true });

                this.ADD_AREA_ITEMS({
                    index,
                    values: [
                        value,
                        this.ADDITIONAL_VARIABLES.find((variable) => variable.is_opening_bracket),
                        this.ADDITIONAL_VARIABLES.find((variable) => variable.is_closing_bracket),
                    ],
                });

                this.$bus.$emit('on-area-update');
            },

            onClone() {
                this.CLONE_AREA(this.type);
                this.$noty.success(this.$t('calcModes.formula.clone'));
            },

            onPaste() {
                this.PASTE_AREA(this.type);
                this.$noty.success(this.$t('calcModes.formula.paste'));

                this.$bus.$emit('on-area-update');
            },

            async onSave(e) {
                let result = await this.$validator.validate();

                if (result && this.hasValidBracketPairs) {
                    try {
                        await this.VALIDATE_AREA();

                        this.fnSave();
                        this.$emit('send');
                    } catch (e) {
                        $fn.setValidateErrors(e, this.errors);
                    }
                } else {
                    if (!this.hasValidBracketPairs) this.onShowBracketsError(e);
                    $fn.showFormError(e.target.closest('.druk-c-btn'), this.$validator.errors.items);
                }
            },

            onShowBracketsError(e) {
                document.querySelectorAll('.has-open').forEach((bracket) => {
                    bracket.classList.add('has-invalid');

                    let timer = setTimeout(() => {
                        clearTimeout(timer);
                        bracket.classList.remove('has-invalid');
                    }, 1000);
                });

                $fn.showFormError(e.target.closest('.druk-c-btn'), this.$t('calcModes.formula.has_open_brackets'));
            },
        },
    };
</script>

<style lang="scss">
    .formula {
        &__body {
            padding: var(--druk-gap-xl) 0 var(--druk-gap-m);
            &.druk-is-reduced {
                padding: 0;
            }
        }
        &__actions {
            display: flex;
            align-items: center;
            margin: var(--druk-n-gap-xs);
        }
        &__cards {
            max-height: 400px;
            overflow: auto;
            @media (max-width: $druk-breakpoints-def) {
                margin-bottom: var(--druk-gap-xl);
            }
        }
        &__card {
            margin-bottom: var(--druk-gap-xl);
            &:last-child {
                margin-bottom: 0;
            }
        }
        &__groups {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            &.druk-is-column .formula__variables {
                margin: var(--druk-n-gap-step);
            }
            @media (max-width: $druk-breakpoints-sm) {
                display: block;
            }
        }
        &__variables {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: var(--druk-n-gap-step) var(--druk-gap-s-a) var(--druk-n-gap-step) var(--druk-n-gap-step);
            @media (max-width: $druk-breakpoints-sm) {
                margin: var(--druk-n-gap-step) var(--druk-n-gap-step) var(--druk-gap-s-a) var(--druk-n-gap-step);
            }
        }
        &__variable {
            cursor: pointer;
            display: inline-block;
            margin: var(--druk-gap-step);
            padding: 2px 12px;
            min-height: 24px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            font-weight: 500;
            border-radius: 4px;
            outline: 1px solid var(--druk-formulas-default-outline-variant);
            color: var(--druk-formulas-on-default-container);
            background-color: var(--druk-formulas-default-container);
            transition: transform var(--druk-duration-short-1) var(--druk-easing-emphasized-accelerate);
            &:hover {
                transform: translateY(-1px);
            }
            &--additional {
                outline: 1px solid var(--druk-formulas-additional-outline-variant);
                color: var(--druk-formulas-on-additional-container);
                background-color: var(--druk-formulas-additional-container);
            }
            &--functions {
                outline: 1px solid var(--druk-formulas-functions-outline-variant);
                color: var(--druk-formulas-on-functions-container);
                background-color: var(--druk-formulas-functions-container);
            }
            &--global {
                outline: 1px solid var(--druk-formulas-global-outline-variant);
                color: var(--druk-formulas-on-global-container);
                background-color: var(--druk-formulas-global-container);
            }
            &--detail {
                outline: 1px solid var(--druk-formulas-detail-outline-variant);
                color: var(--druk-formulas-on-detail-container);
                background-color: var(--druk-formulas-detail-container);
            }
            &--equipment {
                outline: 1px solid var(--druk-formulas-equipment-outline-variant);
                color: var(--druk-formulas-on-equipment-container);
                background-color: var(--druk-formulas-equipment-container);
            }
            &--material {
                outline: 1px solid var(--druk-formulas-material-outline-variant);
                color: var(--druk-formulas-on-material-container);
                background-color: var(--druk-formulas-material-container);
            }
            &--other {
                outline: 1px solid var(--druk-formulas-other-outline-variant);
                color: var(--druk-formulas-on-other-container);
                background-color: var(--druk-formulas-other-container);
            }
            &.has-icon {
                display: inline-flex;
                vertical-align: top;
                width: 24px;
                height: 24px;
                padding: 0px;
                svg {
                    margin: auto;
                }
            }
        }
        &__footer {
            padding: var(--druk-gap-m) 0 0;
        }
    }
</style>
