import { render, staticRenderFns } from "./BreadCrumbs.vue?vue&type=template&id=15d300a8&scoped=true"
import script from "./BreadCrumbs.vue?vue&type=script&lang=js"
export * from "./BreadCrumbs.vue?vue&type=script&lang=js"
import style0 from "./BreadCrumbs.vue?vue&type=style&index=0&id=15d300a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d300a8",
  null
  
)

export default component.exports