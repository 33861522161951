<template>
    <div
        class="druk-c-pgn druk-l-surface-tint-pale"
        :class="{ 'druk-is-disabled': hasLoadingList, 'druk-has-separate-styles': isSeparate }">
        <div class="druk-c-pgn__wrapper">
            <div class="druk-c-pgn__pages">
                <div
                    v-if="firstButton.isPresent"
                    class="druk-c-pgn__page druk-c-page"
                    :class="{ 'druk-is-disabled': !firstButton.isActive }"
                    @click="onChangePage(1)">
                    <druk-icon :color="'primary'" :name="firstButton.icon" :size="'xs'" />
                </div>

                <div
                    v-if="prevButton.isPresent"
                    class="druk-c-pgn__page druk-c-page"
                    :class="{ 'druk-is-disabled': !prevButton.isActive }"
                    @click="onChangePage(currentPage - 1)">
                    <druk-icon :color="'primary'" :name="prevButton.icon" :size="'xs'" />
                </div>

                <div v-if="prevMore.isPresent" class="druk-c-pgn__page druk-c-page has-inaccessible" @click="onChangePage(1)">
                    <druk-icon :color="'primary'" :name="prevMore.icon" :size="'xs'" />
                </div>

                <div
                    v-for="page in visiblePages"
                    :key="page.id"
                    class="druk-c-pgn__page druk-c-page"
                    :class="{ 'druk-is-active': page.id === currentPage }"
                    @click="onChangePage(page.id)">
                    <span>{{ page.name }}</span>
                </div>

                <div v-if="nextMore.isPresent" class="druk-c-pgn__page druk-c-page has-inaccessible" @click="onChangePage(1)">
                    <druk-icon :color="'primary'" :name="prevMore.icon" :size="'xs'" />
                </div>

                <div
                    v-if="nextButton.isPresent"
                    class="druk-c-pgn__page druk-c-page"
                    :class="{ 'druk-is-disabled': !nextButton.isActive }"
                    @click="onChangePage(currentPage + 1)">
                    <druk-icon :color="'primary'" :name="nextButton.icon" :size="'xs'" />
                </div>

                <div
                    v-if="lastButton.isPresent"
                    class="druk-c-pgn__page druk-c-page"
                    :class="{ 'druk-is-disabled': !lastButton.isActive }"
                    @click="onChangePage(pagination.last_page)">
                    <druk-icon :color="'primary'" :name="lastButton.icon" :size="'xs'" />
                </div>
            </div>

            <div class="druk-c-pgn__info">
                <div class="druk-c-pgn__label">{{ $t('contact.show') }}</div>

                <div class="druk-c-pgn__page-picker">
                    <druk-dropdown
                        :position="{ horizontal: 'strt', vertical: 'mid' }"
                        :class="{ 'druk-is-disabled': limits.length <= 1 }"
                        :hasStaticContent="true">
                        <template v-slot:druk-c-dd-base>
                            <druk-button :type="'tonal'" :label="currentLimit" />
                        </template>

                        <template v-slot:druk-c-dd-list>
                            <ul class="list druk-is-like-line">
                                <li
                                    v-for="(option, index) in limits"
                                    :key="index"
                                    class="list__item"
                                    :class="{ 'has-active': option.limit === currentLimit }"
                                    @click="onChangeLimit(option.limit)">
                                    {{ option.limit }}
                                </li>
                            </ul>
                        </template>
                    </druk-dropdown>
                </div>

                <div class="druk-c-pgn__total">
                    {{ $t('common.pagination_from') }} <span>{{ pagination.total }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'druk-pagination',

        props: {
            pagination: {
                type: Object,
                required: true,
            },

            list: {
                type: [Array, Object],
                required: true,
            },

            hasLoadingList: Boolean,
            isSeparate: Boolean,
        },

        data() {
            return {
                PAGES_AMOUNT: 5,
                DEFAULT_LIMIT: 30,
            };
        },

        watch: {
            hasEmptyLastPage() {
                this.onChangePage(this.pagination.last_page);
            },
        },

        computed: {
            currentPage() {
                return this.pagination.page;
            },

            currentLimit() {
                return this.pagination.total < this.pagination.limit ? this.pagination.total : this.pagination.limit;
            },

            firstButton() {
                return {
                    icon: 'angles-left',
                    isActive: this.currentPage > 1,
                    isPresent: this.PAGES_AMOUNT < this.pagination.last_page,
                };
            },

            prevButton() {
                return {
                    icon: 'angle-left',
                    isActive: this.currentPage > 1,
                    isPresent: this.pagination.last_page > 2,
                };
            },

            prevMore() {
                return {
                    icon: 'ellipsis',
                    isPresent: this.firstButton.isPresent && this.currentPage >= this.PAGES_AMOUNT - 1,
                };
            },

            nextMore() {
                return {
                    icon: 'ellipsis',
                    isPresent: this.pagination.last_page >= this.PAGES_AMOUNT + 1 && this.currentPage < this.pagination.last_page - 2,
                };
            },

            nextButton() {
                return {
                    icon: 'angle-right',
                    isActive: this.currentPage < this.pagination.last_page,
                    isPresent: this.visiblePages.length > 2,
                };
            },

            lastButton() {
                return {
                    icon: 'angles-right',
                    isActive: this.currentPage < this.pagination.last_page,
                    isPresent: this.pagination.last_page > this.PAGES_AMOUNT + 1,
                };
            },

            pages() {
                return Array.from({ length: this.pagination.last_page }, (page, index) => ({
                    id: index + 1,
                    name: index + 1,
                }));
            },

            visiblePages() {
                if (!this.prevMore.isPresent) return this.pages.slice(0, this.PAGES_AMOUNT - 1);

                if (this.prevMore.isPresent && this.nextMore.isPresent)
                    return this.pages.slice(this.currentPage - 2, this.currentPage + 1);

                if (this.prevMore.isPresent && !this.nextMore.isPresent)
                    return this.pages.slice(this.pagination.last_page - 4, this.pagination.last_page);

                return this.pages;
            },

            limits() {
                let limit = this.pagination.total > this.pagination.limit ? this.pagination.limit : this.pagination.total,
                    map = [
                        {
                            id: 0,
                            limit: limit <= this.DEFAULT_LIMIT ? limit : this.DEFAULT_LIMIT,
                        },
                    ];

                while ([...map].pop().limit < this.pagination.total) {
                    let last = [...map].pop();

                    map.push({
                        id: last.id + 1,
                        limit: last.limit * 2 < this.pagination.total ? last.limit * 2 : this.pagination.total,
                    });
                }

                return map;
            },

            hasEmptyLastPage() {
                return this.pagination?.page > this.pagination?.last_page;
            },
        },

        methods: {
            onChangePage(newPage) {
                this.$emit('changePage', newPage);
            },

            onChangeLimit(newLimit) {
                this.$emit('changeLimit', newLimit);
            },

            checkListLength() {
                let keys = typeof this.list === 'object' ? Object.keys(this.list) : this.list.length;
                if (!keys) this.$emit('toPreviousPage', this.pagination.page);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-pgn {
        padding: var(--druk-gap-l) var(--druk-gap-xl);
        border-top: 1px solid var(--druk-outline-variant);
        border-radius: 0px 0px 12px 12px;
        color: var(--druk-on-surface);
        &.druk-has-separate-styles {
            padding: var(--druk-gap-xl) 0 0;
            border-top: initial;
            background-color: transparent !important;
        }
        &__wrapper,
        &__pages {
            display: flex;
            align-items: center;
        }
        &__wrapper {
            justify-content: space-between;
            @media (max-width: $druk-breakpoints-md) {
                flex-wrap: wrap;
            }
        }
        &__pages {
            margin: 0px -4px;
            @media (max-width: $druk-breakpoints-md) {
                justify-content: space-evenly;
                width: 100%;
                margin-bottom: 16px;
            }
        }
        &__info {
            font-size: 14px;
            display: flex;
            align-items: center;
            @media (max-width: $druk-breakpoints-md) {
                width: 100%;
                justify-content: center;
            }
        }
        &__page-picker {
            padding: 0 var(--druk-gap-s);
        }
    }

    .druk-c-page {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 4px;
        padding: 0px !important;
        width: auto;
        height: 40px;
        min-width: 40px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        font-weight: 500;
        border-radius: 50%;
        color: var(--druk-primary);
        &::before {
            content: '';
            pointer-events: none;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: transparent;
            transition: background-color 0.2s ease-in-out;
        }
        &:hover {
            &::before {
                background-color: var(--druk-state-layers-primary-0-08);
            }
        }
        &:active,
        &:focus {
            &::before {
                background-color: var(--druk-state-layers-primary-0-12);
            }
        }
        &--more {
            @media (max-width: $druk-breakpoints-md) {
                display: none;
            }
        }
        &.druk-is-active {
            pointer-events: none;
            &::before {
                background-color: var(--druk-state-layers-primary-0-08);
            }
        }
        @media (max-width: $druk-breakpoints-md) {
            height: 30px;
            min-width: 30px;
        }
    }
</style>
