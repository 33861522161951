<template>
    <div class="vmodal">
        <div class="vmodal__container">
            <div class="vmodal__head">
                <div class="vmodal__title">
                    <div class="vmodal__title-text">{{ $t('currency.controll.title') }}</div>

                    <div class="vmodal__title-support">
                        <druk-support :hasNoIcon="true">{{ $t('currency.controll.info') }}</druk-support>
                    </div>
                </div>

                <druk-icon-button :type="'standard'" :icon="'xmark'" @click="$emit('close')" />
            </div>

            <div class="vmodal__main">
                <form-edit-currency @sendForm="$emit('close')" />
            </div>
        </div>
    </div>
</template>

<script>
    import FormEditCurrency from './FormEditCurrency';

    export default {
        name: 'modal-edit-currency',

        components: {
            FormEditCurrency,
        },
    };
</script>

<style lang="scss"></style>
