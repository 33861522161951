<template>
    <div class="druk-l-card-m" :class="{ 'druk-is-blocked': hasNoData || loadingGetData }">
        <div v-if="loadingGetData" class="druk-l-card__loader">
            <druk-loader :color="'inverse-on-surface'" :size="'s'" />
        </div>

        <div v-if="hasNoData && !loadingGetData" class="druk-l-card__lock">
            <druk-support :isInverse="true">{{ $t('common.multiple_async.no_result') }}</druk-support>
        </div>

        <div class="druk-l-card__header">
            <div class="druk-l-card__headline">
                <div class="druk-l-card__headline-text">{{ $t('dashboard.orders.status_chart.title') }}</div>
            </div>
        </div>

        <div class="druk-l-card__block">
            <Bar :ref="'chart'" :chart-data="chartConfig.data" :chart-options="chartConfig.options" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import { Bar } from 'vue-chartjs/legacy';

    import mixinChart from '@mixins/mixinChart';

    export default {
        name: 'orders-status-bar-chart',

        mixins: [mixinChart],

        components: {
            Bar,
        },

        computed: {
            ...mapState({
                ACTIVE_ORDER_STATUSES: (state) => state.orders.statuses.ACTIVE_ORDER_STATUSES,

                ordersData: (state) => state.dashboard.ordersData,
                statuses: (state) => state.orders.statuses.list,

                loadingGetData: (state) => state.dashboard.loadingGetData,
                loadingGetStatuses: (state) => state.orders.statuses.loadingGetList,
            }),

            chartConfig() {
                return {
                    data: {
                        labels: this.activeStatuses.map((status) => status.title),

                        datasets: [
                            {
                                data: this.activeStatuses.map((status) => status.data),
                                borderWidth: 1,
                                borderColor: this.activeStatuses.map((status) => this.COLORS_MAP[status.color]?.border),
                                backgroundColor: this.activeStatuses.map((status) => this.COLORS_MAP[status.color]?.background),
                                hoverBackgroundColor: this.activeStatuses.map(
                                    (status) => this.COLORS_MAP[status.color]?.backgroundHover,
                                ),
                            },
                        ],
                    },

                    options: {
                        ...this.chartOptions,
                        scales: this.scalesPluginOptions,
                        plugins: {
                            datalabels: this.dataLabelsPluginOptions,
                            doughnutlabel: {
                                display: false,
                            },
                            legend: {
                                ...this.legendPluginOptions,
                                ...this.barLegendPluginOptions,
                            },
                            tooltip: {
                                ...this.tooltipPluginOptions,
                                ...this.barTooltipPluginOptions,
                            },
                        },
                    },
                };
            },

            activeStatuses() {
                return this.ACTIVE_ORDER_STATUSES.map((active_status) => ({
                    ...active_status,
                    ...this.statuses.find((status) => status.alias === active_status.alias),
                    data: this.ordersData[active_status.alias],
                }));
            },

            hasNoData() {
                return !this.activeStatuses.filter((item) => !!item.data).length;
            },
        },
    };
</script>

<style lang="scss" scoped></style>
