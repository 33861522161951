var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search",class:{
        'has-full': _vm.hasSelect,
        'has-active': _vm.hasActive,
        'has-focus': _vm.hasActive,
        'druk-has-hint': _vm.hint && !_vm.hint.isSide,
        'druk-has-side-hint': _vm.hint && _vm.hint.isSide,
    }},[_c('div',{ref:"area",staticClass:"search__area"},[_c('form-input',{class:{ 'has-loading': _vm.hasSoftLoading },attrs:{"name":_vm.name,"label":_vm.label,"surface":_vm.surface,"placeholder":_vm.placeholder,"hint":_vm.hint,"rules":_vm.rules},on:{"onFocus":_vm.onFocus,"input":_vm.onSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(!_vm.hasSelect && _vm.hasLoading)?_c('div',{staticClass:"search__nav"},[_c('druk-loader',{attrs:{"isIcon":true}})],1):(_vm.hasSelect)?_c('div',{staticClass:"search__nav",on:{"click":_vm.onClear}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-xmark"}})],1):_c('div',{staticClass:"search__nav search__nav--search"},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-magnifying-glass"}})],1)],1),_c('div',{ref:"cnt",staticClass:"search__cnt",class:{ [`druk-l-surface-${_vm.surfaceVariant}`]: _vm.surfaceVariant, 'has-active': _vm.hasOptions }},[_c('ul',{staticClass:"multiselect__content"},_vm._l((_vm.options),function(option,index){return _c('li',{key:index,staticClass:"multiselect__element",on:{"click":function($event){return _vm.onSelect(option)}}},[_c('span',{staticClass:"multiselect__option"},[_vm._v(_vm._s(option[_vm.customOption ? 'title' : _vm.option_label || 'title']))])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }