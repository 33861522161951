<template>
    <div id="app">
        <transition>
            <component :is="layout" v-if="hasBuildedApp" />
        </transition>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import mixinBuildApp from '@mixins/mixinBuildApp';

    import DefaultLayout from '@layouts/DefaultLayout';
    import LoginLayout from '@layouts/LoginLayout';
    import ErrorLayout from '@layouts/ErrorLayout';
    import IframeLayout from '@layouts/IframeLayout';

    export default {
        name: 'app',

        mixins: [mixinBuildApp],

        components: {
            DefaultLayout,
            LoginLayout,
            ErrorLayout,
            IframeLayout,
        },

        data() {
            return {
                permissionError: {
                    uk: 'У Вас немає прав для проведення цієї операції',
                    pl: 'Nie masz uprawnień do wykonania tej operacji',
                    en: 'You have not permitted for this action',
                    ru: 'У Вас нет прав для проведения этой операции',
                },
            };
        },

        created() {
            if (!this.$route.name || this.$route.name === 'login') {
                this.setLayout('login-layout');
                this.hasBuildedApp = true;
            }

            if (this.isLogged && this.$route.name !== 'login') {
                this.hasBuildedApp = false;

                this.USER_FETCH()
                    .then((resp) =>
                        this.hasTypographyAccess ? this.buildTypographyApp(resp.single.typography.id) : this.buildErpApp(),
                    )
                    .catch((error) => {
                        this.AUTH_LOGOUT().then(() => {
                            this.$noty.error(error);

                            if (this.hasIframe) this.SET_HISTORY_ADDRESS(window.location.href);
                            this.$router.go({ name: 'login' });
                        });
                    });
            }

            this.setWindowProps();
            window.addEventListener('resize', this.setWindowProps);
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.setWindowProps);
        },

        watch: {
            $route(to, from) {
                this.setLayout(to.meta.layout || 'default-layout');
            },
        },

        computed: {
            ...mapState({
                layout: (state) => state.layout,
            }),
            ...mapGetters({
                isLogged: 'isLogged',
                hasERPAccess: 'hasERPAccess',
                hasTypographyAccess: 'hasTypographyAccess',
            }),
        },

        methods: {
            ...mapActions({
                USER_FETCH: 'USER_FETCH',
                AUTH_LOGOUT: 'AUTH_LOGOUT',
            }),
            ...mapMutations({
                SET_HISTORY_ADDRESS: 'SET_HISTORY_ADDRESS',
                SET_WINDOW_WIDTH: 'SET_WINDOW_WIDTH',
            }),

            setWindowProps() {
                this.SET_WINDOW_WIDTH(window.screen.width);
            },
        },
    };
</script>

<style lang="scss">
    // @Explanation: Basic
    @import '~@styles';
    @import '~@styles/variables';
    @import '~@styles/roles/index';
    @import '~@fonts/fonts.css';
    @import '~@styles/elevations';
    @import '~@styles/layout/index';
    @import '~@styles/shape';
    @import '~@styles/states';

    // @Explanation: Utils
    @import '~@styles/utils/animations';
    @import '~@styles/utils/display';
    @import '~@styles/utils/indents';
    @import '~@styles/utils/layout';
    @import '~@styles/utils/sizes';
    @import '~@styles/utils/text';

    // @Explanation: Templates
    @import '~@styles/template/checkbox.scss';
    @import '~@styles/template/dashboard.scss';
    @import '~@styles/template/inputLogin.scss';
    @import '~@styles/template/comments.scss';
    @import '~@styles/template/selector.scss';
    @import '~@styles/template/swiper.scss';
    @import '~@styles/template/switch.scss';
    @import '~@styles/template/techCard.scss';
    @import '~@styles/template/vmodal.scss';
    @import '~@styles/template/wizard.scss';
    @import '~@styles/template/knowledge/article.scss';
    @import '~@styles/template/knowledge/card.scss';

    // @Explanation: Legacy (Temporary file with old style system)
    @import '~@styles/common.scss';

    #app {
        height: 100%;
        background-color: var(--druk-background);
    }
</style>
