<template>
    <div class="druk-l-card-m" :class="{ 'druk-is-blocked': hasNoData || loadingGetData }">
        <div v-if="loadingGetData" class="druk-l-card__loader">
            <druk-loader :color="'inverse-on-surface'" :size="'s'" />
        </div>

        <div v-if="hasNoData && !loadingGetData" class="druk-l-card__lock">
            <druk-support :isInverse="true">{{ $t('common.multiple_async.no_result') }}</druk-support>
        </div>

        <div class="druk-l-card__header">
            <div class="druk-l-card__headline">
                <div class="druk-l-card__headline-text">{{ $t('dashboard.orders.source_chart.title') }}</div>
            </div>
        </div>

        <div class="druk-l-card__block">
            <Doughnut :ref="'chart'" :chart-data="chartConfig.data" :chart-options="chartConfig.options" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import { Doughnut } from 'vue-chartjs/legacy';

    import mixinChart from '@mixins/mixinChart';

    export default {
        name: 'orders-source-doughnut-chart',

        mixins: [mixinChart],

        components: {
            Doughnut,
        },

        computed: {
            ...mapState({
                filteredOrdersData: (state) => state.dashboard.filteredOrdersData,

                loadingGetData: (state) => state.dashboard.loadingGetData,
            }),

            chartConfig() {
                return {
                    data: {
                        labels: this.sourceChartData.map((item) => item.label),

                        datasets: [
                            {
                                data: this.sourceChartData.map((item) => item.data),
                                borderWidth: 1,
                                borderColor: this.sourceChartData.map((item) => this.COLORS_MAP[item.color]?.border),
                                backgroundColor: this.sourceChartData.map((item) => this.COLORS_MAP[item.color]?.background),
                                hoverBackgroundColor: this.sourceChartData.map((item) => this.COLORS_MAP[item.color]?.backgroundHover),
                            },
                        ],
                    },

                    options: {
                        ...this.chartOptions,
                        plugins: {
                            datalabels: {
                                display: false,
                            },
                            doughnutlabel: {
                                color: this.TEXT_COLOR,
                            },
                            legend: {
                                ...this.legendPluginOptions,
                                ...this.doughnutLegendPluginOptions,
                            },
                            tooltip: this.tooltipPluginOptions,
                        },
                    },
                };
            },

            sourceChartData() {
                return [
                    {
                        data: this.filteredOrdersData.by_client,
                        label: this.$t('dashboard.orders.source_chart.created_by_client'),
                        color: 'dark-aqua',
                    },
                    {
                        data: this.filteredOrdersData.by_manager,
                        label: this.$t('dashboard.orders.source_chart.created_by_manager'),
                        color: 'iris',
                    },
                ];
            },

            hasNoData() {
                return !this.sourceChartData.filter((item) => !!item.data).length;
            },
        },
    };
</script>

<style lang="scss" scoped></style>
