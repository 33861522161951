var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"druk-c-table",class:{
        'druk-has-scroll': _vm.hasScroll,
        'druk-is-scrolled': _vm.isScrolled,
        'druk-has-card-adaptation': _vm.hasCardAdaptation,
        'druk-has-modal-styles': _vm.isModalMode,
    }},[_c('div',{staticClass:"druk-c-table__container"},[_c('div',{staticClass:"druk-c-table__wrapper",class:{ [`druk-l-surface-${_vm.surface}`]: _vm.surface, 'druk-has-no-pagination': !_vm.hasPagination }},[_c('div',{ref:"body",staticClass:"druk-c-table__body",class:{ 'druk-has-loader': _vm.hasLoadingList },on:{"scroll":_vm.onScroll}},[_c('table',{ref:"table",class:{ 'druk-has-card-adaptation': _vm.hasCardAdaptation }},[(!_vm.isWithoutHeader)?_c('thead',{staticClass:"druk-c-table__header"},[_c('tr',_vm._l((_vm.headers),function(header,index){return _c('th',{directives:[{name:"show",rawName:"v-show",value:(!header.isHidden),expression:"!header.isHidden"}],key:index,staticClass:"druk-c-table__header-item",class:{
                                    'druk-u-ta-center': header.isCentered,
                                    'druk-has-actions': header.hasActions,
                                    'druk-is-reduced': !header.text,
                                },attrs:{"id":header.isSticky ? 'sticky' : false}},[_c('div',{staticClass:"druk-c-table__header-data"},[_c('div',{staticClass:"druk-c-table__header-text"},[_vm._v(_vm._s(header.text))]),(header.hasSort)?_c('div',{staticClass:"druk-c-table__header-sort",class:{
                                            'druk-is-picked': header.key === _vm.sortKey,
                                            'druk-has-asc-direction': _vm.direction === _vm.DIRECTION_ASC_KEY,
                                            'druk-has-desc-direction': _vm.direction === _vm.DIRECTION_DESC_KEY,
                                        },on:{"click":function($event){return _vm.updateDirection(header.key)}}},[_c('druk-icon',{staticClass:"druk-c-table__header-sort-icon druk-is-default",attrs:{"name":'sort',"color":'on-surface-variant'}}),_c('druk-icon',{staticClass:"druk-c-table__header-sort-icon druk-is-desc",attrs:{"name":'sort-up',"color":'on-surface-variant'}}),_c('druk-icon',{staticClass:"druk-c-table__header-sort-icon druk-is-asc",attrs:{"name":'sort-down',"color":'on-surface-variant'}})],1):_vm._e()])])}),0)]):_vm._e(),_vm._l((_vm.groups),function(group,gIndex){return [(!_vm.hasDefaultGrouping && (_vm.activeGrouping.key || _vm.activeGrouping.title))?[_c('thead',{key:`group-title-${gIndex}`,staticClass:"druk-c-table__group-title",class:{ 'druk-has-payload': _vm.activeGrouping.payload, 'druk-has-no-header': _vm.isWithoutHeader }},[_c('tr',_vm._l((_vm.headers),function(header,hIndex){return _c('th',{directives:[{name:"show",rawName:"v-show",value:(hIndex === 0),expression:"hIndex === 0"}],key:hIndex,attrs:{"id":header.isSticky ? 'sticky' : false,"colspan":header.colspan}},[(_vm.activeGrouping.title)?_c('span',[_vm._v(_vm._s(_vm.activeGrouping.title))]):(_vm.activeGrouping.key)?[_c(_vm.activeGrouping.link ? 'druk-link' : 'span',{tag:"component",attrs:{"label":_vm.getGroupTitle(group),"path":_vm.activeGrouping.link ? _vm.getGroupPath(group) : false,"isBlank":true}},[_vm._v(_vm._s(_vm.getGroupTitle(group)))])]:_vm._e(),(_vm.activeGrouping.payload)?_c(_vm.activeGrouping.payload.base || 'span',{tag:"component",staticClass:"druk-c-table__group-payload",class:{
                                                [_vm.activeGrouping.payload.class]: _vm.activeGrouping.payload.class,
                                                [`${_vm.activeGrouping.payload.class}--${_vm.getGroupPayloadValue(group, 'relatedClass')}`]:
                                                    _vm.activeGrouping.payload.relatedClass,
                                            }},[_vm._v(_vm._s(_vm.getGroupPayloadValue(group)))]):_vm._e()],2)}),0)])]:_vm._e(),_vm._t(`rows-${group.id}`)]})],2),(_vm.hasNoItems)?_c('div',{staticClass:"druk-c-table__prompt"},[_c('druk-support',[_vm._v(_vm._s(_vm.$t('common.table.empty')))])],1):_vm._e(),_c('div',{ref:"loader",staticClass:"druk-c-table__loader druk-u-text-body-m druk-l-shape-m druk-l-elevation-2"},[_c('druk-loader',{attrs:{"size":'s',"label":_vm.$t('common.data_loading')}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasScroll && !_vm.hasLoadingList && !_vm.isModalMode),expression:"hasScroll && !hasLoadingList && !isModalMode"}],staticClass:"druk-c-table__scroll-layout",class:{ 'druk-has-loader': _vm.hasLoadingList },style:({ bottom: `${_vm.scrollPosition}px` })},[_c('div',{ref:"scroll",staticClass:"druk-c-table__scroll",style:({ width: _vm.bodyWidth ? `${_vm.bodyWidth}px` : `100%` })},[_c('span',{style:({ width: `${_vm.tableWidth}px` })})])])])]),(_vm.hasPagination)?_c('div',{staticClass:"druk-c-table__pagination"},[_c('druk-pagination',{attrs:{"pagination":_vm.pagination,"list":_vm.items,"hasLoadingList":_vm.hasLoadingList},on:{"changeLimit":function($event){return _vm.$emit('changeLimit', $event)},"changePage":function($event){return _vm.$emit('changePage', $event)}}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }