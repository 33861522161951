import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        EDITOR_MODES: [
            {
                value: 0,
                key: 'tech_map_editor.mode_shortly',
                title: null,
            },
            {
                value: 1,
                key: 'tech_map_editor.mode_expanded',
                title: null,
            },
            {
                value: 2,
                key: 'tech_map_editor.mode_own',
                title: null,
            },
        ],

        COUNT_TYPES: [
            {
                value: 'fixed',
                key: 'details.elements_amout.fixed',
                title: null,
            },
            {
                value: 'custom',
                key: 'details.elements_amout.custom',
                title: null,
            },
        ],

        EDITOR_MODE_SHORTLY: 0,
        EDITOR_MODE_EXPANDED: 1,
        EDITOR_MODE_OWN: 2,

        FORMAT_TYPE_OWN: 'own_format',
        FORMAT_TYPE_PRODUCT: 'product_format',
        FORMAT_TYPE_FORMULA: 'formula_format',

        COUNT_TYPE_FIXED: 'fixed',
        COUNT_TYPE_CUSTOM: 'custom',

        COMMON_FORM_DATA: {
            count: 1,
            min_count: 1,
            max_count: 2,
            default_count: 1,

            bleeds: null,
            format_type: null,
            format: null,
            count_type: null,
            notes: null,

            use_typography_settings: 1,
            is_same: 1,
            is_custom_count: 0,
            is_even_count_only: 0,

            detail: {
                title: null,
                is_print: 1,
                is_double_sided_print: 0,
            },
        },

        PAYLOAD_FORM_DATA: {
            min_width: null,
            max_width: null,
            default_width: null,

            min_height: null,
            max_height: null,
            default_height: null,

            min_angle_corner_radius: null,
            max_angle_corner_radius: null,
            default_angle_corner_radius: null,

            min_padding: null,
            max_padding: null,
            default_padding: null,
        },

        list: [],
        listPagination: {},
        listFilter: {
            page: null,
            limit: null,
            title: null,
            catalog: null,
        },

        formatTypesList: [],

        details: [],
        materialParams: [],
        equipmentParams: [],

        item: {},
        rollDetail: {},
        listDetail: {},

        formData: {},

        loadingGetList: false,
        loadingGetWinnerList: false,
        loadingGetFormatTypesList: false,
        loadingGetItem: false,
        loadingGetDetails: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        formattedList(state) {
            return state.list.map((item, index) => {
                let sameItems = state.list.filter((existed) => existed.detail.id === item.detail.id);

                return sameItems.length === 1
                    ? item
                    : Object.assign(item, {
                          same_index: sameItems.findIndex((same) => same.id === item.id) + 1,
                      });
            });
        },

        rollDetailSettings(state, getters, rootState, rootGetters) {
            return rootGetters['products/hasRollPrintType'] && !state.rollDetail.use_typography_settings
                ? state.rollDetail?.parameters || {}
                : rootGetters['settings/formattedDetailSettings'];
        },

        listDetailSettings(state, getters, rootState, rootGetters) {
            return rootGetters['products/hasListLabelsPrintType'] && !state.listDetail.use_typography_settings
                ? state.listDetail?.parameters || {}
                : rootGetters['settings/formattedDetailSettings'];
        },

        detailSettings(state, getters, rootState, rootGetters) {
            let settings = Object.create(null);

            if (rootGetters['products/hasRollPrintType']) Object.assign(settings, getters.rollDetailSettings);
            if (rootGetters['products/hasListLabelsPrintType']) Object.assign(settings, getters.listDetailSettings);

            return settings;
        },

        detailFormatsContours(state) {
            return state.list.filter((item) => !!item.format?.contour?.file);
        },

        hasCustomCountDetail(state) {
            return !!state.list.filter((item) => item.is_custom_count).length;
        },

        hasDetailsWithoutFormat(state) {
            return !!state.list.filter((item) => !item.format).length;
        },

        hasEditableParams(state) {
            return !!(state.materialParams.length || state.equipmentParams.length);
        },

        hasProductFormatDetails(state) {
            return state.list.filter((item) => item.format_type === state.FORMAT_TYPE_PRODUCT).length;
        },

        hasDetailsWithFormatsContours(state, getters) {
            return !!getters.detailFormatsContours.length;
        },
    },

    mutations: {
        SET_LIST(state, { list, isRollPrintTypeProduct, isListLabelsPrintTypeProduct }) {
            state.list = list;

            // @Explanation: Products with labels print type can exist only onne detail. This may change in the future
            if (isRollPrintTypeProduct) state.rollDetail = [...list].shift() || {};
            if (isListLabelsPrintTypeProduct) state.listDetail = [...list].shift() || {};
        },

        SET_EDITABLE_PARAMS(state, details) {
            let filteredDetails = details?.filter((detail) => detail.material?.length || detail.equipment?.length) || [];

            state.materialParams = filteredDetails.map((detail) => ({ id: detail.id, params: detail.material }));
            state.equipmentParams = filteredDetails.map((detail) => ({ id: detail.id, params: detail.equipment }));
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_FORMAT_TYPES_LIST(state, list) {
            state.formatTypesList = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_FORM_DATA(state, { formData, key }) {
            Vue.set(state, key, formData);
        },

        SET_FORM_DATA_PROP(state, { key, value }) {
            Vue.set(state.formData, key, value);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        SET_FILTER_LIST(state, { key, val }) {
            state.listFilter[key] = val;
        },

        UPDATE_FORM_DATA(state, formData) {
            Object.assign(state.formData, formData);
        },

        UPDATE_CUSTOM_ALG(state, { index, algorithm }) {
            let algIndex = state.list[index].algorithms.findIndex((item) => item.id === algorithm.id);
            if (algIndex) Vue.set(state.list[index].algorithms, algIndex, algorithm);
        },

        RESET_LIST(state) {
            state.list = [];
        },

        RESET_STORE(state) {
            state.list = [];

            state.materialParams = [];
            state.equipmentParams = [];

            state.rollDetail = {};
            state.listDetail = {};
        },

        RESET_FORM_DATA(state) {
            state.formData = { ...state.COMMON_FORM_DATA };
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, rootState, rootGetters, commit }, { productId, kindId }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/products/${productId}/kinds/${kindId}/details/tree`
                    : `/api/products/${productId}/kinds/${kindId}/details`;

                let resp = await $axios.get(path, { params: getters.listActiveFilter });

                commit('SET_LIST', {
                    list: resp.list,
                    isRollPrintTypeProduct: rootGetters['products/hasRollPrintType'],
                    isListLabelsPrintTypeProduct: rootGetters['products/hasListLabelsPrintType'],
                });

                commit('SET_EDITABLE_PARAMS', resp.details);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_FORMAT_TYPES_LIST: async ({ state, getters, rootGetters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetFormatTypesList', status: true });

                let resp = await $axios.get('api/products/kinds/details/format-types-list');

                commit('SET_FORMAT_TYPES_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetFormatTypesList', status: false });
            }
        },

        GET_ITEM: async ({ state, rootGetters, getters, commit }, { productId, kindId, id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/products/${productId}/kinds/${kindId}/details/${id}`
                    : `/api/products/${productId}/kinds/${kindId}/details/${id}`;

                let resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, commit, dispatch }, { productId, kindId, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/products/${productId}/kinds/${kindId}/details`
                    : `/api/products/${productId}/kinds/${kindId}/details`;

                let resp = await $axios.post(path, formData);

                dispatch('GET_LIST', { productId, kindId });
                commit('RESET_FORM_DATA');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, { productId, kindId, id, without_fetch, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/products/${productId}/kinds/${kindId}/details/${id}`
                    : `/api/products/${productId}/kinds/${kindId}/details/${id}`;

                let resp = await $axios.delete(path, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                if (!without_fetch) dispatch('GET_LIST', { productId, kindId });
                commit('RESET_FORM_DATA');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
