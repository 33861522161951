<template>
    <div class="druk-l-section__wrapper">
        <div class="druk-l-section__header">
            <div class="druk-l-title">
                <div class="druk-l-title__text">{{ $t('typog.index.title') }}</div>
            </div>
        </div>

        <div class="druk-l-section__body">
            <keep-alive>
                <typographies-index
                    :moduleActiveFilter="listActiveFilter"
                    :isInnerModule="true"
                    :fnModuleFilterUpdate="UPDATE_LIST_FILTER" />
            </keep-alive>
        </div>
    </div>
</template>

<script>
    import TypographiesIndex from '@views/typographies/TypographiesIndex';

    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'typographies-section',

        components: {
            TypographiesIndex,
        },

        computed: {
            ...mapGetters({
                listActiveFilter: 'typographies/listActiveFilter',
            }),
        },

        methods: {
            ...mapMutations({
                UPDATE_LIST_FILTER: 'typographies/UPDATE_LIST_FILTER',
            }),
        },
    };
</script>

<style lang="scss" scoped></style>
