<template>
    <div class="druk-l-form__item">
        <form-select
            v-model="formData.languages"
            :options="systemList"
            :label="$t('header.menu.languages')"
            :name="'systemList'"
            :rules="'required'"
            :multiple="true"
            :surface="'tint-brighter'"
            @input="onCheckSelectedLang" />
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'typographies-edit-languages',

        inject: ['$validator'],

        props: {
            selectedLang: String,
        },

        created() {
            this.$bus.$on('on-save-system-languages', (e) => this.onSave());
        },

        destroyed() {
            this.$bus.$off('on-save-system-languages');
        },

        computed: {
            ...mapState({
                systemList: (state) => state.languages.systemList,
                formData: (state) => state.languages.systemFormData,
                typography: (state) => state.typographies.item,
            }),

            hasSelectedLangInData() {
                return !!this.formData.languages.find((language) => language.code === this.selectedLang);
            },
        },

        methods: {
            ...mapActions({
                SAVE_SYSTEM_LIST: 'languages/SAVE_SYSTEM_LIST',
            }),
            ...mapMutations({
                SET_SELECTED_LANG: 'SET_SELECTED_LANG',
            }),

            async onSave() {
                await this.SAVE_SYSTEM_LIST({
                    formData: this.formData,
                    id: this.typography.id,
                    without_noty: true,
                });

                this.onCheckSystemLanguage();
            },

            onCheckSystemLanguage() {
                if (!this.formData.languages.find((language) => language.code === this.$i18n.locale)) {
                    let locale = [...this.formData.languages].shift().code;

                    this.$i18n.setLang(locale).then(() => {
                        this.setValidateLocalization(locale);
                        this.SET_SELECTED_LANG(locale);
                    });
                }
            },

            onCheckSelectedLang() {
                if (!this.hasSelectedLangInData) this.$bus.$emit('on-set-form-language', [...this.formData.languages].shift().code);
            },
        },
    };
</script>

<style lang="scss" scoped></style>
