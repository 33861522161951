import Vue from 'vue';

import novaPoshta from './services/novaPoshta';
import dpdBaltic from './services/dpdBaltic';

const COMMON_LIST_FILTER = {
    delivery_type_id: null,
    module_id: null,
    is_published: null,
    is_payer: null,
    page: null,
    limit: null,
    sort: 'new',
    direction: 'desc',
};

const COMMON_FORM_DATA = {
    title: null,
    address: null,
    delivery_type: null,
    price: 0,
    is_payer: 0,
    is_published: 0,
    is_default: 0,
};

export default {
    namespaced: true,

    modules: {
        novaPoshta,
        dpdBaltic,
    },

    state: {
        DELIVERY_MODULE_TYPE: 'delivery',

        DELIVERY_TYPES_COLOR_MAP: {
            1: 'candlelight',
            2: 'shamrock-green',
            3: 'water-blue',
            4: 'iris',
        },

        PAYERS_LIST: [
            {
                translation_alias: 'common.client',
                value: 0,
            },
            {
                translation_alias: 'common.typography',
                value: 1,
            },
        ],

        DELIVERY_TYPE_COURIER_ID: 1,
        DELIVERY_TYPE_PICKUP_ID: 2,
        DELIVERY_TYPE_POST_OFFICE_ID: 3,
        DELIVERY_TYPE_CARRIER_ID: 4,

        list: [],
        listFilter: { ...COMMON_LIST_FILTER },

        tree: [],
        deliveryTypes: [],

        item: {},
        deliveryContact: {},

        formData: { ...COMMON_FORM_DATA },

        loadingGetList: false,
        loadingGetTree: false,
        loadingGetDeliveryTypes: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingSaveDeliveryContact: false,
        loadingDeleteItem: false,
        loadingGenerateExpressInvoice: false,
    },

    getters: {
        deliveryProviders(state, getters, rootState) {
            return rootState.typographies.modules.typographyList.filter((item) => item.module.type === state.DELIVERY_MODULE_TYPE);
        },

        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    actions: {
        GET_LIST: async ({ state, rootState, getters, rootGetters, commit }, filters) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `api/typographies/${rootGetters.currentTypography.id}/delivery/options`
                    : `api/typographies/${rootState.typographies.item.id}/delivery/options`;

                let resp = await $axios.get(path, {
                    params: filters || getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_TREE: async ({ state, rootState, getters, rootGetters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetTree', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `api/typographies/${rootGetters.currentTypography.id}/delivery/options/tree`
                    : `api/typographies/${rootState.typographies.item.id}/delivery/options/tree`;

                let resp = await $axios.get(path);

                commit('SET_TREE', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetTree', status: false });
            }
        },

        GET_DELIVERY_TYPES: async ({ state, rootState, getters, rootGetters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetDeliveryTypes', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `api/typographies/${rootGetters.currentTypography.id}/delivery/types`
                    : `api/typographies/${rootState.typographies.item.id}/delivery/types`;

                let resp = await $axios.get(path);

                commit('SET_DELIVERY_TYPES', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetDeliveryTypes', status: false });
            }
        },

        GET_ITEM: async ({ state, rootState, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `api/typographies/${rootGetters.currentTypography.id}/delivery/options/${id}`
                    : `api/typographies/${rootState.typographies.item.id}/delivery/options/${id}`;

                let resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootState, getters, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `api/typographies/${rootGetters.currentTypography.id}/delivery/options`
                    : `api/typographies/${rootState.typographies.item.id}/delivery/options`;

                let resp = await $axios.post(path, formData);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SAVE_DELIVERY_CONTACT: async ({ state, rootState, rootGetters, commit, dispatch }, { clientId, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveDeliveryContact', status: true });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/orders/clients/contacts/${clientId}`,
                    formData,
                );

                commit('SET_DELIVERY_CONTACT', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveDeliveryContact', status: false });
            }
        },

        DELETE_ITEM: async ({ state, rootState, getters, rootGetters, commit, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `api/typographies/${rootGetters.currentTypography.id}/delivery/options/${id}`
                    : `api/typographies/${rootState.typographies.item.id}/delivery/options/${id}`;

                let resp = await $axios.delete(path);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },

        GENERATE_EXPRESS_INVOICE: async ({ state, rootState, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGenerateExpressInvoice', status: true });

                let resp = await $axios
                    .post(`api/typographies/${rootGetters.currentTypography.id}/orders/${id}/delivery/express-invoice`)
                    .then((resp) => {
                        const url = resp.link;
                        const link = document.createElement('a');
                        link.href = url;
                        link.target = '_blank';
                        link.click();

                        URL.revokeObjectURL(link.href);
                    });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGenerateExpressInvoice', status: false });
            }
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_TREE(state, list) {
            state.tree = list;
        },

        SET_DELIVERY_TYPES(state, list) {
            state.deliveryTypes = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_DELIVERY_CONTACT(state, item) {
            state.deliveryContact = item;
        },

        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', { ...formData });
        },

        SET_FORM_DATA_PROP(state, { key, value }) {
            Vue.set(state.formData, key, value);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_ITEM(state) {
            state.item = {};
        },

        RESET_FORM_DATA(state) {
            Vue.set(state, 'formData', { ...COMMON_FORM_DATA });
        },

        RESET_STORE(state) {
            state.listFilter = { ...COMMON_LIST_FILTER };
        },
    },
};
