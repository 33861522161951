import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        BITRIX24_KEY: 'bitrix24',
        BITRIX24_ID: 1,

        BLOG_KEY: 'blog',
        BLOG_ID: 16,

        CHECKBOX_KEY: 'checkbox',
        CHECKBOX_ID: 12,

        CUSTOM_CSS_KEY: 'custom-css',
        CUSTOM_CSS_ID: 15,

        DPD_BALTIC_KEY: 'dpd-baltic',
        DPD_BALTIC_ID: 21,

        FACEBOOK_PIXEL_KEY: 'facebook',
        FACEBOOK_PIXEL_ID: 17,

        FLEXO_PRINT_KEY: 'flexo-print',
        FLEXO_PRINT_ID: 24,

        FREELANCE_HUNT_KEY: 'freelance-hunt',
        FREELANCE_HUNT_ID: 3,

        GOOGLE_ANALYTICS_KEY: 'google-analytics',
        GOOGLE_ANALYTICS_ID: 7,

        GTM_KEY: 'gtm',
        GTM_ID: 14,

        HOTJAR_KEY: 'hotjar',
        HOTJAR_ID: 25,

        INSTAGRAM_KEY: 'instagram-feed',
        INSTAGRAM_ID: 10,

        LAYOUT_EDITOR_KEY: 'layout-editor',
        LAYOUT_EDITOR_ID: 18,

        LIQPAY_KEY: 'liqpay',
        LIQPAY_ID: 6,

        MARKETPLACE_KEY: 'marketplace',
        MARKETPLACE_ID: 4,

        MULTIPLE_DOMAINS_KEY: 'multi-domain',
        MULTIPLE_DOMAINS_ID: 23,

        NOVA_PAY_ID: 'nova-pay',
        NOVA_PAY_KEY: 2,

        NOVA_POSHTA_KEY: 'novaposhta',
        NOVA_POSHTA_ID: 9,

        ROLL_PRINT_KEY: 'roll-print',
        ROLL_PRINT_ID: 11,

        STRIPE_KEY: 'stripe',
        STRIPE_ID: 20,

        THIRD_PARTY_AUTH_KEY: 'third-party-authorization',
        THIRD_PARTY_AUTH_ID: 22,

        SETTINGS_MAP: {
            checkbox: {
                cashier_login: null,
                cashier_password: null,
                cashbox_key: null,
            },

            'dpd-baltic': {
                api_key: null,
                delivery_warehouse_id: null,
            },

            facebook: [],

            gtm: {
                gtm_key: null,
            },

            hotjar: {
                hj_id: null,
            },

            'instagram-feed': {
                inst_key: null,
            },

            liqpay: {
                public_key: null,
                private_key: null,
            },

            novaposhta: {
                api_key: null,
                delivery_warehouse_id: null,
                delivery_warehouse_title: null,
            },

            stripe: {
                public_key: null,
                secret_key: null,
                webhook_key: null,
            },

            'third-party-authorization': {
                has_google_auth: false,
            },
        },

        MODULE_WIDGETS_MAP: {
            root: [
                'checkbox',
                'custom-css',
                'facebook',
                'gtm',
                'hotjar',
                'instagram-feed',
                'liqpay',
                'stripe',
                'third-party-authorization',
            ],

            typography: ['checkbox', 'facebook', 'gtm', 'hotjar', 'instagram-feed', 'liqpay', 'stripe'],
        },

        MODULE_TABS_MAP: {
            bitrix24: 'bitrix-24-index',
            'dpd-baltic': 'delivery-index',
            'google-analytics': 'google-analytics-index',
            novaposhta: 'delivery-index',
        },

        MODULE_ROUTES_MAP: {
            root: {},
            typography: {
                'custom-css': 'css',
            },
        },

        list: [],
        listPagination: {},
        listFilter: {
            page: null,
            limit: 30,
        },

        typographyList: [],
        typographyListPagination: {},
        typographyListFilter: {
            page: null,
            limit: 30,
        },

        item: {},
        typographyItem: {},

        loadingGetList: false,
        loadingGetTypographyList: false,
        loadingGetItem: false,
        loadingGetTypographyItem: false,
        loadingSaveItem: false,
        loadingSaveTypographyItem: false,
        loadingActivateTypographyList: false,
        loadingDeactivateTypographyList: false,
        loadingDeleteItem: false,
        loadingDeleteTypographyItem: false,
    },

    getters: {
        formattedList(state, getters) {
            return state.list.map((item) => ({
                ...item,
                is_widget: !!getters.formattedWidgetsMap.includes(item.alias),
                has_tab: !!state.MODULE_TABS_MAP[item.alias],
                has_route: !!getters.fomrattedRoutesMap[item.alias],
            }));
        },

        formattedTypographyList(state, getters) {
            return state.typographyList.map((item) => ({
                ...item,
                is_widget: !!getters.formattedWidgetsMap.includes(item.module.alias),
                has_tab: !!state.MODULE_TABS_MAP[item.module.alias],
                has_route: !!getters.fomrattedRoutesMap[item.module.alias],
            }));
        },

        formattedWidgetsMap(state, getters, rootState, rootGetters) {
            return rootGetters.hasERPAccess ? state.MODULE_WIDGETS_MAP.root : state.MODULE_WIDGETS_MAP.typography;
        },

        fomrattedRoutesMap(state, getters, rootState, rootGetters) {
            return rootGetters.hasERPAccess ? state.MODULE_ROUTES_MAP.root : state.MODULE_ROUTES_MAP.typography;
        },

        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        typographyListActiveFilter(state) {
            return Object.keys(state.typographyListFilter)
                .filter((key) => state.typographyListFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.typographyListFilter[key]), resp), {});
        },

        itemSettings(state) {
            return state.item.settings;
        },

        bitrix24Module(state) {
            return state.typographyList.find((item) => item.module.alias === state.BITRIX24_KEY) || null;
        },

        blogModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.BLOG_KEY) || null;
        },

        checkboxModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.CHECKBOX_KEY) || null;
        },

        customCSSModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.CUSTOM_CSS_KEY) || null;
        },

        dpdBalticModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.DPD_BALTIC_KEY) || null;
        },

        dpdBalticSettings(state, getters) {
            return getters.dpdBalticModule?.secured_settings || null;
        },

        facebookPixelModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.FACEBOOK_PIXEL_KEY) || null;
        },

        flexoPrintModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.FLEXO_PRINT_KEY) || null;
        },

        gtmModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.GTM_KEY) || null;
        },

        googleAnalyticsModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.GOOGLE_ANALYTICS_KEY) || null;
        },

        hotjarModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.HOTJAR_KEY) || null;
        },

        instagramFeedModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.INSTAGRAM_KEY) || null;
        },

        liqpayModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.LIQPAY_KEY) || null;
        },

        multipleDomainsModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.MULTIPLE_DOMAINS_KEY) || null;
        },

        novaPoshtaModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.NOVA_POSHTA_KEY) || null;
        },

        novaPoshtaSettings(state, getters) {
            return getters.novaPoshtaModule?.secured_settings || null;
        },

        rollPrintModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.ROLL_PRINT_KEY) || null;
        },

        stripeModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.STRIPE_KEY) || null;
        },

        thirdPartyAuthModule(state) {
            return state.typographyList.find((item) => item.module.alias === state.THIRD_PARTY_AUTH_KEY) || null;
        },

        hasBitrix24Module(state, getters) {
            return getters.bitrix24Module && !getters.bitrix24Module.disabled;
        },

        hasBlogModule(state, getters) {
            return getters.blogModule && !getters.blogModule.disabled;
        },

        hasCheckboxModule(state, getters) {
            return getters.checkboxModule && !getters.checkboxModule.disabled;
        },

        hasCustomCSSModule(state, getters) {
            return getters.customCSSModule && !getters.customCSSModule.disabled;
        },

        hasDPDBalticModule(state, getters) {
            return getters.dpdBalticModule && !getters.dpdBalticModule.disabled;
        },

        hasFacebookPixelModule(state, getters) {
            return getters.facebookPixelModule && !getters.facebookPixelModule.disabled;
        },

        hasFlexoPrintModule(state, getters) {
            return getters.flexoPrintModule && !getters.flexoPrintModule.disabled;
        },

        hasGTMModule(state, getters) {
            return getters.gtmModule && !getters.gtmModule.disabled;
        },

        hasGoogleAnalyticsModule(state, getters) {
            return getters.googleAnalyticsModule && !getters.googleAnalyticsModule.disabled;
        },

        hasHotjarModule(state, getters) {
            return getters.hotjarModule && !getters.hotjarModule.disabled;
        },

        hasInstagramFeedModule(state, getters) {
            return getters.instagramFeedModule && !getters.instagramFeedModule.disabled;
        },

        hasLiqpayModule(state, getters) {
            return getters.liqpayModule && !getters.liqpayModule.disabled;
        },

        hasMultipleDomainsModule(state, getters) {
            return getters.multipleDomainsModule && !getters.multipleDomainsModule.disabled;
        },

        hasNovaPoshtaModule(state, getters) {
            return getters.novaPoshtaModule && !getters.novaPoshtaModule.disabled;
        },

        hasRollPrintModule(state, getters) {
            return getters.rollPrintModule && !getters.rollPrintModule.disabled;
        },

        hasStripeModule(state, getters) {
            return getters.stripeModule && !getters.stripeModule.disabled;
        },

        hasThirdPartyAuthModule(state, getters) {
            return getters.thirdPartyAuthModule && !getters.thirdPartyAuthModule.disabled;
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, commit }, filters = {}) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = `api/modules`;

                let resp = await $axios.get(path, {
                    params: Object.keys(filters).length ? filters : getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_TYPOGRAPHY_LIST: async ({ state, getters, rootState, rootGetters, commit }, { typographyId, filters = {} }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetTypographyList', status: true });

                let path = `api/typographies/${typographyId}/modules`;

                let resp = await $axios.get(path, {
                    params: Object.keys(filters).length ? filters : getters.typographyListActiveFilter,
                });

                commit('SET_TYPOGRAPHY_LIST', resp.list);
                commit('SET_TYPOGRAPHY_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetTypographyList', status: false });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = `api/modules/${id}`;

                let resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        GET_TYPOGRAPHY_ITEM: async ({ state, getters, rootState, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetTypographyItem', status: true });

                const typographyId = rootGetters.currentTypography?.id || rootState.typographies.item.id;

                let path = `api/typographies/${typographyId}/modules/${id}`;

                let resp = await $axios.get(path);

                commit('SET_TYPOGRAPHY_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetTypographyItem', status: false });
            }
        },

        SAVE_ITEM: async ({ commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = `api/modules`;

                let resp = await $axios.post(path, formData);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SAVE_TYPOGRAPHY_LIST: async ({ commit, dispatch }, { typographyId, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingActivateTypographyList', status: true });

                await Promise.all([
                    dispatch('ACTIVATE_TYPOGRAPHY_LIST', {
                        typographyId,
                        formData: formData.activeList,
                    }),

                    dispatch('DEACTIVATE_TYPOGRAPHY_LIST', {
                        typographyId,
                        formData: formData.disabledList,
                    }),
                ]);

                dispatch('GET_TYPOGRAPHY_LIST', { typographyId });
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingActivateTypographyList', status: false });
            }
        },

        SAVE_TYPOGRAPHY_ITEM: async ({ rootState, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveTypographyItem', status: true });

                const typographyId = rootGetters.currentTypography?.id || rootState.typographies.item.id;

                let path = `api/typographies/${typographyId}/modules`;

                let resp = await $axios.post(path, formData);

                dispatch('GET_TYPOGRAPHY_LIST', { typographyId });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveTypographyItem', status: false });
            }
        },

        ACTIVATE_TYPOGRAPHY_LIST: async ({ commit, dispatch }, { typographyId, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingActivateTypographyList', status: true });

                let path = `api/typographies/${typographyId}/modules/activate`;

                let resp = await $axios.post(path, formData);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingActivateTypographyList', status: false });
            }
        },

        DEACTIVATE_TYPOGRAPHY_LIST: async ({ commit, dispatch }, { typographyId, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeactivateTypographyList', status: true });

                let path = `api/typographies/${typographyId}/modules/deactivate`;

                let resp = await $axios.post(path, formData);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeactivateTypographyList', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = `api/modules/${id}`;

                let resp = await $axios.delete(path);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },

        DELETE_TYPOGRAPHY_ITEM: async ({ rootState, rootGetters, commit, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteTypographyItem', status: true });

                const typographyId = rootGetters.currentTypography?.id || rootState.typographies.item.id;

                let path = `api/typographies/${typographyId}/modules/${id}`;

                let resp = await $axios.delete(path);

                dispatch('GET_TYPOGRAPHY_LIST', { typographyId });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteTypographyItem', status: false });
            }
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_TYPOGRAPHY_LIST(state, list) {
            state.typographyList = list;
        },

        SET_TYPOGRAPHY_PAGINATION(state, pagination) {
            state.typographyListPagination = pagination;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_TYPOGRAPHY_ITEM(state, item) {
            state.typographyItem = item;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));

            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        UPDATE_TYPOGRAPHY_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.typographyListFilter, key, value));

            Vue.set(state.typographyListFilter, 'page', newFilterlist.page || 1);
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: null,
            };
        },

        RESET_TYPOGRAPHY_LIST(state) {
            state.typographyList = [];
        },

        RESET_TYPOGRAPHY_STORE(state) {
            state.typographyListFilter = {
                page: null,
                limit: null,
            };
        },
    },
};
