<template>
    <div ref="dd" :id="`dd-${hash}`" class="druk-c-dd" :class="{ 'druk-has-static': hasStaticContent }">
        <div class="druk-c-dd__wrapper">
            <div class="druk-c-dd__base">
                <slot v-bind:isShown="isShown" name="druk-c-dd-base"></slot>
            </div>

            <div
                ref="dd-content"
                class="druk-c-dd__content"
                :class="{
                    [`druk-l-surface-${surface}`]: !!surface,
                    'druk-is-shown': isShown,
                    'druk-is-temporary-hidden': isTemporaryHidden && !hasStaticContent,
                    [`druk-is-from-${position.horizontal}`]: !!position,
                    [`druk-is-from-${position.vertical}`]: !!position,
                    'druk-is-static': hasStaticContent,
                    'druk-is-reduced': isReduced,
                    'druk-is-small': isSmall,
                }"
                :style="variables">
                <slot name="druk-c-dd-list"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'druk-dropdown',

        props: {
            position: {
                type: Object,
                default: () => {
                    return {
                        horizontal: 'end', // @Explanation: Available values: strt, ctr, end
                        vertical: 'mid', // @Explanation: Available values: top, mid, bot
                    };
                },
            },

            surface: {
                type: String,
                default: 'tint-bright',
            },

            hasStaticContent: Boolean,

            isReduced: Boolean,
            isSmall: Boolean,
        },

        data() {
            return {
                MIN_DROP_PADDING: 40, // @Explanation: This value is equal to the list item height. M3 guidelines - https://m3.material.io/components/menus/guidelines#f160b5ee-995d-435a-8545-f635d1d4c8b9

                hash: null,

                variables: {},

                topPosition: 0,
                leftPosition: 0,

                listener: null,

                isShown: false,
                isTemporaryHidden: false,
            };
        },

        created() {
            this.listener = (e) => this.setDropdownPosition(this.LONG_ANIMATION_DELAY, e);
            this.hash = $fn.generateHash();

            document.addEventListener('click', this.onToggle);

            if (this.isReduced)
                window.addEventListener('resize', () => {
                    this.setContentHeight();
                });
        },

        mounted() {
            this.setDropdownPosition();
            if (this.isReduced) this.setContentHeight();

            this.$bus.$on('make-forced-dd-close', () => (this.isShown = false));
            document.addEventListener('scroll', this.listener);
        },

        beforeDestroy() {
            this.isShown = false;

            this.$bus.$off('make-forced-dd-close');
            document.removeEventListener('click', this.onToggle);
            document.removeEventListener('scroll', this.listener);

            if (this.isReduced)
                window.removeEventListener('resize', () => {
                    this.setContentHeight();
                });
        },

        watch: {
            isShown() {
                this.isShown ? this.$emit('toShow') : this.$emit('toHide');
            },
        },

        computed: {
            ...mapState({
                SHORT_ANIMATION_DELAY: (state) => state.constant.SHORT_ANIMATION_DELAY,
                LONG_ANIMATION_DELAY: (state) => state.constant.LONG_ANIMATION_DELAY,
            }),

            ...mapGetters({
                isSMMaxWidth: 'isSMMaxWidth',
            }),
        },

        methods: {
            onToggle(e) {
                if (!e?.target?.closest(`#dd-${this.hash}`)) {
                    this.isShown = false;
                    return;
                }

                this.isShown = !this.isShown;
                this.setDropdownPosition(this.SHORT_ANIMATION_DELAY, e);
            },

            setDropdownPosition(delay, e) {
                if (!this.isShown) return;

                const ddBounds = $fn.getBoundProps(this.$refs.dd.getBoundingClientRect());

                this.isTemporaryHidden = true;

                Object.entries(ddBounds).forEach(([key, value]) =>
                    this.$set(this.variables, `--druk-u-dd-base-bounds-${key}`, `${this.hasStaticContent ? 0 : value}px`),
                );

                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    this.isTemporaryHidden = false;
                }, delay || this.LONG_ANIMATION_DURATION);
            },

            setContentHeight() {
                let ddContentBounds = this.$refs['dd-content']?.getBoundingClientRect();
                if (!ddContentBounds) return;

                let maxHeight = ddContentBounds.height - (ddContentBounds.bottom - window.innerHeight) - this.MIN_DROP_PADDING;
                this.$refs['dd-content'].style['max-height'] = `${maxHeight}px`;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-dd {
        display: inline-block;
        &.druk-has-static {
            position: relative;
            z-index: 10;
        }
        &__content {
            --druk-u-dd-content-shown-scale-x: 1;
            --druk-u-dd-content-shown-scale-y: 1;
            --druk-u-dd-content-hidden-scale-x: 0;
            --druk-u-dd-content-hidden-scale-y: 0;

            --druk-u-dd-conctent-transform-origin-horizontal: center;
            --druk-u-dd-conctent-transform-origin-vertical: center;

            --druk-u-dd-content-translate: translate(var(--druk-u-dd-content-translate-x), var(--druk-u-dd-content-translate-y));

            --druk-u-dd-content-shown-scale: scale(var(--druk-u-dd-content-shown-scale-x), var(--druk-u-dd-content-shown-scale-y));
            --druk-u-dd-content-hidden-scale: scale(var(--druk-u-dd-content-hidden-scale-x), var(--druk-u-dd-content-hidden-scale-y));

            pointer-events: none;
            position: fixed;
            top: var(--druk-u-dd-content-top);
            left: var(--druk-u-dd-content-left);
            transform: var(--druk-u-dd-content-translate) var(--druk-u-dd-content-hidden-scale);
            transform-origin: var(--druk-u-dd-conctent-transform-origin-horizontal) var(--druk-u-dd-conctent-transform-origin-vertical);
            padding: 8px 0;
            border-radius: 4px;
            box-shadow: var(--druk-elevation-2);
            opacity: 0;
            transition:
                opacity 0 var(--druk-easing-emphasized-accelerate),
                transform 0 var(--druk-easing-emphasized-accelerate);
            z-index: 10;
            &.druk-is-shown {
                pointer-events: all;
                transform: var(--druk-u-dd-content-translate) var(--druk-u-dd-content-shown-scale);
                opacity: 1;
                transition:
                    opacity var(--druk-duration-medium-2) var(--druk-easing-emphasized-decelerate),
                    transform var(--druk-duration-short-2) var(--druk-easing-emphasized-decelerate);
            }
            &.druk-is-temporary-hidden {
                transform: var(--druk-u-dd-content-translate) var(--druk-u-dd-content-hidden-scale);
                opacity: 0;
                transition: opacity var(--druk-duration-short-1) var(--druk-easing-emphasized-accelerate);
            }
            &.druk-is-static {
                position: absolute;
                overflow: auto;
            }
            &.druk-is-small {
                padding: 4px 0;
            }
            &.druk-is-reduce {
                overflow: auto;
            }
        }
    }

    .druk-is-from {
        &-strt {
            --druk-u-dd-content-left: var(--druk-u-dd-base-bounds-left);
            --druk-u-dd-content-translate-x: 0%;
            --druk-u-dd-conctent-transform-origin-horizontal: left;
            &.druk-is-from-mid {
                --druk-u-dd-content-translate-x: -100%;
                --druk-u-dd-content-hidden-scale-y: 0.38;
                --druk-u-dd-conctent-transform-origin-horizontal: right;
            }
        }
        &-ctr {
            --druk-u-dd-content-left: 50%;
            --druk-u-dd-content-translate-x: -50%;
            &.druk-is-from-mid {
                --druk-u-dd-content-hidden-scale-x: 0.38;
                --druk-u-dd-content-hidden-scale-y: 0.38;
            }
            &:not(.druk-is-static) {
                --druk-u-dd-content-left: var(--druk-u-dd-base-bounds-left);
                --druk-u-dd-content-translate-x: calc(-50% + var(--druk-u-dd-base-bounds-width) / 2);
            }
        }
        &-end {
            --druk-u-dd-content-translate-x: 0%;
            --druk-u-dd-conctent-transform-origin-horizontal: right;
            right: 0px;
            &.druk-is-from-mid {
                --druk-u-dd-content-left: calc(var(--druk-u-dd-base-bounds-left) + var(--druk-u-dd-base-bounds-width));
                --druk-u-dd-content-translate-x: 0px;
                --druk-u-dd-content-hidden-scale-y: 0.38;
                --druk-u-dd-conctent-transform-origin-horizontal: left;
            }
            &.druk-is-from-mid.druk-is-static {
                left: 100%;
                right: initial;
            }
            &:not(.druk-is-static) {
                --druk-u-dd-content-left: var(--druk-u-dd-base-bounds-left);
                --druk-u-dd-content-translate-x: calc(-100% + var(--druk-u-dd-base-bounds-width));
                right: initial;
            }
        }
        &-top {
            --druk-u-dd-content-top: var(--druk-u-dd-base-bounds-top);
            --druk-u-dd-content-translate-y: -100%;

            --druk-u-dd-content-hidden-scale-x: 0.38;
            --druk-u-dd-conctent-transform-origin-vertical: bottom;
        }
        &-mid {
            --druk-u-dd-content-top: 50%;
            --druk-u-dd-content-translate-y: -50%;
            &:not(.druk-is-static) {
                --druk-u-dd-content-top: var(--druk-u-dd-base-bounds-top);
                --druk-u-dd-content-translate-y: calc(-50% + var(--druk-u-dd-base-bounds-height) / 2);
            }
            &.druk-is-from-end {
                --druk-u-dd-content-translate-x: var(--druk-u-dd-base-bounds-width);
            }
        }
        &-bot {
            --druk-u-dd-content-top: 100%;
            --druk-u-dd-content-translate-y: 0%;
            --druk-u-dd-content-hidden-scale-x: 0.38;
            --druk-u-dd-conctent-transform-origin-vertical: top;
            &:not(.druk-is-static) {
                --druk-u-dd-content-top: calc(var(--druk-u-dd-base-bounds-top) + var(--druk-u-dd-base-bounds-height));
            }
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        width: auto;
        overflow: hidden;
        &.druk-is-like-line {
            flex-direction: row;
            padding: 0 var(--druk-gap-s);
            .list__item {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px var(--druk-gap-s);
                min-width: 32px;
                height: 32px;
                border-radius: 4px;
            }
        }
        &__item {
            cursor: pointer;
            display: flex;
            margin: auto 0;
            padding: 10px 16px;
            height: 40px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            font-weight: 500;
            span {
                margin: auto 0;
            }
            &.druk-is-active {
                pointer-events: none;
                color: var(--druk-on-surface);
                background-color: var(--druk-state-layers-secondary-0-08);
            }
            &.druk-is-small {
                padding: 8px 16px;
                height: 32px;
                min-width: 32px;
                min-height: 32px;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.5px;
                font-weight: 500;
            }
        }
        @media (max-width: $druk-breakpoints-sm) {
            overflow-x: scroll;
            max-width: 200px;
        }
    }
</style>
