<template>
    <div class="druk-c-loader" :class="{ 'druk-is-block': isBlock }">
        <div class="druk-c-loader__wrapper">
            <div class="druk-c-loader__base">
                <druk-circular-indicator :size="size" :color="color" />
            </div>

            <div v-if="support" class="druk-c-loader__support">{{ support }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'druk-loader',

        props: {
            label: String,

            size: {
                type: String,
                default: 'xs',
            },
            color: {
                type: String,
                default: 'primary',
            },

            isBlock: Boolean,
            isIcon: Boolean,
        },

        computed: {
            support() {
                return this.isIcon ? null : this.label || this.$t('common.module_loading');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-loader {
        display: inline-flex;
        vertical-align: middle;
        line-height: 1;
        &.druk-is-block {
            display: block;
        }
        &__wrapper {
            display: inline-flex;
            align-items: center;
        }
        &__base {
            display: inline-block;
            line-height: 1;
        }
        &__support {
            padding-left: var(--druk-gap-s);
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            font-weight: 500;
        }
    }
</style>
