class CanvasLayout {
    constructor({ id, dimensions, baseColor, borderGap }) {
        const figure = document.getElementById(id),
            cvs = (this.cvs = document.createElement(id));

        this.bottomGap = borderGap.bottom;
        this.leftGap = borderGap.left;
        this.color = baseColor;

        cvs.width = dimensions.width + this.leftGap;
        cvs.height = dimensions.height;
        cvs.color = this.color;

        this.ctx = cvs.getContext('2d');
        figure.appendChild(cvs);
    }

    getCanvas() {
        return this.cvs;
    }

    reset(curve, evt) {
        this.cvs.width = this.cvs.width;

        if (evt && curve) {
            curve.mouse = { x: evt.offsetX, y: evt.offsetY };
        }
    }

    drawSkeleton(curve, labels, ranges, colors, has_preview) {
        let points = curve.points;

        if (ranges) this.drawRanges(points, ranges, colors.concealer, colors.border);

        this.drawBorder(colors.border);
        this.drawAxesArea(points[0], points[3]);

        this.drawLabels(points, labels, colors.text);

        if (!has_preview) {
            this.drawTargents(points, colors.targents);
            this.drawPoints(points, colors.base, colors.circle);
        }
    }

    drawCurve(curve, color) {
        this.setLineWidth(2);
        this.setStrokeStyle(color || this.color);

        this.ctx.beginPath();

        let points = curve.points;
        this.ctx.moveTo(points[0].x, points[0].y);

        this.ctx.bezierCurveTo(points[1].x, points[1].y, points[2].x, points[2].y, points[3].x, points[3].y);

        this.ctx.stroke();
        this.ctx.closePath();
    }

    drawBorder(color) {
        this.setLineWidth(1);
        this.setStrokeStyle(color);

        this.ctx.strokeRect(
            this.leftGap,
            1,
            this.ctx.canvas.width - this.leftGap - 1,
            this.ctx.canvas.height - this.bottomGap * 1 - 1,
        );
    }

    drawAxesArea(start, end) {
        this.drawLeftBounds(start, end);
        this.drawBottomBounds(start, end);
    }

    drawLeftBounds(start, end) {
        let topBoundPoints = [
                { x: -1 * this.leftGap, y: start.y + 1 },
                { x: this.leftGap, y: start.y + 1 },
            ],
            bottomBoundPoints = [
                { x: -1 * this.leftGap, y: end.y },
                { x: this.leftGap, y: end.y },
            ];

        this.drawLine(topBoundPoints[0], topBoundPoints[1]);
        this.drawLine(bottomBoundPoints[0], bottomBoundPoints[1]);
    }

    drawBottomBounds(start, end) {
        const extend = 30;

        let leftBoundPoints = [
                { x: start.x, y: start.y - extend },
                { x: start.x, y: end.y + extend },
            ],
            rigthBoundPoints = [
                { x: end.x, y: start.y - extend },
                { x: end.x, y: end.y + extend },
            ];

        this.drawLine(leftBoundPoints[0], leftBoundPoints[1]);
        this.drawLine(rigthBoundPoints[0], rigthBoundPoints[1]);
    }

    drawLine(start, end) {
        this.ctx.beginPath();

        this.ctx.moveTo(start.x, start.y);
        this.ctx.lineTo(end.x, end.y);

        this.ctx.stroke();
    }

    drawLabels(points, labels, color) {
        this.setFillStyle(color);

        this.ctx.font = 'normal 500 14px Montserrat';

        const priceExtend = 5,
            clicksExtend = this.bottomGap - 5;

        let topPriceCoors = [0, points[0].y + this.ctx.measureText(labels.price.start).actualBoundingBoxAscent + priceExtend],
            bottomPriceCoords = [0, points[3].y - priceExtend],
            leftClicksCoords = [points[0].x, points[3].y + clicksExtend],
            rightClicksCoords = [points[3].x - this.ctx.measureText(labels.count.end).width, points[3].y + clicksExtend];

        this.drawText(labels.price.start, topPriceCoors);
        this.drawText(labels.price.end, bottomPriceCoords);
        this.drawText(labels.count.start, leftClicksCoords);
        this.drawText(labels.count.end, rightClicksCoords);
    }

    drawText(text, point) {
        this.ctx.fillText(text, point[0], point[1]);
    }

    drawTargents(points, color) {
        this.setStrokeStyle(color);

        this.drawLine(points[0], points[1]);
        this.drawLine(points[2], points[3]);
    }

    drawPoints(points, color, circleColor) {
        this.setStrokeStyle(color || this.color);

        this.drawCircle(points[1], 4, circleColor);
        this.drawCircle(points[2], 4, circleColor);
    }

    drawCircle(p, r, color) {
        this.setFillStyle(color);
        this.setLineWidth(2);

        this.ctx.beginPath();
        this.ctx.arc(p.x, p.y, r, 0, 2 * Math.PI);
        this.ctx.fill();
        this.ctx.stroke();
    }

    drawRanges(points, ranges, concealerColor, borderColor) {
        let areaWidth = points[3].x - points[0].x,
            areaHeight = points[3].y - points[0].y,
            widthdivider = [...ranges.list].pop().end / areaWidth,
            widthMultiplier = [...ranges.list].pop().end / ranges.count,
            heightPriceArea = ranges.max_price - ranges.min_price;

        ranges.list.forEach((range, index, list) => {
            let width =
                    ((range.end ? range.end + 1 - range.start : 1) / widthdivider) * widthMultiplier +
                    +!!(index === 0) * widthMultiplier,
                height = ((heightPriceArea - (ranges.max_price - range.price)) / heightPriceArea) * areaHeight,
                point = {
                    x: ((range.start - 1) / widthdivider) * widthMultiplier + points[0].x - +!!(index === 0) * widthMultiplier,
                    y: points[3].y - points[0].y - height,
                },
                color = this.colorShades[index];

            if (height > 0) this.drawRange(width, height, point, color);
        });

        this.drawСoncealers(points, concealerColor, borderColor);
    }

    drawRange(width, height, point, color) {
        this.setStrokeStyle(`rgba(${color.r}, ${color.g}, ${color.b}, 1)`);
        this.setFillStyle(`rgba(${color.r}, ${color.g}, ${color.b}, 0.4)`);
        this.setLineWidth(1);

        this.ctx.strokeRect(point.x, point.y, width, height);
        this.ctx.fillRect(point.x, point.y, width, height);
    }

    drawСoncealers(points, concealerColor, borderColor) {
        this.setFillStyle(concealerColor);
        this.setStrokeStyle(borderColor);

        this.drawСoncealer(this.leftGap, points[0].y, points[0].x - this.leftGap, points[3].y);
        this.drawСoncealer(points[3].x, points[0].y, points[0].x - this.leftGap, points[3].y);
    }

    drawСoncealer(x, y, width, height) {
        this.ctx.fillRect(x, y, width, height);
    }

    setStrokeStyle(color) {
        this.ctx.strokeStyle = color;
    }

    setFillStyle(color) {
        this.ctx.fillStyle = color;
    }

    setLineWidth(width) {
        this.ctx.lineWidth = width;
    }

    noFill() {
        this.ctx.fillStyle = 'transparent';
    }

    generateColorShades(color, count, isDarkTheme) {
        // @Explanation important trick for HEX colors - /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
        let base = color.replace(/[a-zA-Z\(\)]/g, '').split(', '),
            r = parseInt(base[0], 16),
            g = parseInt(base[1], 16),
            b = parseInt(base[2], 16);

        let darkMultipliers = Array.from({ length: count }, (_, i) => 10 - i + i / count).slice(0, (count / 2).toFixed()),
            ligthMultipliers = Array.from({ length: count }, (_, i) => 10 + i - i / count).slice(0, (count / 2).toFixed()),
            multipliers = ligthMultipliers.reverse().concat(darkMultipliers);

        this.colorShades = multipliers.map((item) => ({
            r: (r * item) / (isDarkTheme ? 36 : 12),
            g: (g * item) / (isDarkTheme ? 36 : 12),
            b: (b * item) / (isDarkTheme ? 36 : 12),
        }));
    }
}

export default CanvasLayout;
