<template>
    <div class="vmodal">
        <div class="vmodal__container">
            <div class="vmodal__head">
                <div class="vmodal__title">
                    <div class="vmodal__title-text">{{ $t('btn.copy') }} {{ $t('typog.modal.title') }} {{ title }}</div>
                </div>

                <druk-icon-button :type="'standard'" :icon="'xmark'" :isDisabled="loadingCopyItem" @click="$emit('close')" />
            </div>

            <div class="vmodal__main">
                <div class="druk-l-form">
                    <div class="druk-l-form__body">
                        <div class="druk-l-form__item">
                            <form-input
                                v-model="formData.title"
                                name="title"
                                rules="required"
                                :surface="'tint-brighter'"
                                :label="$t('form.title')" />
                        </div>

                        <div class="druk-l-form__item">
                            <form-input
                                v-model="formData.domain"
                                name="domain"
                                rules="required"
                                :surface="'tint-brighter'"
                                :label="$t('typog.form.domen')" />
                        </div>

                        <div class="druk-l-form__item">
                            <form-select
                                v-model="formData.language"
                                :label="$t('domains.main_website_language')"
                                :options="clientLanguages"
                                rules="required"
                                :surface="'tint-brighter'"
                                name="language" />
                        </div>

                        <div class="druk-l-form__item">
                            <form-input
                                v-model="formData.email"
                                name="email"
                                rules="required"
                                :surface="'tint-brighter'"
                                :label="$t('form.email')" />
                        </div>

                        <div class="druk-l-form__item druk-is-last">
                            <form-input
                                v-model="formData.alias"
                                name="alias"
                                rules="required"
                                :surface="'tint-brighter'"
                                :label="$t('form.alias')" />
                        </div>
                    </div>

                    <div class="druk-l-form__footer">
                        <div class="druk-l-form__footer-item">
                            <druk-button :icon="'floppy-disk'" :label="$t('btn.copy')" :isWaiting="loadingCopyItem" @click="onCopy" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'typography-copy',

        props: ['id', 'title'],

        data() {
            return {
                formData: {
                    title: null,
                    domain: null,
                    language: null,
                    email: null,
                    alias: null,
                    color: null,
                },
            };
        },

        computed: {
            ...mapState({
                TYPOGRAPHY_CLASSIC_COLOR: (state) => state.constant.TYPOGRAPHY_CLASSIC_COLOR,

                clientLanguages: (state) => state.languages.clientList,

                loadingCopyItem: (state) => state.typographies.loadingCopyItem,
            }),
        },

        methods: {
            ...mapActions({
                COPY_ITEM: 'typographies/COPY_ITEM',
            }),

            async onCopy(e) {
                let result = await this.$validator.validate();

                if (result) {
                    try {
                        await this.COPY_ITEM({
                            id: this.id,
                            formData: Object.assign(
                                { ...this.formData },
                                {
                                    lang_id: this.formData.language.id,
                                    color: this.TYPOGRAPHY_CLASSIC_COLOR,
                                },
                            ),
                        });

                        this.$emit('close');
                    } catch (e) {
                        $fn.setValidateErrors(e, this.errors);
                    }
                } else {
                    $fn.showFormError(e.target.closest('.druk-c-btn'), this.$validator.errors.items);
                }
            },
        },
    };
</script>

<style lang="scss" scoped></style>
