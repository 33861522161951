var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"druk-l-grid"},[_c('div',{staticClass:"druk-u-md-12",class:{
            'druk-l-grid__col-2 druk-u-lg-4': _vm.isPageLayout,
            'druk-l-grid__col-4': !_vm.isPageLayout,
        }},[_c('div',{staticClass:"druk-l-form__item"},[_c('form-img',{attrs:{"name":"avatar","surface":_vm.isPageLayout ? 'tint-pale' : 'tint-brighter',"label":_vm.$t('form.ava')},model:{value:(_vm.formData.avatar),callback:function ($$v) {_vm.$set(_vm.formData, "avatar", $$v)},expression:"formData.avatar"}})],1)]),_c('div',{staticClass:"druk-u-md-12",class:{
            'druk-l-grid__col-10 druk-u-lg-8': _vm.isPageLayout,
            'druk-l-grid__col-8': !_vm.isPageLayout,
        }},[_c('div',{staticClass:"druk-l-grid"},[_c('div',{class:{
                    'druk-l-grid__col-6 druk-u-def-12 druk-u-def-gap-xl': _vm.isPageLayout,
                    'druk-l-grid__col-12': !_vm.isPageLayout,
                }},[_c('div',{staticClass:"druk-l-form__item"},[_c('form-input',{attrs:{"label":_vm.$t('form.name.last'),"rules":"required","surface":_vm.isPageLayout ? 'tint-pale' : 'tint-brighter',"name":"last_name"},model:{value:(_vm.formData.last_name),callback:function ($$v) {_vm.$set(_vm.formData, "last_name", $$v)},expression:"formData.last_name"}})],1),_c('div',{staticClass:"druk-l-form__item"},[_c('form-input',{attrs:{"label":_vm.$t('admin.form.name'),"rules":"required","surface":_vm.isPageLayout ? 'tint-pale' : 'tint-brighter',"name":"name"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('div',{staticClass:"druk-l-form__item"},[_c('form-date-picker',{attrs:{"label":_vm.$t('form.birthday'),"surface":_vm.isPageLayout ? 'tint-pale' : 'tint-brighter',"name":"birthday","surfaceVariant":_vm.isPageLayout ? 'tint-brighter' : 'tint-pale',"hasDateLimit":true,"singleDatePicker":true,"showDropdowns":true},model:{value:(_vm.formData.birthday),callback:function ($$v) {_vm.$set(_vm.formData, "birthday", $$v)},expression:"formData.birthday"}})],1),_c('div',{staticClass:"druk-l-form__item",class:{ 'druk-is-last': _vm.isPageLayout }},[_c('form-input',{attrs:{"label":_vm.$t('form.email'),"rules":"required|email","surface":_vm.isPageLayout ? 'tint-pale' : 'tint-brighter',"name":"email"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1)]),_c('div',{class:{
                    'druk-l-grid__col-6 druk-u-def-12': _vm.isPageLayout,
                    'druk-l-grid__col-12': !_vm.isPageLayout,
                }},[_c('div',{staticClass:"druk-l-form__item"},[_c('form-input-tel',{attrs:{"label":_vm.$t('form.phone'),"rules":"phone_number","surface":_vm.isPageLayout ? 'tint-pale' : 'tint-brighter',"name":"phone"},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})],1),_c('div',{staticClass:"druk-l-form__item"},[_c('div',{staticClass:"druk-l-form__item-base"},[_c('form-input',{attrs:{"label":_vm.isEditMode ? _vm.$t('empl.pass.new') : _vm.$t('empl.pass'),"id":"password","rules":_vm.isEditMode ? 'min:8' : 'required|min:8',"surface":_vm.isPageLayout ? 'tint-pale' : 'tint-brighter',"name":"password"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('div',{staticClass:"druk-l-form__item-addl"},[_c('druk-icon-button',{attrs:{"type":'outlined',"icon":'copy',"isDisabled":!_vm.formData.password},on:{"click":_vm.onCopyPassword}})],1)],1)]),(_vm.isOwner)?_c('div',{staticClass:"druk-l-form__item"},[_c('form-search-async',{class:{ 'druk-is-disabled': _vm.isEditMode },attrs:{"label":_vm.$t('common.typog'),"path":_vm.apiPathList,"keySearch":'title',"options":_vm.typographies,"hint":_vm.$t('common.async_prompt'),"rules":'required',"surface":_vm.isPageLayout ? 'tint-pale' : 'tint-brighter',"name":"typography"},on:{"search":_vm.onSearch,"input":function($event){_vm.formData.typography = $event}},model:{value:(_vm.formData.typography),callback:function ($$v) {_vm.$set(_vm.formData, "typography", $$v)},expression:"formData.typography"}})],1):_vm._e(),_c('div',{staticClass:"druk-l-form__item",class:{ 'druk-is-last': _vm.hasERPAccess }},[_c('form-select',{class:{
                            'has-loading': _vm.loadingGetRole || _vm.loadingGetRoleTemplate,
                            'druk-is-disabled': _vm.isOwnerEditDisabled || _vm.isCurrentUser,
                        },attrs:{"options":_vm.roleOptions,"label":_vm.$t('empl.change.role'),"option_label":"title","groupLabel":_vm.hasTypographyAccess ? 'title' : null,"groupValues":_vm.hasTypographyAccess ? 'options' : null,"rules":"required","surface":_vm.isPageLayout ? 'tint-pale' : 'tint-brighter',"notEmpty":true,"name":"role"},on:{"input":function($event){return _vm.$emit('onChangeRole', $event)}},model:{value:(_vm.formData.role),callback:function ($$v) {_vm.$set(_vm.formData, "role", $$v)},expression:"formData.role"}})],1),(_vm.hasTypographyAccess)?_c('div',{staticClass:"druk-l-form__item druk-is-last"},[_c('form-switch',{attrs:{"name":"is_auto_assigned","label":_vm.$t('employees.item.is_auto_assigned')},model:{value:(_vm.formData.is_auto_assigned),callback:function ($$v) {_vm.$set(_vm.formData, "is_auto_assigned", $$v)},expression:"formData.is_auto_assigned"}})],1):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }