import Vue from 'vue';

import groups from './groups';
import rapports from './rapports';
import variables from './variables';
import params from './params';
import options from './options';

export default {
    namespaced: true,

    modules: {
        groups,
        rapports,
        variables,
        params,
        options,
    },

    state: {
        ITEM_COMMON_DATA: {
            group: null,
            title: null,

            makeready_time: 0,
            clicks_min: 1,
            clicks_max: 100000,

            margin_top: 0,
            margin_right: 0,
            margin_bottom: 0,
            margin_left: 0,

            has_workflow: false,
            is_rapport: false,
        },

        ITEM_KIND_FORM_DATA: {
            price: null,
            manufacturing_time: null,

            pickedOptions: [],
            pickedVariables: [],

            options: [],
            variables: [],

            calc_type: 'ranges',
            dimension: null,

            ranges: [],
            curve: null,
            formula: null,

            data: {
                optionsList: [],
                variablesList: [],
            },
        },

        list: [],
        listPagination: {},
        listFilter: {
            page: null,
            limit: 30,
            title: null,
        },

        item: {},

        equipmentsList: [],

        formData: {},
        trFormData: {},

        loadingGetList: false,
        loadingGetItem: false,
        loadingGetItemTR: false,
        loadingSaveItem: false,
        loadingSaveItemTR: false,
        loadingDeleteItem: false,
        loadingDeleteItemTR: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        listEquipmentsByGroups(state) {
            return $fn.map(state.list, (el) => {
                return {
                    title: el.title,
                    libs: el.equipment,
                };
            });
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_FILTER_LIST(state, { key, val }) {
            state.listFilter[key] = val;
        },

        SET_EQUIPMENTS_LIST(state, list) {
            state.equipmentsList = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_FORM_DATA(state, formData) {
            state.formData = formData;
        },

        SET_FORM_DATA_PROP(state, { key, value }) {
            Vue.set(state.formData, key, value);
        },

        SET_TR_FORM_DATA(state, formData) {
            state.trFormData = formData;
        },

        SET_TR_FORM_DATA_PROP(state, { key, value }) {
            Vue.set(state.trFormData, key, value);
        },

        SET_TR_FORM_DATA_PROP_VALUE(state, { index, key, valueKey, value }) {
            Vue.set(state.trFormData[key][index], valueKey, value);
        },

        SET_TR_FORM_DATA_DATA_PROP(state, { key, value }) {
            Vue.set(state.trFormData.data, key, value);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        ADD_CLONE_TR_TO_ITEM(state, item) {
            item.hasClone = true;
            state.item.list.push(item);
        },

        PUSH_TO_TR_FORM_DATA_PROP(state, { key, value }) {
            let map = state.trFormData[key].concat(value);
            Vue.set(state.trFormData, key, map);
        },

        REPLACE_TR_FORM_DATA_PROP(state, { index, key, value }) {
            let map = state.trFormData[key];

            map[index] = value;
            Vue.set(state.trFormData, key, map);
        },

        REPLACE_TR_FORM_DATA_DATA_PROP(state, { index, key, value }) {
            let map = state.trFormData.data[key];

            map[index] = value;
            Vue.set(state.trFormData.data, key, map);
        },

        SLICE_FROM_TR_FORM_DATA(state, { index, key }) {
            state.trFormData[key] = state.trFormData[key].slice(0, index).concat(state.trFormData[key].slice(index + 1));
        },

        UPDATE_LIST_FILTER(state, newFilterObj) {
            $fn.forIn(newFilterObj, (val, key) => {
                Vue.set(state.listFilter, key, val);
            });

            Vue.set(state.listFilter, 'page', newFilterObj.page || 1);
        },

        RESET_STORE(state) {
            state.item = {};
        },

        RESET_FORM_DATA(state) {
            state.formData = {};
        },

        RESET_TR_FORM_DATA(state) {
            state.trFormData = {};
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/equipments/groups/tree`, {
                    params: getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_EQUIPMENT_LIST: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/equipments`);

                commit('SET_EQUIPMENTS_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_EQUIPMENT_PARAMS: async ({ state, rootGetters, getters, commit }, equipmentId) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(
                    `/api/typographies/${rootGetters.currentTypography.id}/equipments/${equipmentId}/tables/params`,
                );

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/equipments/${id}/tables`, {
                    params: $fn.assign(getters.listActiveFilter, {
                        limit: null,
                    }),
                });

                commit('SET_ITEM', {
                    list: resp.list,
                    equipment: resp.equipment,
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        GET_ITEM_TR: async ({ state, rootGetters, getters, commit }, { equipmentId, id }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetItemTR',
                    status: true,
                });

                let resp = await $axios.get(
                    `/api/typographies/${rootGetters.currentTypography.id}/equipments/${equipmentId}/tables/${id}`,
                );

                commit(
                    'equipments/variables/SET_VARIABLE',
                    {
                        type: 'set',
                        variables: resp.single.variables,
                    },
                    { root: true },
                );

                commit('equipments/params/SET_REQUEST_PARAMS', resp.single.options, {
                    root: true,
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetItemTR',
                    status: false,
                });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/equipments`, formData);

                dispatch('GET_LIST');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SAVE_ITEM_GROUP: async ({ state, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/equipments/groups`, formData);

                dispatch('GET_LIST');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SAVE_ITEM_TR: async ({ state, rootGetters, commit, dispatch }, { id, formData, without_fetch, hasClone }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveItemTR',
                    status: true,
                });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/equipments/${id}/tables`,
                    formData,
                );

                if (hasClone) {
                    commit('ADD_CLONE_TR_TO_ITEM', resp.single);
                } else if (!without_fetch) {
                    dispatch('GET_ITEM', id);
                }

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveItemTR',
                    status: false,
                });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, { id, without_fetch, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/equipments/${id}`;

                let resp = await $axios.delete(path, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                if (!without_fetch) dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },

        DELETE_ITEM_TR: async ({ commit, rootGetters, dispatch }, { equipmentId, id, without_fetch, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingDeleteItemTR',
                    status: true,
                });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/equipments/${equipmentId}/tables/${id}`;

                let resp = await $axios.delete(path, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                if (!without_fetch) dispatch('GET_ITEM', equipmentId);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingDeleteItemTR',
                    status: false,
                });
            }
        },
    },
};
