<template>
    <div
        class="selector selector--select"
        :class="{
            'has-focus': hasActive,
            'has-full': hasFull,
            'has-error': errors.has(name),
        }">
        <div v-if="label" class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
            <span>
                <label>{{ label }}</label>
                <template v-if="isRequired">*</template>
            </span>

            <druk-hint
                v-if="hint"
                :tooltip="{ text: hint.text || hint, from: 'top', maxWidth: hint.maxWidth, isNotCollapse: hint.isNotCollapse }"
                :icon="{ name: 'circle-info', size: 'xs', color: hasActive ? 'primary' : 'outline' }" />
        </div>

        <div class="selector__main">
            <multiselect
                v-model="selectedValues"
                class="selector__area"
                ref="multiselect"
                group-label="title"
                group-values="libs"
                :options="options"
                :label="option_label"
                tagPlaceholder=""
                placeholder=""
                selectLabel=""
                deselectLabel=""
                selectedLabel="Selected"
                @open="hasActive = true"
                @close="hasActive = false"
                :hideSelected="notEmpty"
                :searchable="!notEmpty"
                :track-by="option_id || 'id'">
                <template slot="option" slot-scope="props">
                    <span v-if="props.option.$groupLabel" class="selector__group-label">{{ props.option.$groupLabel }}</span>
                    <span v-else class="selector__option-label">{{ props.option[option_label] }}</span>
                </template>
            </multiselect>

            <div
                class="selector__select"
                v-if="!hasFull || !isFilterType"
                :class="{ 'has-active': hasFull || hasActive }"
                @click="onToggle">
                <font-awesome-icon icon="fa-regular fa-angle-down" />
            </div>

            <div class="selector__clear" v-if="isFilterType && hasFull" @click="onClear">
                <font-awesome-icon icon="fa-regular fa-xmark" />
            </div>
        </div>

        <div v-if="errors.has(name)" class="selector__error">
            <span :class="{ [`druk-l-surface-${surface}`]: surface }">{{ errorText || errors.first(name) }}</span>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'form-select-group',
        components: {
            Multiselect,
        },
        inject: ['$validator'],
        props: {
            value: {
                required: true,
            },
            options: {
                type: Array,
                required: true,
            },
            hasLoadOptions: {
                default: true,
                type: Boolean,
            },
            name: {
                type: String,
                required: true,
            },
            option_label: String,
            option_id: String,
            type: String,
            label: String,
            rules: String,
            errorText: String,
            hint: [String, Object],
            notEmpty: Boolean,
            isFilterType: Boolean,

            surface: {
                type: String,
                default: 'tint-pale',
            },
        },
        data() {
            return {
                hasActive: false,
            };
        },

        computed: {
            selectedValues: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                },
            },

            hasFull() {
                return !!this.value;
            },

            isRequired() {
                if (this.rules && this.rules.indexOf('required') != -1) return true;
                return false;
            },
        },

        methods: {
            onClear() {
                this.selectedValues = null;
            },

            onToggle() {
                this.$refs.multiselect.activate();
            },
        },
    };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss"></style>
