<template>
    <div class="ranges">
        <div class="ranges__wrapper" :id="WRAPPER_ID">
            <div class="ranges__main" v-if="!hasPreviewMode">
                <div class="ranges__slider">
                    <transition name="fade" mode="out-in">
                        <vue-slider
                            v-if="hasLoadedRanges"
                            v-model="rangesData"
                            :class="{ 'has-inaccessible': hasBlockedSlider }"
                            :min="min"
                            :max="max"
                            :process="process"
                            :dotOptions="dotOptions"
                            :dotSize="16"
                            :tooltip="'always'"
                            :tooltipPlacement="'top'"
                            :contained="true"
                            :clickable="false"
                            :enableCross="false">
                            <template v-slot:tooltip="{ value, index }">
                                <div
                                    class="ranges__tooltip"
                                    :class="{
                                        'ranges__tooltip--bottom': index % 2,
                                        'ranges__tooltip--top': !(index % 2),
                                        'has-alt': hasAltStyles,
                                    }">
                                    {{ value }}
                                </div>
                            </template>

                            <template v-slot:dot="{ index, value }">
                                <calc-range-save
                                    :index="index + 1"
                                    :surface="surface"
                                    :isEditMode="true"
                                    :hasContextMode="true"
                                    @onClose="resetBlock"
                                    @onUpdate="onStartRangesUpdate">
                                    <tool-tip :text="value" :from="'top'">
                                        <div class="ranges__dot" @mousedown="onFocus($event, index)">
                                            <span class="ranges__mark"></span>
                                        </div>
                                    </tool-tip>
                                </calc-range-save>
                            </template>
                        </vue-slider>
                    </transition>

                    <div class="ranges__line">
                        <vue-slider
                            v-model="lineValue"
                            :data="lineData"
                            :processStyle="lineStyle"
                            :dotOptions="lineDotOptions"
                            :dotSize="16"
                            :tooltip="'none'"
                            :contained="true"
                            :clickable="false">
                            <template v-slot:dot="{ index }">
                                <calc-range-save
                                    :index="index"
                                    :surface="surface"
                                    :isEditMode="true"
                                    :hasContextMode="true"
                                    :hasInaccessible="index > 0"
                                    @onClose="resetBlock"
                                    @onUpdate="onStartRangesUpdate">
                                    <div class="ranges__dot" @mousedown="onFocus($event, index)">
                                        <span class="ranges__mark" v-if="index === 0"></span>
                                    </div>
                                </calc-range-save>
                            </template>
                        </vue-slider>
                    </div>
                </div>

                <calc-range-save
                    :index="-1"
                    :lockedValues="rangesData"
                    :side="'has-from-right'"
                    :surface="surface"
                    @onUpdate="onStartRangesUpdate">
                    <druk-icon-button :type="'outlined'" :icon="'plus'" @click="onAddRange()" />
                </calc-range-save>
            </div>

            <druk-support class="ranges__support" v-if="!hasPreviewMode">{{ $t('ranges.prompt') }}</druk-support>

            <div class="druk-l-card-m" :class="{ [`druk-l-surface-${surfaceVariant}`]: surfaceVariant }">
                <calc-ranges-chart :chartTitle="$t(`${translatesBase}.range.title`)" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import CalcRangeSave from './ranges/CalcRangeSave';
    import CalcRangesChart from './ranges/CalcRangesChart';

    export default {
        name: 'calc-modes-ranges',

        components: {
            CalcRangeSave,
            CalcRangesChart,
        },

        props: {
            translatesBase: {
                type: String,
                default: 'equep',
            },

            surface: {
                type: String,
                default: 'tint-pale',
            },

            surfaceVariant: {
                type: String,
                default: 'tint-brighter',
            },

            hasAltStyles: Boolean,
            hasPreviewMode: Boolean,
        },

        data() {
            return {
                WRAPPER_ID: 'wrapper',

                RIGTH_MOUSE_BUTTON_CODE: 2,

                rangesValues: [],

                min: null,
                max: null,

                lineValue: [1, 2],

                lineData: [
                    {
                        index: 0,
                        label: '1',
                        value: 1,
                    },
                    {
                        index: 1,
                        label: '...',
                        value: 2,
                    },
                ],

                blockedIndex: null,
                rangesTimer: null,

                hasLoadedRanges: false,
                hasBlockedSlider: false,
            };
        },

        created() {
            this.buildData();
        },

        computed: {
            ...mapGetters({
                currentTypography: 'currentTypography',
            }),
            ...mapState({
                ranges: (state) => state.calcModes.ranges.list,
                rangesCast: (state) => state.calcModes.ranges.listCast,
            }),

            rangesData: {
                get() {
                    return [...this.ranges].map((item) => item.count).slice(1) || [];
                },
                set(value) {
                    this.rangesValues = value;
                },
            },

            process() {
                return (position) =>
                    this.rangesData.map((item, index) => [
                        position[index],
                        position[index + 1] ? position[index + 1] : position[index],
                        { backgroundColor: this.$material['secondary-container'] },
                    ]);
            },

            dotOptions() {
                return this.rangesData.map((item, index, list) => ({
                    disabled: index === this.blockedIndex,
                    min: list[index - 1] ? +list[index - 1] + 1 : 2,
                    max: list[index + 1] ? +list[index + 1] - 1 : +item,
                    style: {
                        border: `solid 2px ${this.$material.primary}`,
                        backgroundColor: `${this.$material['on-primary']}`,
                    },
                }));
            },

            lineStyle() {
                return { backgroundColor: this.$material['secondary-container'] };
            },

            lineDotOptions() {
                return this.lineData.map((item, index, list) => ({
                    disabled: true,
                    style: {
                        border: `solid 2px ${this.$material.primary}`,
                        backgroundColor: `${this.$material['on-primary']}`,
                    },
                }));
            },
        },

        methods: {
            ...mapMutations({
                SET_RANGES: 'calcModes/ranges/SET_LIST',
                SET_RANGE_DATA: 'calcModes/ranges/SET_ITEM_DATA',
                ADD_RANGE: 'calcModes/ranges/ADD_ITEM',
                UPDATE_RANGES: 'calcModes/ranges/UPDATE_LIST',
                UPDATE_RANGES_CAST: 'calcModes/ranges/UPDATE_LIST_CAST',
            }),

            buildData() {
                this.onCheckRanges();
                this.setLimits();
            },

            onCheckRanges() {
                if (!this.rangesCast.length) {
                    this.onAddRange({ index: 0, count: 1, price: 1 });
                    this.updateRanges();
                }

                this.$emit('ranges-mode');
            },

            setLimits() {
                return new Promise((resolve) => {
                    this.rangesTimer = setTimeout(() => {
                        this.min = 2;
                        this.max = +[...this.rangesCast].pop()?.count || 100;

                        this.onUnblockSlider();

                        this.hasLoadedRanges = true;

                        resolve();
                    }, 100);
                });
            },

            onFocus(e, index) {
                e?.button === this.RIGTH_MOUSE_BUTTON_CODE
                    ? this.onCheckContext(e, index)
                    : document.addEventListener('mouseup', (e) => this.onSetValue(index + 1), { once: true });
            },

            onCheckContext(e, index) {
                if (!!(index !== 'undefinded' && e?.button === this.RIGTH_MOUSE_BUTTON_CODE)) this.blockedIndex = index;
            },

            onSetValue(index) {
                let value =
                    this.rangesValues[index - 1] || Array.isArray(this.rangesValues)
                        ? [...this.rangesValues][index - 1]
                        : this.rangesValues || this.rangesData[index - 1] || [...this.rangesData].shift();

                this.SET_RANGE_DATA({ index: index, key: 'count', value });

                this.onStartRangesUpdate();
            },

            onStartRangesUpdate() {
                let lastRange = [...this.rangesCast].pop();

                this.onBlockSlider();

                if (lastRange.count !== this.max) {
                    lastRange.count > this.max ? this.upgradeRangesUpdate() : this.downgradeRangesUpdate();
                    return;
                }

                this.updateRanges();
            },

            updateRanges() {
                this.UPDATE_RANGES();
                this.UPDATE_RANGES_CAST();

                this.rangesValues = this.rangesData;

                this.onUnblockSlider();
            },

            async upgradeRangesUpdate() {
                this.hasLoadedRanges = false;

                await this.setLimits();
                this.updateRanges();
            },

            downgradeRangesUpdate() {
                this.hasLoadedRanges = false;

                this.updateRanges();
                this.setLimits();
            },

            onBlockSlider() {
                clearTimeout(this.rangesTimer);
                this.hasBlockedSlider = true;
            },

            onUnblockSlider() {
                this.hasBlockedSlider = false;
            },

            resetBlock(index) {
                if (index === this.blockedIndex) this.blockedIndex = null;
            },

            onAddRange(item) {
                this.ADD_RANGE(item);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .ranges {
        &__main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: -4px -4px 0 0;
        }
        &__slider {
            position: relative;
            margin-right: 56px;
            width: 100%;
            z-index: 11;
            &::v-deep {
                .vue-slider-rail {
                    background-color: initial;
                }
                .vue-slider-dot {
                    width: 15px !important;
                    height: 15px !important;
                }
                .vue-slider-dot-handle {
                    border: solid 2px var(--druk-primary);
                    background-color: var(--druk-on-primary);
                }
                .vue-slider-dot-handle-disabled {
                    cursor: initial;
                }
                .vue-slider-dot-tooltip {
                    top: 0px;
                    bottom: 0px;
                }
                .vue-slider-mark {
                    &:first-child {
                        .vue-slider-mark-label {
                            top: 10px;
                        }
                    }
                    &:last-child {
                        .vue-slider-mark-label {
                            top: 7px;
                        }
                    }
                }
                .vue-slider-mark-label {
                    bottom: 0px;
                    margin-top: 0px;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    font-weight: 400;
                }
                .vue-slider-process {
                    border-radius: 0px;
                }
            }
        }
        &__line {
            position: absolute;
            top: 0px;
            left: 0px;
            width: calc(100% + 40px);
            margin: 0px -40px 0px 0px;
            z-index: 0;
            &::v-deep .vue-slider-dot-handle::before {
                content: initial;
            }
        }
        &__dot {
            cursor: pointer;
            content: '';
            display: flex;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            border: solid 2px var(--druk-primary);
            background-color: var(--druk-on-primary);
        }
        &__mark {
            content: '';
            width: 3px;
            height: 3px;
            border-radius: 50%;
            border: solid 2px var(--druk-primary);
            background-color: var(--druk-primary);
            pointer-events: none;
            margin: auto;
        }
        &__tooltip {
            position: absolute;
            left: 50%;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.5px;
            font-weight: 500;
            transform: translate(-50%, 0%);
            &--top {
                top: -50%;
            }
            &--bottom {
                top: 200%;
            }
        }
        &__support {
            margin: 4px 0 20px;
        }
    }
</style>
