var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"ss",staticClass:"druk-l-ss",class:{
        'druk-has-elusive-thief': _vm.hasElusiveThief,
        [`druk-l-surface-${_vm.surface}`]: !!_vm.surface,
        'druk-is-inner': _vm.isInnerSection,
    }},[_c('div',{ref:"wrapper",staticClass:"druk-l-ss__wrapper",class:{ 'druk-has-loader': _vm.hasLoading }},[_c('div',{staticClass:"druk-l-ss__header",class:{
                [`druk-l-surface-${_vm.surface}`]: !!_vm.surface,
                [`druk-l-surface-${_vm.scrollSurface} druk-has-scrolled-wrapper`]: _vm.hasScrolledWrapper,
            }},[_vm._t("druk-ss-header"),_c('druk-icon-button',{attrs:{"type":'standard',"icon":'xmark'},on:{"click":_vm.onClose}})],2),_c('div',{staticClass:"druk-l-ss__container"},[_vm._t("druk-ss-body")],2),_c('div',{staticClass:"druk-l-ss__footer"},[_vm._t("druk-ss-footer")],2)]),_c('div',{staticClass:"druk-l-ss__loader druk-u-text-body-m druk-l-shape-m druk-l-elevation-2",class:{ 'druk-is-shown': _vm.hasLoading }},[_c('div',{staticClass:"druk-l-ss__loader-base"},[_c('druk-loader',{attrs:{"color":'on-surface',"size":'s',"isIcon":true}})],1),_c('span',[_vm._v(_vm._s(_vm.$t('common.data_loading')))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }