<template>
    <component
        :is="!!path ? 'router-link' : 'button'"
        :to="path"
        class="druk-c-btn"
        :class="{ 'druk-is-full': isFull, 'druk-is-waitnig': isWaiting, 'druk-is-disabled': isDisabled }"
        @click="$emit('click', $event)">
        <div
            class="druk-c-btn__wrapper"
            :class="{
                [`druk-u-${type}`]: !!type,
                [`druk-has-icon`]: !!icon,
                'druk-is-highlighted': isHighlighted,
                'druk-is-inverse': isInverse,
                'druk-is-disabled': isDisabled,
            }">
            <div class="druk-c-btn__content" :class="{ 'druk-is-shown': !isWaiting || isDisabled }">
                <div v-if="icon" class="druk-c-btn__icon" :class="{ 'druk-is-disabled': isDisabled }">
                    <druk-icon :size="'xs'" :color="isDisabled ? DISABLED_COLOR : iconColor" :name="icon" />
                </div>

                <div
                    class="druk-c-btn__label druk-u-text-label-lg"
                    :class="{ [`druk-u-${type}`]: !!type, 'druk-is-slender': isSlender, 'druk-is-disabled': isDisabled }">
                    {{ label }}
                </div>
            </div>

            <div v-if="isWaiting && !isDisabled" class="druk-c-btn__loader">
                <druk-circular-indicator :size="'s'" :color="iconColor" />
            </div>

            <div
                class="druk-c-btn__state"
                :class="{ [`druk-u-${type}`]: !!type, 'druk-is-highlighted': isHighlighted, 'druk-is-inverse': isInverse }"></div>
        </div>
    </component>
</template>

<script>
    export default {
        name: 'druk-button',

        props: {
            type: {
                type: String,
                default: 'filled', // @Explanation: Possible values - filled, tonal, outlined, elevated, text
            },

            path: Object,
            icon: String,
            label: [String, Number],

            isFull: Boolean,
            isHighlighted: Boolean,
            isInverse: Boolean,
            isSlender: Boolean,
            isWaiting: Boolean,
            isDisabled: [Boolean, Number],
        },

        data() {
            return {
                DISABLED_COLOR: 'on-surface',
            };
        },

        computed: {
            iconColor() {
                return !this.isInverse ? this.iconColorsMap[this.type] : this.inverseIconColorMap[this.type];
            },

            iconColorsMap() {
                return {
                    filled: this.isHighlighted ? 'tokens-button-filled-highlighted-color' : 'tokens-button-filled-color',
                    tonal: this.isHighlighted ? 'tokens-button-tonal-highlighted-color' : 'on-secondary-container',
                    outlined: this.isHighlighted ? 'error' : 'primary',
                    elevated: this.isHighlighted ? 'error' : 'primary',
                    text: this.isHighlighted ? 'error' : 'primary',
                };
            },

            inverseIconColorMap() {
                return {
                    filled: 'tokens-button-filled-inverse-color',
                    tonal: 'inverse-on-secondary-container',
                    outlined: 'inverse-primary',
                    elevated: 'inverse-primary',
                    text: 'inverse-primary',
                };
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-btn {
        outline: initial;
        border: initial;
        background-color: transparent;
        &.druk-is-full {
            width: 100%;
        }
        &.druk-is-waitnig,
        &.druk-is-disabled {
            cursor: initial;
            pointer-events: none;
            opacity: 1;
        }
        &__wrapper {
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 24px;
            max-height: 40px;
            border-radius: 100px;
            transition-duration: var(--druk-duration-short-4);
            transition-timing-function: var(--druk-easing-emphasized-accelerate);
            &:hover,
            &:active {
                transition-duration: var(--druk-duration-medium-2);
                transition-timing-function: var(--druk-easing-emphasized-decelerate);
            }
            &.druk-u-filled {
                color: var(--druk-tokens-button-filled-color);
                background-color: var(--druk-tokens-button-filled-background);
                transition-property: box-shadow;
            }
            &.druk-u-filled:hover {
                box-shadow: var(--druk-elevation-1);
            }
            &.druk-u-filled:active {
                box-shadow: initial;
            }
            &.druk-u-filled.druk-is-highlighted {
                color: var(--druk-tokens-button-filled-highlighted-color);
                background-color: var(--druk-tokens-button-filled-highlighted-background);
            }
            &.druk-u-filled.druk-is-inverse {
                color: var(--druk-tokens-button-filled-inverse-color);
                background-color: var(--druk-tokens-button-filled-inverse-background);
            }
            &.druk-u-tonal {
                color: var(--druk-on-secondary-container);
                background-color: var(--druk-secondary-container);
                transition-property: box-shadow;
            }
            &.druk-u-tonal:hover {
                box-shadow: var(--druk-elevation-1);
            }
            &.druk-u-tonal:active {
                box-shadow: initial;
            }
            &.druk-u-tonal.druk-is-highlighted {
                color: var(--druk-tokens-button-tonal-highlighted-color);
                background-color: var(--druk-tokens-button-tonal-highlighted-background);
            }
            &.druk-u-tonal.druk-is-inverse {
                color: var(--druk-inverse-on-secondary-container);
                background-color: var(--druk-inverse-secondary-container);
            }
            &.druk-u-outlined {
                border: 1px solid var(--druk-outline);
                color: var(--druk-primary);
                transition-property: border;
            }
            &.druk-u-outlined:active {
                border: 1px solid var(--druk-primary);
            }
            &.druk-u-outlined.druk-is-highlighted {
                color: var(--druk-error);
            }
            &.druk-u-outlined.druk-is-highlighted:active {
                border: 1px solid var(--druk-error);
            }
            &.druk-u-outlined.druk-is-inverse {
                border: 1px solid var(--druk-inverse-outline);
                color: var(--druk-inverse-primary);
            }
            &.druk-u-outlined.druk-is-inverse:active {
                border: 1px solid var(--druk-inverse-primary);
            }
            &.druk-u-elevated {
                color: var(--druk-primary);
                background-color: var(--druk-tokens-button-elevated-background);
                box-shadow: var(--druk-elevation-1);
                transition-property: box-shadow;
            }
            &.druk-u-elevated:hover {
                box-shadow: var(--druk-elevation-2);
            }
            &.druk-u-elevated:active {
                box-shadow: var(--druk-elevation-1);
            }
            &.druk-u-elevated.druk-is-highlighted {
                color: var(--druk-error);
            }
            &.druk-u-elevated.druk-is-inverse {
                color: var(--druk-inverse-primary);
                background-color: var(--druk-tokens-button-elevated-inverse-background);
            }
            &.druk-u-text {
                padding: 10px 12px;
                color: var(--druk-primary);
            }
            &.druk-u-text.druk-has-icon {
                padding: 10px 16px 10px 12px;
            }
            &.druk-u-text.druk-is-highlighted {
                color: var(--druk-error);
            }
            &.druk-u-text.druk-is-inverse {
                color: var(--druk-inverse-primary);
            }
            &.druk-has-icon {
                padding: 10px 24px 10px 16px;
            }
            &.druk-is-disabled {
                cursor: initial;
                pointer-events: none;
                box-shadow: initial;
                opacity: 1;
            }
            &.druk-is-disabled.druk-u-filled {
                background-color: var(--druk-state-layers-on-surface-0-12);
            }
            &.druk-is-disabled.druk-u-tonal {
                background-color: var(--druk-state-layers-on-surface-0-12);
            }
            &.druk-is-disabled.druk-u-outlined {
                border: 1px solid var(--druk-state-layers-on-surface-0-12);
            }
            &.druk-is-disabled.druk-u-elevated {
                background-color: var(--druk-state-layers-on-surface-0-12);
            }
        }
        &__content {
            display: flex;
            align-items: center;
            opacity: 0;
            &.druk-is-shown {
                opacity: 1;
            }
        }
        &__icon {
            pointer-events: none;
            position: relative;
            margin-right: 8px;
            z-index: 1;
        }
        &__label {
            pointer-events: none;
            position: relative;
            z-index: 1;
            &.druk-is-slender {
                white-space: nowrap;
            }
            &.druk-is-disabled {
                color: var(--druk-on-surface);
            }
        }
        &__loader {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &__state {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 100px;
            background-color: transparent;
            transition-property: background-color;
            transition-duration: var(--druk-duration-short-4);
            transition-timing-function: var(--druk-easing-emphasized-accelerate);
            &:hover,
            &:active {
                transition-duration: var(--druk-duration-medium-2);
                transition-timing-function: var(--druk-easing-emphasized-decelerate);
            }
            &.druk-u-filled:hover {
                background-color: var(--druk-tokens-icon-button-filled-hover);
            }
            &.druk-u-filled:active {
                background-color: var(--druk-tokens-icon-button-filled-pressed);
            }
            &.druk-u-filled.druk-is-highlighted:hover {
                background-color: var(--druk-tokens-button-filled-highlighted-hover);
            }
            &.druk-u-filled.druk-is-highlighted:active {
                background-color: var(--druk-tokens-button-filled-highlighted-pressed);
            }
            &.druk-u-filled.druk-is-inverse:hover {
                background-color: var(--druk-tokens-button-filled-inverse-hover);
            }
            &.druk-u-filled.druk-is-inverse:active {
                background-color: var(--druk-tokens-button-filled-inverse-pressed);
            }
            &.druk-u-tonal:hover {
                background-color: var(--druk-state-layers-on-secondary-container-0-08);
            }
            &.druk-u-tonal:active {
                background-color: var(--druk-state-layers-on-secondary-container-0-12);
            }
            &.druk-u-tonal.druk-is-highlighted:hover {
                background-color: var(--druk-tokens-button-tonal-highlighted-hover);
            }
            &.druk-u-tonal.druk-is-highlighted:active {
                background-color: var(--druk-tokens-button-tonal-highlighted-pressed);
            }
            &.druk-u-tonal.druk-is-inverse:hover {
                background-color: var(--druk-state-layers-inverse-on-secondary-container-0-08);
            }
            &.druk-u-tonal.druk-is-inverse:active {
                background-color: var(--druk-state-layers-inverse-on-secondary-container-0-12);
            }
            &.druk-u-outlined:hover {
                background-color: var(--druk-state-layers-primary-0-08);
            }
            &.druk-u-outlined:active {
                background-color: var(--druk-state-layers-primary-0-12);
            }
            &.druk-u-outlined.druk-is-highlighted:hover {
                background-color: var(--druk-state-layers-error-0-08);
            }
            &.druk-u-outlined.druk-is-highlighted:active {
                background-color: var(--druk-state-layers-error-0-12);
            }
            &.druk-u-outlined.druk-is-inverse:hover {
                background-color: var(--druk-state-layers-inverse-primary-0-08);
            }
            &.druk-u-outlined.druk-is-inverse:active {
                background-color: var(--druk-state-layers-inverse-primary-0-12);
            }
            &.druk-u-elevated:hover {
                background-color: var(--druk-state-layers-primary-0-08);
            }
            &.druk-u-elevated:active {
                background-color: var(--druk-state-layers-primary-0-12);
            }
            &.druk-u-elevated.druk-is-highlighted:hover {
                background-color: var(--druk-state-layers-error-0-08);
            }
            &.druk-u-elevated.druk-is-highlighted:active {
                background-color: var(--druk-state-layers-error-0-12);
            }
            &.druk-u-elevated.druk-is-inverse:hover {
                background-color: var(--druk-state-layers-inverse-primary-0-08);
            }
            &.druk-u-elevated.druk-is-inverse:active {
                background-color: var(--druk-state-layers-inverse-primary-0-12);
            }
            &.druk-u-text:hover {
                background-color: var(--druk-state-layers-primary-0-08);
            }
            &.druk-u-text:active {
                background-color: var(--druk-state-layers-primary-0-12);
            }
            &.druk-u-text.druk-is-highlighted:hover {
                background-color: var(--druk-state-layers-error-0-08);
            }
            &.druk-u-text.druk-is-highlighted:active {
                background-color: var(--druk-state-layers-error-0-12);
            }
            &.druk-u-text.druk-is-inverse:hover {
                background-color: var(--druk-state-layers-inverse-primary-0-08);
            }
            &.druk-u-text.druk-is-inverse:active {
                background-color: var(--druk-state-layers-inverse-primary-0-12);
            }
        }
    }
</style>
