<template>
    <div class="nav-bar">
        <div class="nav-bar__wrapper">
            <div
                v-for="(link, index) in navigation"
                v-show="!link.isHidden"
                :key="index"
                class="nav-bar__link"
                :class="{ 'is-active': link.isActive, 'is-blocked': link.isBlocked }"
                @click="$emit('onChange', link.value)">
                <div class="nav-bar__header">
                    <div class="nav-bar__hover"></div>

                    <druk-icon
                        :name="link.icon"
                        :color="link.isActive ? 'on-secondary-container' : 'on-surface-variant'"
                        :variant="link.isActive ? 'solid' : 'regular'"
                        :size="'m'" />
                </div>

                <div class="nav-bar__title">{{ link.title }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'navigation-bar',

        props: {
            navigation: Array,
        },
    };
</script>

<style lang="scss" scoped>
    .nav-bar {
        margin-bottom: 24px;
        padding-bottom: 8px;
        border-bottom: 1px solid var(--druk-outline-variant);

        &__wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: space-evenly;
        }
        &__link {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0px 2px 4px;
            width: 80px;
            color: var(--druk-on-surface-variant);
            &:hover {
                .nav-bar__hover {
                    width: 100%;
                    height: 100%;
                    &::before {
                        background-color: var(--druk-state-layers-on-surface-0-08);
                    }
                }
            }
            &:active,
            &:focus {
                .nav-bar__hover::before {
                    background-color: var(--druk-state-layers-on-surface-0-12);
                }
            }
            &.is-active {
                color: var(--druk-on-surface);
                &:hover {
                    .nav-bar__hover::before {
                        background-color: var(--druk-state-layers-on-secondary-container-0-08);
                    }
                }
                &:active,
                &:focus {
                    .nav-bar__hover::before {
                        background-color: var(--druk-state-layers-on-secondary-container-0-12);
                    }
                }
                .nav-bar__hover {
                    width: 100%;
                    height: 100%;
                    background-color: var(--druk-secondary-container);
                }
                .nav-bar__title {
                    font-weight: 600;
                }
            }
            &.is-blocked {
                cursor: initial;
                pointer-events: none;
                opacity: 0.38;
            }
            @media (max-width: $druk-breakpoints-sm) {
                width: 60px;
            }
        }
        &__header {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 4px;
            width: 56px;
            height: 32px;
        }
        &__hover {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0px;
            height: 0px;
            border-radius: 16px;
            transform: translate(-50%, -50%);
            transition:
                width 0.2s ease-in-out,
                height 0.2s ease-in-out;
            z-index: 0;
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 16px;
                background-color: transparent;
                transition: background-color 0.2s ease-in-out;
            }
        }
        &__title {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.5px;
            font-weight: 500;
            text-align: center;
            transition: color 0.2s ease-in;
            @media (max-width: $druk-breakpoints-sm) {
                word-break: break-all;
            }
        }
    }
</style>
