<template>
    <div
        class="selector selector--select selector--select-of-add"
        :class="{
            'has-focus': hasOpen,
            'has-full': hasFull,
            'has-error': errors.has(name),
            'has-clear-icon': isFilterType,
        }">
        <div v-if="label" class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
            <span>
                <label>{{ label }}</label>
                <template v-if="isRequired">*</template>
            </span>

            <druk-hint
                v-if="hint"
                :tooltip="{ text: hint.text || hint, from: 'top', maxWidth: hint.maxWidth, isNotCollapse: hint.isNotCollapse }"
                :icon="{ name: 'circle-info', size: 'xs', color: hasActive ? 'primary' : 'outline' }" />
        </div>

        <div class="selector__main">
            <multiselect
                class="selector__area"
                ref="multiselect"
                v-model="search"
                :options="options"
                :taggable="true"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :data-vv-name="name"
                :data-vv-as="label"
                v-validate="rules || ''"
                :tag-placeholder="$t('select.paceholder.create')"
                :selectLabel="''"
                :deselectLabel="''"
                :placeholder="placeholder || labelText"
                :multiple="multiple"
                :hideSelected="notEmpty"
                @tag="addTag"
                @input="onChange"
                @open="hasOpen = true"
                @close="hasOpen = false"
                :label="option_label"
                :track-by="option_id || 'id'">
            </multiselect>

            <div
                class="selector__select"
                v-if="!hasFull || !isFilterType"
                :class="{ 'has-active': hasFull || hasActive }"
                @click="onToggle">
                <font-awesome-icon icon="fa-regular fa-angle-down" />
            </div>

            <div class="selector__clear" v-if="isFilterType && hasFull" @click="onClear">
                <font-awesome-icon icon="fa-regular fa-xmark" />
            </div>
        </div>

        <div v-if="errors.has(name)" class="selector__error">
            <span :class="{ [`druk-l-surface-${surface}`]: surface }">{{ errorText || errors.first(name) }}</span>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'form-select-or-add',
        components: {
            Multiselect,
        },
        inject: ['$validator'],
        props: {
            value: {
                required: true,
            },
            options: {
                type: Array,
                required: true,
            },
            hasLoadOptions: {
                default: true,
                type: Boolean,
            },
            name: {
                type: String,
                required: true,
            },
            option_label: String,
            option_id: String,
            type: String,
            label: String,
            placeholder: String,
            rules: String,
            errorText: String,
            hint: [String, Object],
            notEmpty: Boolean,
            multiple: Boolean,
            isFilterType: Boolean,

            //
            surface: {
                type: String,
                default: 'tint-pale',
            },
        },
        data() {
            return {
                text: null,
                search: this.value,
                hasActive: false,
                hasOpen: false,
            };
        },

        computed: {
            hasFull() {
                return !!this.search;
            },

            labelText() {
                return !this.isRequired ? this.label : this.label + '*';
            },

            isRequired() {
                if (this.rules && this.rules.indexOf('required') != -1) return true;
                return false;
            },
        },

        methods: {
            addTag(val) {
                this.search = {
                    [this.option_label]: val,
                };
                this.$emit('input', this.search);
            },

            onToggle() {
                this.$refs.multiselect.activate();
            },

            onChange(val) {
                this.$emit('input', this.search);
            },

            onClear() {
                this.search = null;
            },
        },
    };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss"></style>
