<template>
    <div class="selector selector--color-picker">
        <div v-if="label" class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
            <span>
                <label>{{ label }}</label>
                <template v-if="isRequired">*</template>
            </span>

            <druk-hint
                v-if="hint"
                :tooltip="{ text: hint.text || hint, from: 'top', maxWidth: hint.maxWidth, isNotCollapse: hint.isNotCollapse }"
                :icon="{ name: 'circle-info', size: 'xs', color: hasActive ? 'primary' : 'outline' }" />
        </div>

        <div class="selector__main">
            <color-picker v-model="colors" :preset-colors="presetColors" @input="onUpdate" />
        </div>

        <div v-if="errors.has(name)" class="selector__error">
            <span :class="{ [`druk-l-surface-${surface}`]: surface }">{{ errorText || errors.first(name) }}</span>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

    import { Sketch } from 'vue-color';

    export default {
        name: 'form-color-picker',

        props: {
            value: {
                required: true,
            },
            name: String,
            label: String,
            rules: [String, Object],
            hint: [String, Object],
            errorText: String,
            surface: {
                type: String,
                default: 'tint-pale',
            },
        },

        components: {
            'color-picker': Sketch,
        },

        data() {
            return {
                colors: {
                    hex: this.value,
                },
            };
        },

        computed: {
            ...mapState({
                SYSTEM_COLORS_MAP: (state) => state.constant.SYSTEM_COLORS_MAP,
            }),

            colorsMap() {
                return this.SYSTEM_COLORS_MAP.concat(['primary', 'secondary', 'tertiary']);
            },

            presetColors() {
                return this.colorsMap.map((color) => this.$material[`preset-${color}`]);
            },

            isRequired() {
                if (this.rules && this.rules.indexOf('required') != -1) return true;
                return false;
            },
        },

        methods: {
            onToggle() {
                this.$refs.area.focus();
            },

            onUpdate() {
                this.$emit('input', this.colors.hex);
            },
        },
    };
</script>

<style lang="scss" scoped></style>
