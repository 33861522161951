import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    data() {
        return {
            hasBuildedApp: false,
        };
    },

    computed: {
        ...mapState({
            THEME_LIGHT: (state) => state.THEME_LIGHT,

            systemPickedLanguages: (state) => state.languages.systemPickedList,
        }),
        ...mapGetters({
            languages: 'languages/editableSystemLanguages',
            clientPickedLanguages: 'typographies/clientPickedLanguages',

            hasERPAccess: 'hasERPAccess',
            hasTypographyAccess: 'hasTypographyAccess',
        }),
    },

    methods: {
        ...mapActions({
            GET_TYPOGRAPHY: 'typographies/GET_ITEM',
            GET_TYPOGRAPHY_MODULES: 'typographies/modules/GET_TYPOGRAPHY_LIST',
            GET_LANGUAGES: 'languages/GET_LIST',
            GET_SYSTEM_LANGUAGES: 'languages/GET_SYSTEM_LIST',
        }),

        ...mapMutations({
            SET_HISTORY_ADDRESS: 'SET_HISTORY_ADDRESS',
            SET_LAYOUT: 'SET_LAYOUT',
            SET_SELECTED_CURRENCY: 'SET_SELECTED_CURRENCY',
            SET_SELECTED_CLIENT_LANG: 'SET_SELECTED_CLIENT_LANG',
            SET_PROJECT_THEME: 'SET_PROJECT_THEME',
        }),

        buildTypographyApp(id) {
            this.SET_PROJECT_THEME(JSON.parse(localStorage.getItem('_druk_engine_theme')) || this.THEME_LIGHT);

            this.GET_TYPOGRAPHY_MODULES({ typographyId: id });

            this.GET_TYPOGRAPHY(id).then(async (resp) => {
                this.SET_SELECTED_CURRENCY(resp.single.currency.code);

                await Promise.all([this.GET_SYSTEM_LANGUAGES(), this.GET_LANGUAGES()]),
                    await this.setHeaders({
                        lang:
                            this.systemPickedLanguages.find((item) => item.code === this.$i18n.locale)?.code ||
                            [...this.systemPickedLanguages].shift().code,
                        currency: resp.single.currency.code,
                    });

                this.setClientLanguage();
                this.setBusEvents();

                this.onBuildEnding();
            });
        },

        async buildErpApp() {
            this.SET_PROJECT_THEME(JSON.parse(localStorage.getItem('_druk_engine_theme')) || this.THEME_LIGHT);

            await this.GET_LANGUAGES();

            await this.setHeaders({
                lang: $fn.find(this.languages, ['code', this.$i18n.locale])?.code || [...this.languages].shift().code,
                currency: localStorage.getItem('_currency_druk_crm') || this.$store.state.DEFAULT_CURRENCY,
            });

            this.setClientLanguage();
            this.setBusEvents();

            this.onBuildEnding();
        },

        setHeaders(data) {
            return new Promise((resolve, reject) => {
                this.$i18n.setLang(data.lang);
                this.setValidateLocalization(data.lang);

                this.$axios.defaults.headers.common['Accept-Language'] =
                    data.lang || localStorage.getItem('_language_druk') || this.$store.state.DEFAULT_LANG;
                this.$axios.defaults.headers.common['Currency-Code'] =
                    data.currency || localStorage.getItem('_currency_druk_crm') || this.$store.state.DEFAULT_CURRENCY;
                this.$axios.defaults.headers.common['Access-Control-Allow-Origin'] = this.$store.state.ALLOW_ORIGIN;
                this.$axios.defaults.headers.common['Access-Control-Allow-Credentials'] = this.$store.state.ALLOW_CREDENTIAL;
                this.$axios.defaults.headers.common['Access-Control-Allow-Methods'] = this.$store.state.ALLOW_METHODS;

                resolve();
            });
        },

        setClientLanguage() {
            this.SET_SELECTED_CLIENT_LANG(
                this.clientPickedLanguages.find((item) => item.code === this.$i18n.locale)?.code ||
                    [...this.clientPickedLanguages].shift().code,
            );
        },

        setBusEvents() {
            this.$bus.$on('permission', () => {
                if (this.$route.name !== 'dashboard') {
                    this.SET_HISTORY_ADDRESS(window.location.href);

                    this.$noty.error(this.permissionError[this.$i18n.locale]);
                    this.$router.push({ name: 'dashboard' });

                    window.location.reload();
                }
            });
        },

        onBuildEnding() {
            this.hasBuildedApp = true;

            if (this.$store.state.KEY_LAYOUT_LOGIN === this.$store.state.layout) {
                this.setLayout('iframe-layout');
                this.$router.push({ name: 'dashboard' });
            }
        },

        setLayout(newLayout) {
            if (this.layout === newLayout) return false;

            this.$store.commit('SET_LAYOUT', newLayout);
        },
    },
};
