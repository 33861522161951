<template>
    <div
        class="selector selector--area"
        :class="{
            'has-focus': hasActive,
            'has-full': hasFull,
            'has-error': errors.has(name),
            'has-alt': !hasAutoResize,
        }">
        <div class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
            <span>
                <label>{{ label }}</label>
                <template v-if="isRequired">*</template>
            </span>

            <druk-hint
                v-if="hint"
                :tooltip="{ text: hint.text || hint, from: 'top', maxWidth: hint.maxWidth, isNotCollapse: hint.isNotCollapse }"
                :icon="{ name: 'circle-info', size: 'xs', color: hasActive ? 'primary' : 'outline' }" />
        </div>

        <div class="selector__main" :class="{ [`druk-l-surface-${surface}`]: surface }">
            <textarea
                :ref="slug"
                class="selector__area"
                :style="{ height: `${height} !important` }"
                :value="value && typeof value === 'object' ? value.join(' ') : value"
                :data-vv-name="name"
                :data-vv-as="label"
                v-validate="rules || ''"
                @focus="hasActive = true"
                @blur="hasActive = false"
                @keyup.enter="$emit('enter')"
                @keyup="onCheckHeight"
                @input="$emit('input', $event.target.value)"></textarea>

            <div v-if="placeholder && !hasFull" class="selector__placeholder">
                <span>{{ placeholder }}</span>
            </div>

            <div class="selector__clear" v-if="isFilterType && hasFull" @click="onClear">
                <font-awesome-icon icon="fa-regular fa-xmark" />
            </div>

            <div
                v-if="hasShowLength"
                class="selector__label selector__label--bottom"
                :class="{ [`druk-l-surface-${surface}`]: surface }">
                {{ $t('common.symbols') }}: {{ value ? value.length : '0' }}<template v-if="maxLength">/{{ maxLength }}</template>
            </div>
        </div>

        <div v-if="errors.has(name)" class="selector__error">
            <span :class="{ [`druk-l-surface-${surface}`]: surface }">{{ errorText || errors.first(name) }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'form-textarea',
        inject: ['$validator'],
        props: {
            value: {
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            label: String,
            rules: String,
            height: String,
            errorText: String,
            hint: [String, Object],
            placeholder: String,
            maxLength: [Number, String],
            isFilterType: Boolean,
            hasAutoResize: Boolean,
            hasShowLength: Boolean,

            surface: {
                type: String,
                default: 'tint-pale',
            },
        },
        data() {
            return {
                hasActive: false,
            };
        },

        mounted() {
            this.onCheckHeight();
        },

        computed: {
            slug() {
                return $fn.generateHash();
            },

            hasFull() {
                return !!this.value;
            },

            isRequired() {
                if (this.rules && this.rules.indexOf('required') != -1) return true;
                return false;
            },
        },

        methods: {
            onToggle() {
                this.$refs.area.focus();
            },

            onClear() {
                if (!this.hasFull) return;
                this.$emit('input', null);
            },

            onCheckHeight() {
                if (!this.hasAutoResize) return;

                let textarea = this.$refs[this.slug],
                    hiddenDiv = document.createElement('div'),
                    content = null;

                hiddenDiv.classList.add('selector__area', 'selector--area', 'selector__areaMask');

                textarea.parentNode.appendChild(hiddenDiv);

                content = textarea.value;
                content = content.replace(/\n/g, '<br>');

                hiddenDiv.innerHTML = content + '<br style="line-height: 20px;">';

                textarea.style.height = hiddenDiv.offsetHeight + 'px';

                textarea.parentNode.removeChild(hiddenDiv);

                this.$emit('keyup');
            },
        },
    };
</script>

<style lang="scss" scoped></style>
