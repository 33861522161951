import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        ORDER_STATUS_WAITING: 1,
        ORDER_STATUS_ACCEPTED: 2,
        ORDER_STATUS_CANCELED: 3,
        ORDER_STATUS_COMPLETED: 4,
        ORDER_STATUS_CALCULATION: 5,
        ORDER_STATUS_READY_TO_DELIVERY: 6,
        ORDER_STATUS_IN_PROGRESS: 7,
        ORDER_STATUS_ACCEPTED_BY_MANAGER: 8,
        ORDER_STATUS_SHIPMENT_IS_BLOCKED: 16,

        PRODUCT_STATUS_WAITING: 1,
        PRODUCT_STATUS_CANCELED: 3,
        PRODUCT_STATUS_COMPLETED: 4,
        PRODUCT_STATUS_NEW_PRINT: 7,
        PRODUCT_STATUS_IN_PROGRESS_PRINT: 8,
        PRODUCT_STATUS_NEW_POSTPRESS: 9,
        PRODUCT_STATUS_IN_PROGRESS_POSTPRESS: 10,
        PRODUCT_STATUS_NEW_LOGISTIC: 11,
        PRODUCT_STATUS_IN_PROGRESS_LOGISTIC: 12,
        PRODUCT_STATUS_READY_TO_DELIVERY: 14,
        PRODUCT_STATUS_ACCEPTED_BY_MANAGER: 15,
        PRODUCT_STATUS_SHIPMENT_IS_BLOCKED: 16,

        ACTIVE_ORDER_STATUSES: [
            {
                alias: 'accepted',
                color: 'azul',
            },
            {
                alias: 'waiting',
                color: 'dusk',
            },
            {
                alias: 'accepted_by_manager',
                color: 'rose-red',
            },
            {
                alias: 'in_progress',
                color: 'sunglow',
            },
            {
                alias: 'ready_to_delivery',
                color: 'shamrock-green',
            },
        ],

        ORDER_STATUSES_COLOR_MAP: {
            waiting: 'water-blue',
            accepted: 'azul',
            accepted_by_manager: 'iris',
            in_progress: 'sunglow',
            ready_to_delivery: 'gamboge',
            canceled: 'neutral',
            completed: 'dark-aqua',
            calculation: 'dusk',
        },

        list: [],
        listPagination: {},
        listFilter: {
            page: null,
            limit: 30,
        },

        productList: [],
        productListPagination: {},
        productListFilter: {
            page: null,
            limit: 30,
        },

        // @Explanation: Will be need in the future
        // item: {},

        loadingGetList: false,
        loadingGetProductList: false,
        loadingSaveItemStatus: false,
        loadingSaveProductStatus: false,

        // @Explanation: Will be need in the future
        // loadingGetItem: false,
        // loadingSaveItem: false,
        // loadingDeleteItem: false,
    },

    getters: {
        formattedList(state) {
            let cancelledItem = state.list.find((item) => item.id === state.ORDER_STATUS_CANCELED),
                completedItem = state.list.find((item) => item.id === state.ORDER_STATUS_COMPLETED);

            return state.list
                .filter((item) => item.id !== state.ORDER_STATUS_CANCELED && item.id !== state.ORDER_STATUS_COMPLETED)
                .concat(completedItem, cancelledItem);
        },

        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        productListActiveFilter(state) {
            return Object.keys(state.productListFilter)
                .filter((key) => state.productListFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.productListFilter[key]), resp), {});
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, rootGetters, commit }, filters = {}) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/order-statuses`
                    : `/api/order-statuses`;

                let resp = await $axios.get(path, {
                    params: Object.keys(filters).length ? filters : getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_LIST_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_PRODUCT_LIST: async ({ state, getters, commit }, filters = {}) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetProductList', status: true });

                let path = `/api/order-product-statuses`;

                let resp = await $axios.get(path, {
                    params: Object.keys(filters).length ? filters : getters.productListFilter,
                });

                commit('SET_PRODUCT_LIST', resp.list);
                commit('SET_PRODUCT_LIST_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetProductList', status: false });
            }
        },

        SAVE_ITEM_STATUS: async ({ state, getters, rootGetters, commit, dispatch }, { id, formData, bitrix }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItemStatus', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/orders/${id}/status`;

                let resp = await $axios.post(path, formData);

                dispatch('orders/GET_ITEM', { id, bitrix }, { root: true });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItemStatus', status: false });
            }
        },

        SAVE_PRODUCT_STATUS: async ({ state, getters, rootGetters, commit, dispatch }, { id, product_id, formData, bitrix }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItemStatus', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/orders/${id}/products/${product_id}/status`;

                let resp = await $axios.post(path, formData);

                dispatch('orders/GET_ITEM', { id, bitrix }, { root: true });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItemStatus', status: false });
            }
        },

        // @Explanation: Will be need in the future
        // GET_ITEM: async({state, getters, rootGetters, commit}, id) => {
        // 	try {
        // 		commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true, });

        // 		let path = rootGetters.hasTypographyAccess
        // 				? `/api/typographies/${rootGetters.currentTypography.id}/order-statuses/${id}`
        // 				: `/api/order-statuses/${id}`;

        // 		let resp = await $axios.get(path);

        // 		commit('SET_ITEM', resp.single);

        // 		return resp;
        // 	} catch (e) { throw e; } finally {
        // 		commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false, });
        // 	}
        // },

        // @Explanation: Will be need in the future
        // SAVE_ITEM: async({state, commit, rootGetters, dispatch }, formData) => {
        // 	try {
        // 		commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true, });

        // 		let path = `/api/order-statuses`;

        // 		let resp = await $axios.post(path, formData);

        // 		dispatch('GET_LIST');

        // 		return resp;
        // 	} catch (e) { throw e; } finally {
        // 		commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false, });
        // 	}
        // },

        // @Explanation: Will be need in the future
        // DELETE_ITEM: async({state, commit, rootGetters, dispatch }, id) => {
        // 	try {
        // 		commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true, });

        // 		let path = `/api/order-statuses/${id}`;

        // 		let resp = await $axios.delete(path);

        // 		dispatch('GET_LIST');

        // 		return resp;
        // 	} catch (e) { throw e; } finally {
        // 		commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false, });
        // 	}
        // },
    },

    mutations: {
        SET_LIST(state, list) {
            Vue.set(state, 'list', list);
        },

        SET_LIST_PAGINATION(state, pagination) {
            Vue.set(state, 'listPagination', pagination);
        },

        SET_PRODUCT_LIST(state, list) {
            Vue.set(state, 'productList', list);
        },

        SET_PRODUCT_LIST_PAGINATION(state, pagination) {
            Vue.set(state, 'productListPagination', pagination);
        },

        SET_ITEM(state, item) {
            Vue.set(state, 'item', item);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            Vue.set(state, value_key, status);
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        UPDATE_PRODUCT_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.productListFilter, key, value));
            Vue.set(state.productListFilter, 'page', newFilterlist.page || 1);
        },

        RESET_LIST_FILTER(state) {
            Vue.set(state, 'listFilter', {
                page: null,
                limit: null,
            });
        },

        RESET_PRODUCT_LIST_FILTER(state) {
            Vue.set(state, 'productListFilter', {
                page: null,
                limit: null,
            });
        },
    },
};
