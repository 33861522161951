<template>
    <div class="cFile">
        <div class="cFile__wrapper">
            <div
                class="cFile__body"
                :class="{
                    'has-pagination': hasOneMoreMockups,
                    'has-hover': isControllAvailable,
                    'has-static': !hasMockupAccess || !hasActiveCtrls || isBlocked,
                }"
                @mouseover="onHover(true)"
                @mouseout="onHover(false)">
                <swiper ref="swiper" class="cFile__swiper" :options="swiperOptions">
                    <template v-if="hasMockups">
                        <swiper-slide v-for="(mockup, index) in mockups" :key="index">
                            <font-awesome-icon icon="fa-regular fa-link" v-if="mockup.destination_custom" />

                            <font-awesome-icon
                                icon="fa-regular fa-empty-set"
                                v-else-if="!mockup.preview_original && !mockup.destination_original" />

                            <img
                                v-else
                                :src="mockup.preview_original || mockup.destination_original"
                                @click="$emit('controll')"
                                @error="$fn.fixImageError" />
                        </swiper-slide>

                        <div v-if="hasOneMoreMockups" class="swiper-pagination" slot="pagination"></div>
                    </template>

                    <swiper-slide v-else>
                        <img class="cFile__placeholder" :src="placeholder" @click="$emit('controll')" @error="$fn.fixImageError" />
                    </swiper-slide>
                </swiper>

                <div v-if="hasActiveCtrls && hasMockupAccess" class="cFile__ctrl" :class="{ 'has-pagination': hasOneMoreMockups }">
                    <div class="cFile__icon">
                        <druk-icon-button :type="'elevated'" :icon="'swatchbook'" />
                    </div>

                    <div v-if="label" class="cFile__prompt druk-l-card-xs">
                        <span>{{ label }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'card-file-controll',

        props: {
            mockups: {
                type: Array,
                default: () => {
                    return [];
                },
            },

            placeholder: String,
            icon: String,
            label: String,

            hasActiveCtrls: Boolean,
            hasProductLinks: Boolean,
            hasNoHover: Boolean,

            isBlocked: Boolean,
        },

        data() {
            return {
                hasHovering: false,
            };
        },

        computed: {
            ...mapState({
                PERM_MODULE_ORDER_MOCKUP: (state) => state.permissions.PERM_MODULE_ORDER_MOCKUP,
                PERM_ACTION_VIEW: (state) => state.permissions.PERM_ACTION_VIEW,
            }),

            swiperOptions() {
                return {
                    speed: 300,

                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },

                    slidesPerView: 1,
                    initialSlide: 0,

                    grabCursor: false,
                    perspective: false,
                    centeredSlides: true,
                    allowTouchMove: this.hasOneMoreMockups,
                };
            },

            hasMockups() {
                return this.mockups?.length;
            },

            hasOneMoreMockups() {
                return this.mockups?.length > 1;
            },

            hasMockupAccess() {
                return $fn.hasAccess(this.PERM_MODULE_ORDER_MOCKUP, this.PERM_ACTION_VIEW);
            },

            isControllAvailable() {
                return this.hasHovering && this.hasActiveCtrls && !this.isBlocked;
            },
        },

        methods: {
            onHover(value) {
                this.hasHovering = this.hasMockupAccess && !this.hasNoHover ? value : false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .cFile {
        height: auto;
        &__wrapper {
            height: 100%;
        }
        &__body {
            cursor: pointer;
            position: relative;
            height: 100%;
            padding: var(--druk-gap-l) 0;
            border: 1px solid var(--druk-outline-variant);
            background-color: var(--druk-tint-surface-container-bright);
            border-radius: 12px;
            &::before {
                content: '';
                position: absolute;
                pointer-events: none;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
            &.has-pagination {
                padding: var(--druk-gap-l) 0 var(--druk-gap-xs);
            }
            &.has-hover {
                .cFile__icon {
                    transform: translate(0, var(--druk-n-gap-s));
                }
                .cFile__prompt {
                    opacity: 1;
                }
                &::before {
                    opacity: 1;
                }
                img {
                    filter: brightness(0.32);
                }
            }
            &.has-static {
                cursor: initial;
                pointer-events: none;
            }
        }
        &__ctrl {
            pointer-events: none;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            padding: var(--druk-gap-l);
            width: 100%;
            &.has-invisible {
                display: none;
            }
            &.has-pagination {
                transform: translate(-50%, calc(-50% - var(--druk-gap-l) + var(--druk-gap-xs)));
            }
        }
        &__icon,
        &__prompt {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease-in-out;
        }
        &__icon {
            width: 40px;
            height: 40px;
            transform: translate(0, 20px);
            svg {
                width: 16px;
                height: 16px;
            }
        }
        &__prompt {
            width: auto;
            margin: 4px auto;
            padding: 4px 8px;
            text-align: center;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            font-weight: 400;
            border-radius: 4px;
            color: var(--druk-inverse-on-surface);
            background-color: var(--druk-surface-inverse-surface);
            opacity: 0;
        }
        img {
            display: block;
            width: 100%;
            height: auto;
            transition: filter 0.3s ease-in-out;
        }
    }

    .swiper {
        &-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100% !important;
            height: 100%;
            margin: auto;
            img {
                margin: auto;
            }
        }

        &-pagination {
            position: initial;
        }
    }
</style>
