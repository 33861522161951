<template>
    <div
        class="druk-l-card-m formula__prompt"
        :class="{ [`druk-l-surface-${surface}`]: surface, 'has-active': hasShownPrompt, 'has-alt': hasAltStyles }">
        <div class="druk-l-card__block">
            <druk-support :isBlock="true">{{ $t('calcModes.formula.prompt') }}</druk-support>
        </div>

        <div class="druk-l-card__block">
            <div class="druk-l-info">{{ $t('calcModes.formula.interactions') }}:</div>
        </div>

        <div class="formula__interactions">
            <div class="formula__interaction">
                <div class="formula__variable has-icon">
                    <font-awesome-icon icon="fa-regular fa-arrow-left" />
                </div>

                <druk-support :hasNoIcon="true">— {{ $t('calcModes.formula.interactions_arrow_left') }}</druk-support>
            </div>

            <div class="formula__interaction">
                <div class="formula__variable has-icon">
                    <font-awesome-icon icon="fa-regular fa-arrow-right" />
                </div>
                <druk-support :hasNoIcon="true">— {{ $t('calcModes.formula.interactions_arrow_right') }} </druk-support>
            </div>

            <div class="formula__interaction">
                <div class="formula__variable">
                    {{ isMacOS ? 'Delete' : 'BackSpace' }}
                </div>
                <druk-support :hasNoIcon="true">— {{ $t('calcModes.formula.interactions_backspace') }} </druk-support>
            </div>

            <div class="formula__interaction">
                <template v-if="!isMacOS">
                    <div class="formula__variable has-icon">
                        <font-awesome-icon icon="fa-regular fa-command" />
                    </div>

                    <div class="formula__interaction-addl">
                        <druk-icon :name="'plus'" :size="'xs'" :color="'on-surface-variant'" />
                    </div>
                </template>

                <div class="formula__variable">Delete</div>

                <druk-support :hasNoIcon="true">— {{ $t('calcModes.formula.interactions_delete') }} </druk-support>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'calc-formula-prompt',

        props: {
            surface: {
                type: String,
                default: 'default',
            },

            hasShownPrompt: Boolean,
            hasAltStyles: Boolean,
        },

        computed: {
            isMacOS() {
                return this.$device.isMacOS;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .formula {
        &__prompt {
            max-height: 0px;
            opacity: 0;
            padding: 0 24px;
            transition: all 0.3s ease-in-out;
            &.has-active {
                margin-bottom: 20px;
                max-height: 1200px;
                opacity: 1;
                padding: 24px;
                @media (max-width: $druk-breakpoints-lg) {
                }
            }
        }
        &__interactions {
            display: flex;
            flex-wrap: wrap;
            margin: 0 var(--druk-n-gap-m) var(--druk-n-gap-m);
        }
        &__interaction {
            display: flex;
            align-items: center;
            flex-basis: 50%;
            padding: 0 var(--druk-gap-m);
            margin-bottom: var(--druk-gap-m);
            &-addl {
                display: inline-block;
                margin-right: var(--druk-gap-xs);
            }
            @media (max-width: $druk-breakpoints-md) {
                flex-basis: 100%;
            }
        }
        &__variable {
            cursor: initial;
            font-size: var(--druk-gap-m);
            margin: 0 var(--druk-gap-xs) 0 0;
            &:hover {
                box-shadow: initial;
                transform: initial;
            }
        }
    }
</style>
