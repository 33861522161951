import Vue from 'vue';

const COMMON_FORM_DATA = {
    delivery_type: null,
    delivery_option: null,
    address: null,
};

const NOVA_POSHTA_FORM_DATA = {
    city: null,
    warehouse: null,
};

const DPD_BALTIC_FORM_DATA = {
    warehouse: null,
    city_id: null,
    street_id: null,
    street_no: null,
    flat_no: null,
    postal_code: null,
};

export default {
    namespaced: true,

    state: {
        addressList: [],

        clientLastOrder: {},

        formData: { ...COMMON_FORM_DATA },
        novaPoshtaData: { ...NOVA_POSHTA_FORM_DATA },
        dpdBalticData: { ...DPD_BALTIC_FORM_DATA },

        loadingGetAdressList: false,
        loadingGetClientLastOrder: false,
        loadingSaveClientAddress: false,
        loadingDeleteClientAddress: false,
    },

    getters: {
        formattedAddressList(state, getters, rootState) {
            return state.addressList.map((address) => {
                let isNovaPoshtaCouerierAddress =
                    address.delivery_option.module?.alias === rootState.typographies.modules.NOVA_POSHTA_KEY &&
                    address.delivery_option.delivery_type.id === rootState.delivery.DELIVERY_TYPE_COURIER_ID;

                let isPickupAddress = address.delivery_option.delivery_type.id === rootState.delivery.DELIVERY_TYPE_PICKUP_ID;

                let totalAddress =
                    `${isNovaPoshtaCouerierAddress ? `${address.city_title}, ` : ''}` +
                    `${isPickupAddress ? address.delivery_option.address : address.address}`;

                return {
                    ...address,
                    totalAddress,
                    title: `${address.delivery_option.title}: ${totalAddress}`,
                };
            });
        },
    },

    actions: {
        GET_CLIENT_ADDRESS_LIST: async ({ state, rootState, rootGetters, getters, commit }, clientId) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetAdressList', status: true });

                let resp = await $axios.get(
                    `/api/typographies/${rootGetters.currentTypography.id}/orders/clients/addresses/${clientId}`,
                );

                commit('SET_ADDRESS_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetAdressList', status: false });
            }
        },

        GET_CLIENT_LAST_ORDER: async ({ state, rootState, rootGetters, getters, commit }, clientId) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetClientLastOrder', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/orders`, {
                    params: {
                        client_id: clientId,
                        page: 1,
                        limit: 1,
                        sort: 'new',
                        direction: 'desc',
                    },
                });

                commit('SET_CLIENT_LAST_ORDER', [...resp.list].shift());

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetClientLastOrder', status: false });
            }
        },

        SAVE_CLIENT_ADDRESS: async (
            { state, rootState, rootGetters, getters, commit, dispatch },
            { clientId, formData, without_noty },
        ) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveClientAddress', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/orders/clients/addresses/${clientId}`;

                let resp = await $axios.post(path, formData, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                dispatch('GET_CLIENT_ADDRESS_LIST', clientId);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveClientAddress', status: false });
            }
        },

        DELETE_CLIENT_ADDRESS: async (
            { state, rootState, rootGetters, getters, commit, dispatch },
            { clientId, id, without_fetch },
        ) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteClientAddress', status: true });

                let resp = await $axios.delete(
                    `/api/typographies/${rootGetters.currentTypography.id}/orders/clients/addresses/${clientId}/${id}`,
                );

                if (!without_fetch) dispatch('GET_CLIENT_ADDRESS_LIST', clientId);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteClientAddress', status: false });
            }
        },
    },

    mutations: {
        SET_ADDRESS_LIST(state, list) {
            state.addressList = list;
        },

        SET_CLIENT_LAST_ORDER(state, order) {
            state.clientLastOrder = order;
        },

        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', { ...formData });
        },

        SET_FORM_DATA_PROP(state, { key, value }) {
            Vue.set(state.formData, key, value);
        },

        SET_NOVA_POSHTA_DATA(state, novaPoshtaData) {
            Vue.set(state, 'novaPoshtaData', { ...novaPoshtaData });
        },

        SET_NOVA_POSHTA_DATA_PROP(state, { key, value }) {
            Vue.set(state.novaPoshtaData, key, value);
        },

        SET_DPD_BALTIC_DATA(state, dpdBalticData) {
            Vue.set(state, 'dpdBalticData', { ...dpdBalticData });
        },

        SET_DPD_BALTIC_DATA_PROP(state, { key, value }) {
            Vue.set(state.dpdBalticData, key, value);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', Object.assign({ ...state.formData }, { ...formData }));
        },

        UPDATE_NOVA_POSHTA_DATA(state, novaPoshtaData) {
            Vue.set(state, 'novaPoshtaData', Object.assign({ ...state.novaPoshtaData }, { ...novaPoshtaData }));
        },

        UPDATE_DPD_BALTIC_DATA(state, dpdBalticData) {
            Vue.set(state, 'dpdBalticData', Object.assign({ ...state.dpdBalticData }, { ...dpdBalticData }));
        },

        RESET_FORM_DATA(state) {
            Vue.set(state, 'formData', { ...COMMON_FORM_DATA });
        },

        RESET_NOVA_POSHTA_DATA(state) {
            Vue.set(state, 'novaPoshtaData', { ...NOVA_POSHTA_FORM_DATA });
        },

        RESET_DPD_BALTIC_DATA(state) {
            Vue.set(state, 'dpdBalticData', { ...DPD_BALTIC_FORM_DATA });
        },
    },
};
