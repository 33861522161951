import Vue from 'vue';

// @Explanation: This is required to receive a responses from Preflights on the DEV server and for correct processing of files on PROD server
const hasCallback = !process.env.VUE_APP_API.includes('stage') && !process.env.VUE_APP_API.includes('release');

export default {
    namespaced: true,
    state: {
        CHECK_MAKET_TYPE_AUTO: 'auto',
        CHECK_MAKET_TYPE_MANUAL: 'manual',

        STATUS_EMPTY: 'empty',
        STATUS_CLOSE: 'close',
        STATUS_LOADING: 'working',
        STATUS_SUCCESS: 'success',
        STATUS_ERROR: 'fail',
        STATUS_COMMON_CUT: 'cut',
        STATUS_NEEDED: 'needed',
        STATUS_LINK: 'link',

        PAGE_SIDE_FRONT: 'front',
        PAGE_SIDE_BACK: 'back',

        TYPE_MAKET_ORDER: 'order',
        TYPE_MAKET_CABINET: 'cabinet',
        TYPE_MAKET_OPERATION: 'operation',

        TAB_MAKET_ORIGIN: 'origin',
        TAB_MAKET_MAKET: 'maket',
        TAB_MAKET_LINE: 'line',

        TYPE_LINK_MOCKUP: 'destination',
        TYPE_LINK_LINE: 'destination_imposition',

        typeChekmaket: null,

        productLinks: [],
        maketsMap: {},

        hasLoadingGetFilesHashes: false,
        hasLoadingChangeFilePath: false,
        hasLoadingUploadFile: false,
        hasLoadingLinkFile: false,
    },

    getters: {
        hasShowFileNoty(state) {
            let showNoty = false;

            $fn.forIn(state.maketsMap, (product) => {
                $fn.forIn(product, (maket) => {
                    if (
                        maket.status === state.STATUS_LOADING ||
                        maket.status === state.STATUS_SUCCESS ||
                        maket.status === state.STATUS_ERROR
                    ) {
                        showNoty = true;
                    }
                });
            });

            return showNoty;
        },

        maketNotyList(state) {
            let list = [];

            $fn.forIn(state.maketsMap, (product) => {
                $fn.forIn(product, (maket) => {
                    if (maket.hasNoty) list.push(maket);
                });
            });

            return list;
        },

        formattedMockupsList(state) {
            return Object.values(state.maketsMap)
                .map((product) => Object.values(product))
                .flat();
        },

        mostSmalleProgress(state) {
            let smalleProgress = null;

            $fn.forIn(state.maketsMap, (product) => {
                $fn.forIn(product, (maket) => {
                    if (
                        maket.status === state.STATUS_LOADING ||
                        maket.status === state.STATUS_SUCCESS ||
                        maket.status === state.STATUS_ERROR
                    ) {
                        smalleProgress =
                            smalleProgress === null ? maket.progress : maket.progress < smalleProgress ? maket.progress : 0;
                    }
                });
            });

            return smalleProgress;
        },

        hasMockupsLoading(state, getters) {
            return !!getters.maketNotyList.filter((item) => item.status === state.STATUS_LOADING).length;
        },
    },

    mutations: {
        BUILD_MAKETS_MAP(state, { hash, maketsObj }) {
            Vue.set(state.maketsMap, hash, $fn.cloneDeep(maketsObj));
        },

        SET_PRODUCT_LINKS(state, list) {
            state.productLinks = list;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_MAKET(state, { hash, maketKey, formData, resetData }) {
            let maketCnf = state.maketsMap[hash]?.[maketKey];
            if (hasCallback) Object.assign(maketCnf, { callback: process.env.VUE_APP_API });

            $fn.forIn(formData, (val, key) => {
                Vue.set(maketCnf, key, val);
            });

            if (resetData) state.maketsMap[hash][maketKey] = $fn.assign(state.maketsMap[hash][maketKey], resetData);
        },

        DELETE_PRODUCT_MAKETS(state, hash) {
            Vue.delete(state.maketsMap, hash);
        },

        RESET_MAKETS_MAP(state) {
            $fn.forIn(state.maketsMap, (product, hash) => {
                Vue.delete(state.maketsMap, hash);
            });
        },

        SET_TYPE_CHECK_MAKET(state, type) {
            state.typeChekmaket = type;
        },
    },

    actions: {
        GET_FILE_HASHES: async ({ rootState, rootGetters, state, getters, commit, dispatch }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingGetFilesHashes',
                    status: true,
                });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/mockup-hashes`);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingGetFilesHashes',
                    status: false,
                });
            }
        },

        CHANGE_FILE_PATH: async ({ rootState, rootGetters, state, getters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingChangeFilePath',
                    status: true,
                });

                let resp = await $axios.post(
                    `/api/mockups/change-path`,
                    !hasCallback ? formData : Object.assign({ ...formData, callback: process.env.VUE_APP_API }),
                );

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingChangeFilePath',
                    status: false,
                });
            }
        },

        UPLOAD_FILE: async ({ rootState, rootGetters, state, getters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingUploadFile',
                    status: true,
                });

                if (hasCallback) formData.append('callback', process.env.VUE_APP_API);

                let resp = await $axios.post(`https://${rootState.typographies.item.preflight_domain}/api/process/upload`, formData);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingUploadFile',
                    status: false,
                });
            }
        },

        AUTOGENERATE_FILE_MAKET: async ({ rootState, rootGetters, state, getters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingUploadFile',
                    status: true,
                });

                let resp = await $axios.post(
                    `https://${rootState.typographies.item.preflight_domain}/api/process/autogenerate`,
                    !hasCallback ? formData : Object.assign({ ...formData, callback: process.env.VUE_APP_API }),
                );

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingUploadFile',
                    status: false,
                });
            }
        },

        GET_PRODUCT_LINKS: async ({ rootState, rootGetters, state, getters, commit, dispatch }, hash) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingGetProductLinks',
                    status: true,
                });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/mockup-hashes/${hash}/links`);

                commit('SET_PRODUCT_LINKS', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingGetProductLinks',
                    status: false,
                });
            }
        },

        LINK_FILE_MOCKUP: async ({ rootState, rootGetters, state, getters, commit, dispatch }, { hash, formData }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingLinkFile',
                    status: true,
                });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/mockup-hashes/${hash}/links`,
                    !hasCallback ? formData : Object.assign({ ...formData, callback: process.env.VUE_APP_API }),
                );

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingLinkFile',
                    status: false,
                });
            }
        },

        DELETE_FILE: async ({ rootState, rootGetters, state, getters, commit, dispatch }, { formData, typeMaket, orderId }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingDeleteFile',
                    status: true,
                });
                let resp = await $axios.post(
                    `https://${rootState.typographies.item.preflight_domain}/api/process/delete`,
                    !hasCallback ? formData : Object.assign({ ...formData, callback: process.env.VUE_APP_API }),
                );

                if (typeMaket === state.TYPE_MAKET_CABINET && orderId) {
                    dispatch('orders/GET_ITEM', { id: orderId }, { root: true });
                }

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'hasLoadingDeleteFile',
                    status: false,
                });
            }
        },
    },
};
