<template>
    <div class="druk-l-card-m" :class="{ 'druk-is-blocked': loadingGetData || hasNoData }">
        <div v-if="loadingGetData" class="druk-l-card__loader">
            <druk-loader :color="'inverse-on-surface'" :size="'s'" />
        </div>

        <div v-if="hasNoData && !loadingGetData" class="druk-l-card__lock">
            <druk-support :isInverse="true">{{ $t('common.multiple_async.no_result') }}</druk-support>
        </div>

        <div class="druk-l-card__header">
            <div class="druk-l-card__headline">
                <div class="druk-l-card__headline-text">{{ $t('dashboard.products.popularity_chart.title') }}</div>
            </div>
        </div>

        <div class="druk-l-card__block">
            <druk-table
                :headers="headers"
                :items="filteredProductsData"
                :emptyPrompt="'...'"
                :isInner="true"
                :surface="'tint-brighter'">
                <template :slot="[`rows-default`]">
                    <tbody v-for="product in filteredProductsData" :key="product.id">
                        <tr>
                            <td>
                                <druk-link
                                    :path="{ name: 'products_item', params: { id: product.id } }"
                                    :label="product.title || `${$t('common.without_name')} - #${product.id} (${product.alias})`"
                                    :isBlank="true" />
                            </td>

                            <td>
                                {{ product.orders_count }}
                            </td>

                            <td>
                                {{ product.price_sum }}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </druk-table>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'products-popularity-grid-chart',

        computed: {
            ...mapState({
                filteredProductsData: (state) => state.dashboard.filteredProductsData,

                loadingGetData: (state) => state.dashboard.loadingGetData,
            }),

            headers() {
                return [
                    { key: 'title', text: this.$t('product.title.singl') },
                    { key: 'orders_count', text: this.$t('order.counter') },
                    { key: 'total', text: this.$t('orders.dashboard.orders_total_price') },
                ];
            },

            hasNoData() {
                return !this.filteredProductsData.length;
            },
        },
    };
</script>

<style lang="scss" scoped></style>
