import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        list: [],
        listPagination: {},

        global: {},

        listFilter: {
            page: null,
            limit: 30,
        },

        loadingGetList: false,
        loadingSaveList: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },
    },

    actions: {
        GET_LIST: async ({ getters, rootState, commit }, filters = {}) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = `/api/typographies/${rootState.typographies.item.id}/schedules`;

                let resp = await $axios.get(path, {
                    params: Object.keys(filters).length ? filters : getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_GLOBAL', resp.global);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        SAVE_LIST: async ({ rootState, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveList', status: true });

                let path = `/api/typographies/${rootState.typographies.item.id}/schedules`;

                let resp = await $axios.post(path, formData);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveList', status: false });
            }
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_GLOBAL(state, global) {
            state.global = global;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));

            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: null,
            };
        },
    },
};
