<template>
    <druk-hint
        v-if="isToolTip"
        :tooltip="{ text: prompt, from: 'left', maxWidth: '240px' }"
        :icon="{ name: 'shield-exclamation', size: 'm', color: 'warning' }" />

    <druk-support v-else :isWarning="true">
        <span v-html="prompt"></span>
    </druk-support>
</template>

<script>
    export default {
        name: 'employees-limit',

        props: {
            isToolTip: Boolean,
            isActivationType: Boolean,
        },

        computed: {
            prompt() {
                return `<span>${this.$t('pricing_plan.limit_prompt.is_reached')}! </span
                    ><span>${this.typePrompt}.</span
                    ><span>${this.$t('pricing_plan.limit_prompt.contact_the_admin')}</span>`;
            },

            typePrompt() {
                return $fn.tShift(
                    this.$t(`pricing_plan.limit_prompt.${this.isActivationType ? 'activtion_unavailable' : 'creation_unavailable'}`),
                    {
                        target: this.$t('pricing_plan.limit_prompt.target.employee'),
                    },
                );
            },
        },
    };
</script>
