<template>
    <div
        class="inputLogin"
        :class="{
            'has-focus': hasActive,
            'has-full': hasFull,
            'has-error': errors.has(name),
        }">
        <div class="inputLogin__label" @click="$refs.area.focus()">{{ label }}</div>

        <template v-if="mask">
            <input
                :value="value"
                ref="area"
                :name="hasName ? name : ''"
                :data-vv-name="name"
                :data-vv-as="label"
                v-validate="rules || ''"
                v-mask="mask"
                :placeholder="placeholder"
                :type="type || 'text'"
                @focus="hasActive = true"
                @blur="hasActive = false"
                @keyup.enter="$emit('enter')"
                @keyup="$emit('keyup')"
                @input="$emit('input', $event.target.value)"
                class="inputLogin__area" />
        </template>

        <template v-else>
            <input
                :value="value"
                ref="area"
                :name="hasName ? name : ''"
                :placeholder="placeholder"
                :data-vv-name="name"
                :data-vv-as="label"
                v-validate="rules || ''"
                :type="type || 'text'"
                @focus="hasActive = true"
                @blur="hasActive = false"
                @keyup.enter="$emit('enter')"
                @keyup="$emit('keyup')"
                @input="$emit('input', $event.target.value)"
                class="inputLogin__area" />
        </template>

        <span v-if="errors.has(name)" class="inputLogin__error">{{ errorText || errors.first(name) }}</span>
    </div>
</template>

<script>
    export default {
        name: 'form-input-login',
        inject: ['$validator'],
        props: {
            value: {
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            mask: String,
            placeholder: String,
            hasName: Boolean,
            type: String,
            label: String,
            translate: String,
            rules: String,
            filterType: Boolean,
            errorText: String,
            hasError: {
                default: false,
                type: Boolean,
            },
        },
        data() {
            return {
                hasActive: false,
            };
        },

        computed: {
            hasFull() {
                return !!this.value;
            },

            isRequired() {
                if (this.rules && this.rules.indexOf('required') != -1) return true;
                return false;
            },
        },

        methods: {
            onClear() {
                if (!this.hasFull) return;
                this.$emit('input', null);
            },
        },
    };
</script>

<style lang="scss" scoped></style>
