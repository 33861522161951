<template>
    <div class="selector selector--select selector--select-tags" :class="{ 'has-focus': hasActive, 'has-full': hasFull }">
        <div v-if="label" class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
            <span>
                <label>{{ label }}</label>
                <template v-if="isRequired">*</template>
            </span>

            <druk-hint
                v-if="hint"
                :tooltip="{ text: hint.text || hint, from: 'top', maxWidth: hint.maxWidth, isNotCollapse: hint.isNotCollapse }"
                :icon="{ name: 'circle-info', size: 'xs', color: hasActive ? 'primary' : 'outline' }" />
        </div>

        <div class="selector__main">
            <multiselect
                ref="multiselect"
                v-model="selectedValues"
                class="selector__area"
                :options="filteredOptions"
                :label="option_label"
                :multiple="true"
                :closeOnSelect="false"
                :track-by="option_id"
                :placeholder="!hasActive ? placeholder || '' : $t('common.placeholder.start_typing')"
                :max="limit"
                :tagPlaceholder="''"
                :selectLabel="''"
                :selectedLabel="''"
                :selectGroupLabel="''"
                :deselectGroupLabel="''"
                :deselectLabel="''"
                :showNoOptions="!limit || (limit && selectedValues.length < limit)"
                @open="hasActive = true"
                @close="onClearList"
                @search-change="onSearch($event)">
                <template slot="noResult">
                    {{ $t('common.multiple_async.no_result') }}
                </template>

                <template slot="noOptions">
                    {{ $fn.tShift($t('common.multiple_async.empty_list'), { searchFrom }) }}
                </template>

                <template slot="maxElements">
                    {{ $fn.tShift($t('common.multiselect.prompt.limit_reached'), { limit }) }}
                </template>
            </multiselect>

            <div class="selector__loader" v-if="hasLoadingList">
                <druk-loader :isIcon="true" />
            </div>

            <div class="selector__nav" v-else>
                <font-awesome-icon icon="fa-regular fa-magnifying-glass" />
            </div>
        </div>

        <div v-if="errors.has(name)" class="selector__error">
            <span :class="{ [`druk-l-surface-${surface}`]: surface }">{{ errorText || errors.first(name) }}</span>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    const CancelToken = axios.CancelToken;
    let cancel;

    import Multiselect from 'vue-multiselect';

    export default {
        name: 'form-multiple-async',
        inject: ['$validator'],
        components: {
            Multiselect,
        },
        props: {
            value: {
                require: true,
            },
            options: {
                required: true,
                type: Array,
            },

            path: String,
            keySearch: String,
            label: String,
            hint: [String, Object],
            name: String,

            option_id: {
                type: String,
                default: 'id',
            },
            option_label: {
                type: String,
                default: 'title',
            },

            rules: String,
            placeholder: String,
            extraOptions: Array,

            searchFrom: {
                type: [Number, String],
                default: 3,
            },

            limit: [Number, String],
            errorText: String,

            surface: {
                type: String,
                default: 'tint-pale',
            },
        },
        data() {
            return {
                timer: null,
                defer: 300,

                hasActive: false,
                hasLoadingList: false,
            };
        },

        computed: {
            selectedValues: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                },
            },

            extraFilters() {
                if (!this.extraOptions) return '';
                return this.extraOptions.map((filter) => `&${Object.values(filter).join('=')}`).join();
            },

            filteredOptions() {
                return this.options.filter(
                    (option) => !this.value.find((item) => item[this.option_id || 'id'] === option[this.option_id || 'id']),
                );
            },

            hasFull() {
                return !!this.value;
            },

            isRequired() {
                if (this.rules && this.rules.indexOf('required') != -1) return true;
                return false;
            },
        },

        methods: {
            onSearch(value) {
                clearTimeout(this.timer);
                if (!value || value.length < this.searchFrom) return;

                this.timer = setTimeout(() => {
                    this.hasLoadingList = true;

                    if (cancel) cancel();

                    this.$axios
                        .get(`${this.path}?${this.keySearch}=${value}${this.extraFilters}`, {
                            cancelToken: new CancelToken(function executor(c) {
                                cancel = c;
                            }),
                        })
                        .then((resp) => {
                            this.$emit('search', resp.list);

                            this.hasLoadingList = false;
                        })
                        .catch((err) => {
                            this.$emit('input', null);
                            this.$emit('search', []);

                            this.hasLoadingList = false;

                            if (err?.response?.status === 403) {
                                this.$noty.error(this.$t('search.async.not'));
                                $VUE_APP.$bus.$emit('permission');
                            }
                        });
                }, this.defer);
            },

            onClearList() {
                this.$emit('search', []);
                this.hasActive = false;
            },

            onToggle() {
                this.$refs.multiselect.activate();
            },
        },
    };
</script>
