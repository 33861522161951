<template>
    <div class="druk-l-form">
        <div class="druk-l-form__body">
            <div v-if="hasLoadedGeneralData" class="druk-l-grid">
                <div
                    v-for="(item, index) in list"
                    v-show="item.code !== DEFAULT_CURRENCY"
                    :key="item.code"
                    class="druk-l-grid__col-4 druk-u-md-12 druk-is-laced">
                    <div class="druk-l-form__item" :class="{ 'druk-is-last': index === list.length - 1 }">
                        <div class="druk-l-form__item-base">
                            <form-input
                                v-model="formData[item.code]"
                                :class="{ 'has-loading': loadingSaveCurrencies }"
                                label="1€ ="
                                :placeholder="{ after: item.code }"
                                rules="required|positive_float"
                                :surface="'tint-brighter'"
                                :name="item.code" />

                            <div class="druk-l-form__item-addl">
                                <tool-tip :text="$t('currency.form.set_bank_extange')">
                                    <druk-icon-button
                                        :type="'outlined'"
                                        :icon="'rotate'"
                                        :isWaiting="loadingSaveCurrencies"
                                        @click="getBankCurrency(item)" />
                                </tool-tip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="druk-l-form__loader">
                <druk-loader :size="'s'" />
            </div>
        </div>

        <div class="druk-l-form__footer">
            <div class="druk-l-form__footer-item">
                <druk-button :icon="'floppy-disk'" :label="$t('btn.save')" :isWaiting="loadingSaveCurrencies" @click="onSave" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

    export default {
        name: 'form-edit-currency',

        data() {
            return {
                formData: {},

                hasLoadedGeneralData: false,
                loadingSaveCurrencies: false,
            };
        },

        created() {
            this.initGeneralData();
        },

        computed: {
            ...mapState({
                DEFAULT_CURRENCY: (state) => state.currency.DEFAULT_CURRENCY,

                list: (state) => state.currency.list,
            }),
        },

        methods: {
            ...mapActions({
                GET_LIST: 'currency/GET_LIST',
                GET_BANK_CURRENCY: 'currency/GET_BANK_CURRENCY',
                SAVE_CURRENCY: 'currency/SAVE_CURRENCY',
            }),

            async initGeneralData() {
                await this.getList();
                this.setFormData();

                this.hasLoadedGeneralData = true;
            },

            getList() {
                return this.GET_LIST();
            },

            setFormData() {
                this.list.forEach((item, index) => this.$set(this.formData, `${item.code}`, item.rate));
            },

            async getBankCurrency(currency) {
                this.loadingSaveCurrencies = true;
                console.log('this.$echo', this.$echo);

                await this.GET_BANK_CURRENCY(currency);

                this.$echo.channel(`admin.currency.${this.$store.state.auth.user.id}`).listen('.exchange', (resp) => {
                    console.log('soket unswer', resp);

                    this.SAVE_CURRENCY({
                        currency: Object.assign(currency, {
                            rate: parseInt(resp.rate * 100) / 100,
                        }),
                    });

                    this.loadingSaveCurrencies = false;
                    this.initGeneralData();
                });
            },

            async onSave(e) {
                let result = await this.$validator.validate();

                if (result) {
                    try {
                        this.loadingSaveCurrencies = true;

                        let editedItems = this.list.filter((item) => this.formData[item.code] !== item.rate);

                        await Promise.all(
                            editedItems.map((item) =>
                                this.SAVE_CURRENCY({
                                    currency: Object.assign(item, {
                                        rate: parseFloat(this.formData[item.code]),
                                    }),
                                    without_noty: true,
                                }),
                            ),
                        );

                        this.$noty.success(this.$t('common.noty_post_success'));

                        this.$emit('sendForm');
                    } catch (e) {
                        $fn.setValidateErrors(e, this.errors);
                    } finally {
                        this.loadingSaveCurrencies = false;
                    }
                } else $fn.showFormError(e.target.closest('.druk-c-btn'), this.$validator.errors.items);
            },
        },
    };
</script>

<style lang="scss" scoped></style>
