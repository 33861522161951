import { render, staticRenderFns } from "./FormImg.vue?vue&type=template&id=64722352&scoped=true"
import script from "./FormImg.vue?vue&type=script&lang=js"
export * from "./FormImg.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64722352",
  null
  
)

export default component.exports