<template>
    <div class="druk-l-card-m" :class="{ 'druk-is-blocked': loadingGetData }">
        <div v-if="loadingGetData" class="druk-l-card__loader">
            <druk-loader :color="'inverse-on-surface'" :size="'s'" />
        </div>

        <div class="druk-l-card__header">
            <div class="druk-l-card__headline">
                <div class="druk-l-card__headline-text">
                    {{ $t('dashboard.orders.base_chart.title') }}
                </div>
            </div>
        </div>

        <div class="druk-l-card__block">
            <div class="druk-l-grid druk-is-reduced">
                <div class="druk-l-grid__col-3 druk-u-def-6 druk-u-sm-12 druk-u-def-gap-l">
                    <div class="druk-l-card-s druk-l-surface-tint-brighter">
                        <div class="druk-l-info-list">
                            <div class="druk-l-info-list__item druk-u-gap-l">
                                <druk-support :hasNoIcon="true">{{ $t('dashboard.orders.base_chart.total') }}</druk-support>
                            </div>

                            <div class="druk-l-info-list__item druk-u-text-headline-m druk-is-proniment">
                                {{ filteredOrdersData.total }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="druk-l-grid__col-3 druk-u-def-6 druk-u-sm-12 druk-u-def-gap-l">
                    <div class="druk-l-card-s druk-l-surface-tint-brighter">
                        <div class="druk-l-info-list">
                            <div class="druk-l-info-list__item druk-u-gap-l">
                                <druk-support :hasNoIcon="true"
                                    >{{ $t('dashboard.orders.base_chart.total_price') }} ({{ selectedCurrencyCode }})</druk-support
                                >
                            </div>

                            <div class="druk-l-info-list__item druk-u-text-headline-m druk-is-proniment">
                                {{ filteredOrdersData.total_price }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="druk-l-grid__col-3 druk-u-def-6 druk-u-sm-12 druk-u-sm-gap-l">
                    <div class="druk-l-card-s druk-l-surface-tint-brighter">
                        <div class="druk-l-info-list">
                            <div class="druk-l-info-list__item druk-u-gap-l">
                                <druk-support :hasNoIcon="true"
                                    >{{ $t('dashboard.orders.base_chart.avg_price') }} ({{ selectedCurrencyCode }})</druk-support
                                >
                            </div>

                            <div class="druk-l-info-list__item druk-u-text-headline-m druk-is-proniment">
                                {{ filteredOrdersData.avg_price }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="druk-l-grid__col-3 druk-u-def-6 druk-u-sm-12">
                    <div class="druk-l-card-s druk-l-surface-tint-brighter druk-is-under-development">
                        <div class="druk-l-card__lock">
                            {{ $t('dashaboard.prompt.in_development') }}
                        </div>

                        <div class="druk-l-info-list">
                            <div class="druk-l-info-list__item druk-u-gap-l">
                                <druk-support :hasNoIcon="true">{{
                                    $t('dashboard.orders.base_chart.total_manufacturing_costs')
                                }}</druk-support>
                            </div>

                            <div class="druk-l-info-list__item druk-u-text-headline-m druk-is-proniment">
                                {{ filteredOrdersData.total_manufacturing_costs }}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'orders-base-mosaic-chart',

        computed: {
            ...mapState({
                selectedCurrencyCode: (state) => state.selectedCurrencyCode,
                filteredOrdersData: (state) => state.dashboard.filteredOrdersData,

                loadingGetData: (state) => state.dashboard.loadingGetData,
            }),
        },
    };
</script>

<style lang="scss" scoped></style>
