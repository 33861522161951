<template>
    <div v-if="currentMode" :class="{ [`druk-l-card-m druk-l-surface-${surface}`]: !hasAltStyles }">
        <div class="druk-l-card__header">
            <div class="druk-l-card__title druk-has-addl druk-u-md-break">
                <div class="druk-l-card__title-text">{{ title }}</div>

                <div class="druk-l-card__title-addl">
                    <form-select
                        v-model="currentMode"
                        :class="{ 'druk-u-max-width-360': !isMDMaxWidth }"
                        :label="isMDMaxWidth ? $t('product.calculation_type') : ''"
                        :notEmpty="true"
                        :options="tModesList"
                        option_id="value"
                        name="currentMode"
                        :surface="surface"
                        @input="onChangeMode" />
                </div>
            </div>
        </div>

        <transition name="fade" mode="out-in">
            <component
                :is="currentMode.layout"
                :type="type"
                :translatesBase="translatesBase"
                :surface="surface"
                :surfaceVariant="surfaceVariant"
                :isEquipmentType="isEquipmentType"
                :hasPreviewMode="hasPreviewMode"
                :hasAltStyles="hasAltStyles"
                @ranges-mode="$emit('ranges-mode')"
                @curve-mode="$emit('curve-mode')"
                @formula-mode="$emit('formula-mode')" />
        </transition>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import mixinModes from './mixins/mixinModes';

    export default {
        name: 'calc-modes',

        mixins: [mixinModes],

        props: {
            title: String,
            type: String, // full, equipment, material
            translatesBase: String,

            surface: {
                type: String,
                default: 'tint-bright',
            },

            surfaceVariant: {
                type: String,
                default: 'tint-brighter',
            },

            hasPreviewMode: Boolean,
            hasAltStyles: Boolean,
        },

        computed: {
            ...mapGetters({
                isMDMaxWidth: 'isMDMaxWidth',
            }),
        },

        methods: {
            ...mapMutations({
                RESTORE_RANGES: 'calcModes/ranges/RESTORE_LIST',
                RESTORE_CURVE: 'calcModes/curve/RESTORE_ITEM',
                RESTORE_FORMULA: 'calcModes/formula/RESTORE_AREA',
                RESET_RANGES: 'calcModes/ranges/RESET_LIST',
                RESET_CURVE: 'calcModes/curve/RESET_ITEM',
                RESET_FORMULA: 'calcModes/formula/RESET_AREA',
            }),

            onChangeMode() {
                if (this.hasRangesCalcMode) this.setRangesModeData();
                if (this.hasCurveCalcMode) this.setCurveModeData();
                if (this.hasFormulaCalcMode) this.setFormulaModeData();
            },

            setRangesModeData() {
                this.RESTORE_RANGES();
                this.RESET_CURVE();
                this.RESET_FORMULA();
            },

            setCurveModeData() {
                this.RESTORE_CURVE();
                this.RESET_RANGES();
                this.RESET_FORMULA();
            },

            setFormulaModeData() {
                this.RESTORE_FORMULA();
                this.RESET_RANGES();
                this.RESET_CURVE();
            },
        },
    };
</script>

<style lang="scss" scoped></style>
