<template>
    <div class="curve">
        <div v-if="!hasPreviewMode" class="druk-l-form">
            <div class="druk-l-form__body druk-has-no-top-gap">
                <div class="druk-l-grid">
                    <div class="druk-l-grid__col-8 druk-u-def-12 druk-u-def-gap-l">
                        <div class="druk-l-form__title">
                            <div class="druk-l-form__title-text">{{ $t('common.params') }}</div>
                        </div>

                        <div class="druk-l-grid">
                            <div class="druk-l-grid__col-4 druk-u-lg-12 druk-u-lg-gap-xl">
                                <div class="druk-l-form__item druk-is-last">
                                    <form-input
                                        v-model="curve.max_price"
                                        :label="`${$t('equep.curve.price.max')} (${selectedCurrencyCode})`"
                                        name="max_price"
                                        :rules="`required|positive_float:decimal:4,min_value:${(($fn.toFloat(curve.min_price) || 0.0001) + 0.0001).toFixed(4)}`"
                                        :surface="surface"
                                        @input="onCurveUpdate" />
                                </div>
                            </div>

                            <div class="druk-l-grid__col-4 druk-u-lg-12 druk-u-lg-gap-xl">
                                <div class="druk-l-form__item druk-is-last">
                                    <form-input
                                        v-model="curve.min_price"
                                        :label="`${$t('equep.curve.price.min')} (${selectedCurrencyCode})`"
                                        name="min_price"
                                        :rules="`required|positive_float:decimal:4,min_value:${0.0001},max_value:${(($fn.toFloat(curve.max_price) || 0.0002) - 0.0001).toFixed(4)}`"
                                        :surface="surface"
                                        @input="onCurveUpdate" />
                                </div>
                            </div>

                            <div class="druk-l-grid__col-4 druk-u-lg-12">
                                <div class="druk-l-form__item druk-is-last">
                                    <div class="druk-l-form__item-base">
                                        <form-input
                                            v-model="curve.max_count"
                                            :label="$t(`${translatesBase}.curve.price.count`)"
                                            name="max_count"
                                            rules="required|int"
                                            :surface="surface"
                                            :hint="{ text: $t(`${translatesBase}.curve.price.count.info`), isSide: true }"
                                            @input="onCurveUpdate" />

                                        <tool-tip
                                            class="druk-l-form__item-addl"
                                            :from="'top'"
                                            :text="$t('librery.algorithms.components.workItem.resetParams')"
                                            :isNotCollapse="true"
                                            @click="onCurveReset">
                                            <druk-icon-button :type="'outlined'" :icon="'rotate-left'" />
                                        </tool-tip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="druk-l-grid__col-4 druk-u-def-12">
                        <div class="druk-l-form__title">
                            <div class="druk-l-form__title-text">{{ $t('common.calculation') }}</div>
                        </div>

                        <div class="druk-l-form__item druk-is-last">
                            <form-input
                                v-model="formData.count"
                                :label="`${$t(`${translatesBase}.curve.for.click`)} = ${parseFloat(formData.price).toFixed(4)} (${selectedCurrencyCode})`"
                                name="count"
                                rules="int"
                                :surface="surface"
                                @input="onChangeCount(+$event)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="hasLoadedData">
            <calc-curve-canvas
                :translatesBase="translatesBase"
                :surface="surface"
                :surfaceVariant="surfaceVariant"
                :isEquipmentType="isEquipmentType"
                :hasPreviewMode="hasPreviewMode"
                @onUpdate="onUpdate" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import CalcCurveCanvas from './curve/CalcCurveCanvas';

    export default {
        name: 'calc-modes-curve',

        inject: ['$validator'],

        components: {
            CalcCurveCanvas,
        },

        props: {
            translatesBase: {
                type: String,
                default: 'equep',
            },

            surface: {
                type: String,
                default: 'tint-pale',
            },
            surfaceVariant: {
                type: String,
                default: 'tint-bright',
            },

            isEquipmentType: Boolean,
            hasAltStyles: Boolean,
            hasPreviewMode: Boolean,
        },

        data() {
            return {
                formData: {
                    count: 1,
                    price: 0,
                },

                hasLoadedData: false,
            };
        },

        created() {
            this.setCommonData();
        },

        computed: {
            ...mapState({
                COMMON_CURVE: (state) => state.calcModes.curve.COMMON_ITEM,

                curve: (state) => state.calcModes.curve.item,
                curveRanges: (state) => state.calcModes.ranges.listStash,

                selectedCurrencyCode: (state) => state.selectedCurrencyCode,
            }),

            isEditMode() {
                return !!this.curve;
            },

            hasRanges() {
                return this.curveRanges.length > 1;
            },
        },

        methods: {
            ...mapMutations({
                SET_CURVE: 'calcModes/curve/SET_ITEM',
                UPDATE_CURVE: 'calcModes/curve/UPDATE_ITEM',
            }),

            setCommonData() {
                if (!this.isEditMode) {
                    this.SET_CURVE({
                        ...this.COMMON_CURVE,
                        max_count: this.hasRanges ? [...this.curveRanges].pop().count : null,
                        max_price: this.hasRanges ? [...this.curveRanges].shift().price : null,
                        min_price: this.hasRanges ? [...this.curveRanges].pop().price : null,
                    });
                }

                this.formData.price = this.curve.max_price || 0;

                this.hasLoadedData = true;

                this.$emit('curve-mode');
                this.onCurveUpdate();
            },

            onCurveUpdate() {
                this.$bus.$emit('on-curve-update');
            },

            onCurveReset() {
                this.$bus.$emit('on-curve-reset');
            },

            onChangeCount(value) {
                this.$bus.$emit('on-change-count', value);
            },

            onUpdate(data) {
                Object.assign(this.formData, data);
            },
        },
    };
</script>

<style lang="scss" scoped></style>
