<!--@TODO: Temporary modal for konica-->
<template>
    <div class="vmodal">
        <div class="vmodal__container">
            <div class="vmodal__head">
                <div class="vmodal__title">
                    <div class="vmodal__title-text">{{ $t('tech.information') }}</div>
                </div>

                <druk-icon-button :type="'standard'" :icon="'xmark'" @click="$emit('close')" />
            </div>

            <div class="vmodal__main">
                <div class="druk-l-form">
                    <div class="druk-l-form__body">
                        <div class="druk-l-form__block">
                            <div class="druk-l-form__headline">
                                <div class="druk-l-form__headline-text">{{ $t('techin.check') }}</div>

                                <div class="druk-l-form__headline-support">
                                    <druk-support>
                                        <span v-html="deviceInfo"></span>
                                    </druk-support>
                                </div>
                            </div>

                            <div class="druk-l-form__headline">
                                <div class="druk-l-form__headline-text">{{ $t('techin.device') }}</div>

                                <div class="druk-l-form__headline-support">
                                    <druk-support>
                                        <span v-html="deviceDetect"></span>
                                    </druk-support>
                                </div>
                            </div>

                            <div class="druk-l-form__headline">
                                <div class="druk-l-form__headline-text">{{ $t('techin.screen') }}</div>

                                <div class="druk-l-form__headline-support">
                                    <druk-support>
                                        <span v-html="window"></span>
                                    </druk-support>
                                </div>
                            </div>
                        </div>

                        <div class="druk-l-form__block druk-is-last">
                            <div class="druk-l-form__headline druk-is-reduced">
                                <div class="druk-l-form__headline-text">{{ $t('common.color_theme.title') }}</div>
                            </div>

                            <div class="druk-l-grid">
                                <div class="druk-l-grid__col-3 druk-u-md-12 druk-u-md-gap-xl">
                                    <div class="druk-l-form__item druk-is-last">
                                        <alternative-header-logo />

                                        <div class="druk-l-form__support">
                                            <druk-support>{{ $t('common.color_theme.alternative_logo_prompt') }}</druk-support>
                                        </div>
                                    </div>
                                </div>

                                <div class="druk-l-grid__col-3 druk-u-md-12 druk-u-md-gap-xl">
                                    <div class="druk-l-form__item druk-is-last">
                                        <inverse-sidebar-picker />

                                        <div class="druk-l-form__support">
                                            <druk-support>{{ $t('common.color_theme.contrast_drawer_prompt') }}</druk-support>
                                        </div>
                                    </div>
                                </div>

                                <div class="druk-l-grid__col-3 druk-u-md-12">
                                    <div class="druk-l-form__item druk-is-last">
                                        <theme-picker />

                                        <div class="druk-l-form__support">
                                            <druk-support>{{ $t('common.color_theme.dark_theme_prompt') }}</druk-support>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="druk-l-form__footer">
                        <div class="druk-l-form__footer-item">
                            <druk-button
                                :path="{ name: 'druk_developer' }"
                                :icon="'wand-magic-sparkles'"
                                :label="$t('developer_page.title')"
                                @click.native="$emit('close')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AlternativeHeaderLogo from '@views/typographies/theme/AlternativeHeaderLogo.vue';
    import InverseSidebarPicker from '@views/typographies/theme/InverseSidebarPicker';
    import ThemePicker from '@views/typographies/theme/ThemePicker';

    export default {
        name: 'modal-tech-data',

        components: {
            AlternativeHeaderLogo,
            InverseSidebarPicker,
            ThemePicker,
        },

        computed: {
            deviceInfo() {
                return Object.entries(this.$device)
                    .filter(([key, value]) => !!value && typeof value !== 'function')
                    .map(([key, value]) => `${key}: <b>${value}</b>`)
                    .join('; ');
            },

            deviceDetect() {
                return Object.entries(this.$device.deviceDetect())
                    .map(([key, value]) => `${key}: <b>${value}</b>`)
                    .join('; ');
            },

            window() {
                return [
                    `offsetWidth: <b>${window.innerWidth}</b>`,
                    `offsetHeight: <b>${window.innerHeight}</b>`,
                    `screenWidth: <b>${window.screen.width}</b>`,
                    `screenHeight: <b>${window.screen.height}</b>`,
                ].join('; ');
            },
        },
    };
</script>

<style lang="scss" scoped></style>
