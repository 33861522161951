<template>
    <div class="druk-l-section__footer">
        <div class="druk-l-card-m druk-is-board">
            <div class="druk-l-options druk-is-alt">
                <div class="druk-l-options__group">
                    <div
                        v-if="$fn.hasAccess(deletePermissionModule, deletePermissionAction) && isEditMode"
                        class="druk-l-options__item">
                        <druk-dialog-option :isUnavailable="hasButtonLoader" @success="$emit('onDelete', $event)">
                            <druk-button
                                :type="'outlined'"
                                :icon="'trash'"
                                :label="$t('common.delete')"
                                :isHighlighted="true"
                                :isWaiting="hasButtonLoader" />
                        </druk-dialog-option>
                    </div>

                    <div v-if="hasBackwardButton" class="druk-l-options__item">
                        <druk-button
                            :type="'outlined'"
                            :icon="'backward'"
                            :label="backWardLabel"
                            :isWaiting="hasButtonLoader"
                            @click="$emit('onCancel', $event)" />
                    </div>

                    <div v-else class="druk-l-options__item">
                        <druk-dialog-option @success="$emit('onCancel', $event)">
                            <druk-button :type="'outlined'" :icon="'xmark'" :label="$t('admin.btn.cancel')" />
                        </druk-dialog-option>
                    </div>
                </div>

                <div class="druk-l-options__group">
                    <div class="druk-l-options__item" :class="{ '_order-1': hasLastStage }">
                        <druk-button
                            :type="!hasLastStage ? 'tonal' : 'filled'"
                            :icon="'floppy-disk'"
                            :label="isEditMode ? $t('btn.save') : $t('btn.create')"
                            :isWaiting="hasButtonLoader"
                            @click="$emit('onSave', $event)" />
                    </div>

                    <div v-if="!hasFirstStage" class="druk-l-options__item">
                        <druk-button
                            :type="'tonal'"
                            :icon="'backward'"
                            :label="$t('common.go.back')"
                            :isWaiting="hasButtonLoader"
                            :isDisabled="hasBrokenWizard"
                            @click="$emit('onBack', $event)" />
                    </div>

                    <div v-if="!hasLastStage" class="druk-l-options__item">
                        <druk-button
                            :icon="'forward'"
                            :label="$t('admin.btn.continue')"
                            :isWaiting="hasButtonLoader"
                            :isDisabled="hasBrokenWizard"
                            @click="$emit('onNext', $event)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'wizard-footer',

        props: {
            deletePermissionModule: String,
            deletePermissionAction: String,
            backWardLabel: String,
            isEditMode: Boolean,
            hasFirstStage: Boolean,
            hasLastStage: Boolean,
            hasBrokenWizard: Boolean,
            hasBackwardButton: Boolean,
            loadingSaveItem: Boolean,
            loadingDeleteItem: Boolean,
        },

        computed: {
            hasButtonLoader() {
                return this.loadingSaveItem || this.loadingDeleteItem;
            },
        },
    };
</script>

<style lang="scss" scoped></style>
