<template>
    <div class="draggable">
        <draggable
            class="draggable__list"
            tag="ul"
            v-model="sortedList"
            v-bind="dragOptions"
            @start="startDragging"
            @end="endDragging">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                <li v-for="item in sortedList" :key="item.id" class="draggable__item">
                    <div class="draggable__card druk-l-surface-tint-pale">
                        <div class="draggable__drop">
                            <font-awesome-icon icon="fa-regular fa-grip-dots-vertical" />
                        </div>

                        <div class="draggable__icon">
                            <img
                                :src="item.preview || item.image || require('@img/placeholder.svg')"
                                alt=""
                                @error="$fn.fixImageError" />
                        </div>

                        <div class="draggable__title">
                            {{ item.title || `${$t('common.without_name')}(${item.alias})`, }}
                            <i v-if="item.is_default">({{ $t('views.selected') }})</i>
                        </div>
                    </div>
                </li>
            </transition-group>
        </draggable>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        name: 'draggable-list',
        components: {
            draggable,
        },
        props: {
            list: {
                required: true,
                type: Array,
            },
        },
        data() {
            return {
                sortedList: this.list,

                dragOptions: {
                    animation: 200,
                    group: 'description',
                    disabled: false,
                    ghostClass: 'ghost',
                },

                drag: false,
            };
        },

        methods: {
            startDragging() {
                this.drag = true;
            },

            endDragging() {
                this.drag = false;
                this.$emit('onUpdate', this.sortedList);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .draggable {
        &__item {
            margin-bottom: var(--druk-gap-l);

            &:last-child {
                margin-bottom: 0;
            }
        }
        &__card {
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            padding: var(--druk-gap-m) var(--druk-gap-l);
            height: 60px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            font-weight: 500;
            border-radius: 12px;
            box-shadow: var(--druk-elevation-1);
            transition: box-shadow 0.2s ease-in-out;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 12px;
                background-color: transparent;
                transition: background-color 0.2s ease-in-out;
            }
            &:hover {
                box-shadow: var(--druk-elevation-2);
                &::before {
                    background-color: var(--druk-state-layers-on-surface-0-08);
                }
            }
            &:active,
            &:focus {
                box-shadow: var(--druk-elevation-1);
                &::before {
                    background-color: var(--druk-state-layers-on-surface-0-12);
                }
            }
        }
        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: var(--druk-gap-s);
            width: 40px;
            height: 40px;
            background-color: (--druk-surface-dim);
            img {
                max-width: 40px;
                max-height: 40px;
            }
        }
        &__drop {
            margin-right: var(--druk-gap-l);
        }
    }

    .ghost {
        .draggable__card {
            box-shadow: var(--druk-elevation-4);
        }
    }
</style>
