<template>
    <div class="druk-l-card-m" :class="{ 'druk-has-blocked': loadingGetData, 'druk-is-under-development': !loadingGetData }">
        <div v-if="loadingGetData" class="druk-l-card__loader">
            <div class="druk-l-card druk-l-card-m druk-l-surface druk-l-shape-lg druk-has-inverse-surface">
                <druk-loader :color="'inverse-on-surface'" :size="'s'" />
            </div>
        </div>

        <div v-else class="druk-l-card__lock">
            {{ $t('dashaboard.prompt.in_development') }}
        </div>

        <div class="druk-l-card__header">
            <div class="druk-l-card__headline">
                <div class="druk-l-card__headline-text">
                    {{ $t('dashboard.orders.status_polar_chart.title') }}
                </div>
            </div>
        </div>

        <div class="druk-l-card__body">
            <PolarArea :ref="'chart'" :chart-data="chartConfig.data" :chart-options="chartConfig.options" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import { PolarArea } from 'vue-chartjs/legacy';

    import mixinChart from '@mixins/mixinChart';

    export default {
        name: 'orders-status-polar-chart',

        mixins: [mixinChart],

        components: {
            PolarArea,
        },

        computed: {
            ...mapState({
                ORDER_STATUSES_COLOR_MAP: (state) => state.orders.statuses.ORDER_STATUSES_COLOR_MAP,

                ordersData: (state) => state.dashboard.ordersData,
                statuses: (state) => state.orders.statuses.list,

                loadingGetData: (state) => state.dashboard.loadingGetData,
                loadingGetStatuses: (state) => state.orders.statuses.loadingGetList,
            }),

            chartConfig() {
                return {
                    data: {
                        labels: this.statusesData.map((status) => status.title),

                        datasets: [
                            {
                                data: this.statusesData.map((status) => status.data),
                                borderWidth: 1,
                                borderColor: this.statusesData.map((status) => this.COLORS_MAP[status.color]?.border),
                                backgroundColor: this.statusesData.map((status) => this.COLORS_MAP[status.color]?.background),
                                hoverBackgroundColor: this.statusesData.map(
                                    (status) => this.COLORS_MAP[status.color]?.backgroundHover,
                                ),
                            },
                        ],
                    },

                    options: {
                        ...this.chartOptions,
                        scales: this.polarScalesPluginOptions,
                        plugins: {
                            datalabels: {
                                display: false,
                            },
                            doughnutlabel: {
                                display: false,
                            },
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                ...this.tooltipPluginOptions,
                            },
                        },
                    },
                };
            },

            statusesData() {
                return this.statuses.map((status) => ({
                    ...status,
                    color: this.ORDER_STATUSES_COLOR_MAP[status.alias],
                    data: (Math.random() * (500 - 245) + this.ordersData[status.alias]).toFixed(0),
                }));
            },
        },
    };
</script>
