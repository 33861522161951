<template>
    <div class="vmodal">
        <div class="vmodal__container">
            <div class="vmodal__head">
                <div class="vmodal__title">
                    <div class="vmodal__title-text">{{ $t('login.locked_modal.title') }}</div>
                </div>

                <druk-icon-button :type="'standard'" :icon="'xmark'" @click="$emit('close')" />
            </div>

            <div class="vmodal__main">
                <div class="druk-l-form">
                    <div class="druk-l-form__body">
                        <div class="druk-l-grid">
                            <div class="druk-l-grid__col-4 druk-u-md-12 druk-u-md-gap-xl">
                                <img src="https://druk.s3.amazonaws.com/static/maintenance.gif" alt="" @error="$fn.fixImageError" />
                            </div>

                            <div class="druk-l-grid__col-8 druk-u-md-12">
                                <div class="druk-l-info druk-has-accent">{{ $t('login.locked_modal.message') }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="druk-l-form__footer">
                        <div class="druk-l-form__footer-item">
                            <druk-button :icon="'check'" :label="$t('btn.agree')" @click="$emit('close')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'modal-locked-user',
    };
</script>
